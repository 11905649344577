<template>
    <ul class="list-group ">
        <li :class="{'active': this.activeSubHeader === header}" class="list-group-item" v-for="header in organisationDailyReportHeader.daily_report_subheaders"
        v-bind:key="header.id" >
            <div class="row">
                <div class="col-7 cursor-pointer" @click="setActiveSubHeader(header)">
                    {{header.name}}
                </div>
                <div class="col-1">
                    <i class="fa-solid fa-arrow-up" @click="moveSubHeaderUp(header)"></i>
                </div>
                <div class="col-1">
                    <i class="fa-solid fa-arrow-down" @click="moveSubHeaderDown(header)"></i>
                </div>
                <div class="col-1">
                    <i class="fa-solid fa-pencil" @click="editSubHeader(header)" data-bs-toggle="modal" data-bs-target="#modalSubHeader"></i>
                </div>
                <div class="col-1">
                    <i class="fa-solid fa-trash" @click="deleteSubHeader(header)"></i>
                </div>
            </div>
                        
        </li>
        <li class="list-group-item cursor-pointer" @click="setNewSubHeader()"
                    data-bs-toggle="modal" data-bs-target="#modalSubHeader">
            <i class="fa-solid fa-plus" ></i>
        </li>
    </ul>


    <div class="modal fade" id="modalSubHeader" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-scrollable modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalLabel">{{ $t(this.currentComponent) }}</h5>
                    <button type="button" id="modalSubHeaderClose" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body modal-textarea">
                    <component v-if="this.currentComponent" :is="currentComponent" v-bind="currentProperties" v-on:closeModal="closeModal"></component>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t("close") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
import AddOrganisationDailyReportSubHeader from '@/components/dailyreport/AddOrganisationDailyReportSubHeader.vue'
import EditOrganisationDailyReportSubHeader from '@/components/dailyreport/EditOrganisationDailyReportSubHeader.vue'

export default {
    name: 'EditOrganisationDailyReportSubHeaders',
    emits: ['setActiveSubHeader'],
    components: {
        EditOrganisationDailyReportSubHeader,
        AddOrganisationDailyReportSubHeader,
        },
    props: {
        organisationDailyReport: {
            type: Object,
            required: true,
        },
        organisationDailyReportHeaderId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            activeSubHeader: null,
            currentComponent: "",
            organisationDailyReportHeader: {},
            organisationDailyReportHeaderIdOld: 0
        }
    },
    mounted(){
        this.getOrganisationDailyReportSubHeaderFromBackend()
    },
    updated(){
        console.log('entry updated')
        console.log(this.organisationDailyReportHeaderIdOld)
        console.log(this.organisationDailyReportHeaderId)
        if(this.organisationDailyReportHeaderIdOld === 0){
            this.organisationDailyReportHeaderIdOld = this.organisationDailyReportHeaderId
        }
        if(this.organisationDailyReportHeaderIdOld != this.organisationDailyReportHeaderId){
            this.getOrganisationDailyReportSubHeaderFromBackend()
            this.organisationDailyReportHeaderIdOld = this.organisationDailyReportHeaderId
            this.activeSubHeader = null
        }
        console.log('exit updated')
    },
    methods: {
        sortSubHeaderArray(){
            console.log('entry sortSubHeaderArray')
            this.organisationDailyReportHeader.daily_report_subheaders = this.organisationDailyReportHeader.daily_report_subheaders.sort((a,b) => {
                if(a.position < b.position){
                    return -1
                }
                if (a.position > b.position){
                    return 1
                }
                return 0
            })
            this.updateSubHeadersBackend()
            console.log('exit sortSubHeaderArray')
        },
        setNewSubHeader(){
            console.log('entry setNewSubHeader')
            this.currentComponent = "AddOrganisationDailyReportSubHeader",
            console.log('exit setNewSubHeader')
        },
        editSubHeader(header){
            console.log('entry editSubHeader')
            this.currentComponent = 'EditOrganisationDailyReportSubHeader'
            this.activeSubHeader = header
            this.$emit('setActiveSubHeader', this.activeSubHeader)
            console.log('exit editSubHeader')
        },
        deleteSubHeader(header){
            console.log('entry deleteSubHeader')
            axios
                .delete(`/api/v1/organisation_daily_report_subheaders/${header.id}/`)
                .then(response => {
                    createToast({text: this.$t("subheader deleted")
                    ,type: 'success'})
                    this.deleteSubHeaderFromFrontend(header)
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            this.activeSubHeader = ''
            this.$emit('setActiveSubHeader', this.activeSubHeader)
            console.log('exit deleteSubHeader')
        },
        deleteSubHeaderFromFrontend(header){
            console.log('entry deleteSubHeaderFromFrontend')
            let position = 1
            let pos_delete = this.organisationDailyReportHeader.daily_report_subheaders.map(e => e.id).indexOf(header.id)
            console.log('pos_delete: ' + pos_delete)
            this.organisationDailyReportHeader.daily_report_subheaders.splice(pos_delete, 1)
            this.organisationDailyReportHeader.daily_report_subheaders.forEach(element => {
                element.position = position
                position++
                axios
                    .patch(`/api/v1/organisation_daily_report_subheaders/${element.id}/`, element)
                    .then(response => {
                        console.log('response')
                        console.log(response.data)
                    })
                    .catch(error => {
                        if (error.response) {
                            if ('detail' in error.response.data){
                                createToast({text: this.$t(error.response.data['detail'])
                                            ,type: 'error'})
                            }                        
                            console.error(JSON.stringify(error.response.data))
                        } else if (error.message) {
                            console.error(JSON.stringify(error.message))
                        } else {
                            console.error(JSON.stringify(error))
                        }
                    })
            })
            console.log('exit deleteSubHeaderFromFrontend')
        },
        moveSubHeaderUp(header){
            console.log('entry moveSubHeaderUp')
            if(header.position < 2){
                return
            }
            let header_to_switch = this.organisationDailyReportHeader.daily_report_subheaders.filter(array_header => array_header.position === header.position -1)

            header_to_switch.forEach((value,index) =>{
                value.position = header.position
            })
            header.position--
            this.sortSubHeaderArray()
            console.log('exit moveSubHeaderUp')
        },
        moveSubHeaderDown(header){
            console.log('entry moveSubHeaderDown')
            if(header.position >= this.organisationDailyReportHeader.daily_report_subheaders.length){
                return
            }
            let header_to_switch = this.organisationDailyReportHeader.daily_report_subheaders.filter(array_header => array_header.position === header.position +1)

            header_to_switch.forEach((value,index) =>{
                value.position = header.position
            })
            header.position++
            this.sortSubHeaderArray()
            console.log('exit moveSubHeaderDown')
        },
        setActiveSubHeader(header){
            console.log('entry setActiveSubHeader')
            this.activeSubHeader = header
            this.$emit('setActiveSubHeader', this.activeSubHeader)
            console.log('exit setActiveSubHeader')
        },
        updateSubHeadersBackend(){
            console.log('entry updateSubHeadersBackend')
            this.organisationDailyReportHeader.daily_report_subheaders.forEach(element => {
                axios
                    .patch(`/api/v1/organisation_daily_report_subheaders/${element.id}/`, element)
                    .then(response => {
                        console.log(response)
                    })
                    .catch(error => {
                        if (error.response) {
                            if ('detail' in error.response.data){
                                createToast({text: this.$t(error.response.data['detail'])
                                            ,type: 'error'})
                            }                        
                            console.error(JSON.stringify(error.response.data))
                        } else if (error.message) {
                            console.error(JSON.stringify(error.message))
                        } else {
                            console.error(JSON.stringify(error))
                        }
                    })
                })
            console.log('exit updateSubHeadersBackend')
        },
        getOrganisationDailyReportSubHeaderFromBackend(){
            console.log('entry getOrganisationDailyReportSubHeaderFromBackend')
            axios
                .get(`/api/v1/organisation_daily_report_headers/${this.organisationDailyReportHeaderId}/`)
                .then(response => {
                    console.log(response)
                    this.organisationDailyReportHeader = response.data
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit getOrganisationDailyReportSubHeaderFromBackend')
        },
        closeModal(action, element){
            console.log("closeModal")
            document.getElementById('modalSubHeaderClose').click();
            if(action === 'add'){
                this.organisationDailyReportHeader.daily_report_subheaders.push(element)
            }
        },
    },
    computed:{
        currentProperties(){
            
            return {organisationDailyReportHeader: this.organisationDailyReportHeader, 
                organisationDailyReportSubHeader: this.activeSubHeader,
                subHeaderPosition: this.organisationDailyReportHeader.daily_report_subheaders.length +1,
                }
        },
    }
}
</script>