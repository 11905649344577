import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import { createHead } from '@vueuse/head'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import VueKinesis from "vue-kinesis"
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import "bootstrap"
import "vue-search-select/dist/VueSearchSelect.css";
import { tooltip } from '@/assets/js/tooltip'
import VueApexCharts from "vue3-apexcharts";

axios.defaults.baseURL = process.env.VUE_APP_AXIOS

const i18n = createI18n({
    locale: 'de', // set locale
    legacy: true, // you must set `false`, to use Composition API
    useScope: 'global',
    fallbackLocale: 'de', // set fallback locale
    silentTranslationWarn: true,
    messages: loadLocaleMessages()

  })


function loadLocaleMessages() {
    const locales = require.context('./', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i)
      if (matched && matched.length > 1) {
        const locale = matched[1]
        messages[locale] = locales(key)
      }
    })
    return messages
  }

const app = createApp(App)
app.use(VueKinesis)
app.component('Datepicker', Datepicker);
app.use(store)
app.use(i18n)
app.use(router, axios)
app.use(VueApexCharts);
app.use(createHead())
app.use(VueGoogleMaps, {
  load: {
      key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
  },
})
app.directive('tooltip', tooltip)
app.mount('#app')
