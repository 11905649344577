<template>
    <div class="master-data mt-2">
        <ul class="nav nav-tabs">
            <li class="nav-item" v-for="live_data in this.$store.getters.getOrganisation.live_data_organisation"
                v-bind:key="live_data.id">
                <a class="nav-link" :class="{'active': this.currentLiveData === live_data}" href="#" @click="setLiveData(live_data)">
                    {{ live_data.name }} 
                    <i class="fa-solid fa-pencil" @click="editLiveData(live_data)" data-bs-toggle="modal" data-bs-target="#modalLiveData"></i>
                </a>
            </li>
            <li class="nav-item" >
                <a class="nav-link" href="#" data-bs-toggle="modal" data-bs-target="#modalLiveData"  @click="newLiveData()"><i class="fa-solid fa-plus" ></i> {{ $t("new_live_data") }}</a>
            </li>
        </ul>
        <component v-if="currentLiveData" :is="currentTabComponent" v-bind="currentProperties" v-on:deleteLiveData="deleteLiveData"></component>
    </div>


    <div class="modal fade" id="modalLiveData" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-scrollable modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalLabel">{{ $t(this.currentComponent) }}</h5>
                    <button type="button" id="modalLiveDataClose" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body modal-textarea">
                    <component :is="currentComponent" v-bind="currentPropertiesLiveData" v-on:closeModal="closeModal"></component>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t("close") }}</button>
                </div>
            </div>
        </div>
    </div>
  </template>
  <script>    
  import ConfigLiveData from '@/components/livedata/ConfigLiveData.vue'
  import AddLiveData from '@/components/livedata/AddLiveData.vue'
  import EditLiveData from '@/components/livedata/EditLiveData.vue'
  export default {
        name: 'ConfiLiveDatas',
        components: {
            ConfigLiveData,
            AddLiveData,
            EditLiveData
        },
        data() {
            return {
                currentTabComponent: "ConfigLiveData",
                currentLiveData: null,
                currentComponent: "AddLiveData",
            }
        },
        methods: {
            deleteLiveData(live_data){
                console.log('entry deleteLiveData')
                let pos_delete = this.$store.getters.getOrganisation.live_data_organisation.map(e => e.id).indexOf(live_data.id)
                console.log('pos_delete: ' + pos_delete)
                this.$store.getters.getOrganisation.live_data_organisation.splice(pos_delete, 1)
                this.currentLiveData = ''
                console.log('exit deleteLiveData')
            },
            editLiveData(live_data){
                console.log('entry editLiveData')
                this.currentComponent = 'EditLiveData'
                this.currentLiveData = live_data
                console.log('exit editLiveData')
            },
            newLiveData(){
                console.log('entry newLiveData')
                this.currentComponent = 'AddLiveData'
                console.log('exit newLiveData')
            },
            closeModal(action, new_live_data){
                console.log('entry closeModal with action: ' + action)
                if(action === 'add'){
                    this.$store.getters.getOrganisation.live_data_organisation.push(new_live_data)
                    this.setLiveData(new_live_data)
                }
                document.getElementById('modalLiveDataClose').click();
                console.log('exit closeModal')
            },
            setLiveData(live_data){
                console.log('entry setLiveData')
                this.currentLiveData = live_data
                console.log('exit setLiveData')
            }
        },
        computed:{
            currentProperties(){
                return {organisation: this.$store.getters.getOrganisation, liveDataId: this.currentLiveData.id}
            },
            currentPropertiesLiveData(){
                return {organisation: this.$store.getters.getOrganisation, liveData: this.currentLiveData}
            },
        }
    }
  </script>