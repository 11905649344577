<template>
    <div class="container-fluid my-3 py-3">
        <div class="row">
            <div class="col-12 mb-3">
                <div class="form-floating">
                    <input id="inputUsername" disabled type="text" name="username" class="form-control" @keyup.enter="submitForm" v-model="user.username" placeholder="name@examp" required>
                    <label for="inputUsername" class="form-label" >{{ $t("username") }}</label>                
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6 mb-3">
                <div class="form-floating">
                    <input id="inpuEmail" type="email" name="username" class="form-control" @keyup.enter="submitForm" v-model="user.email" placeholder="name@examp" required>
                    <label for="inputEmail" class="form-label" >{{ $t("email") }}</label>                
                </div>
            </div>
            <div class="col-6">
                <select class="form-select " aria-label="Default select example" v-model="user.grp_name">
                    <option  v-for="group in this.authgroups.authgroups" 
                        v-bind:key="group.id" v-bind:value="group.id">{{group.grp_name}}</option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="col-6 mb-3">
                <div class="form-floating">
                    <input id="inputPhone" type="text" name="username" class="form-control" @keyup.enter="submitForm" v-model="user.phone_number" placeholder="name@examp" required>
                    <label for="inputPhone" class="form-label" >{{ $t("phone") }}</label>
                </div>
            </div>
        </div>
        <div class="row">
            
            <div class="text-danger" v-if="errors.length">
                <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
            </div>
            
        </div>
        <button class="btn btn-success mb-0 ms-auto" @click="submitForm">{{ $t("save") }}</button>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
import { isThursday } from 'date-fns'
export default {
    name: 'AddUser',
    props: {
        organisation: {
            type: Object,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            passwordFieldType: "password",
            errors: [],
            authgroups: [],
        }
    },
    mounted(){
        this.getAuthgroups()
    },
    methods: {
        getAuthgroups(){
            console.log('entry getAuthgroups')
            axios
                .get(`/api/v1/organisation/${this.organisation.id}/get_authgroups_user/`)
                .then(response => {
                    this.authgroups = response.data
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit getAuthgroups')
        },
        submitForm() {
            this.errors = []
            axios
                .patch(`/api/v1/users/${this.user.id}/`, this.user)
                .then(response => {
                    
                    createToast({text: this.$t("user_altered")
                   ,type: 'success'})
                   this.$emit('closeModal')
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })            
        },
        switchVisibility(){
            this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
            this.$refs.password.focus();
        }
    }
}
</script>