<template>
     <div class="row mt-3">
        <div class="col-4">
            <ul class="list-group ">
                <li :class="{'active': this.activeHeader === header}" class="list-group-item" v-for="header in dashboard.plant_dashboard_headers"
                v-bind:key="header.id" >
                    <div class="row">
                        <div class="col-8 cursor-pointer" @click="setActiveHeader(header)">
                            <template v-if="header.is_group_name"><i class="fa-solid fa-users"></i></template><template v-else><i class="fa-solid fa-user"></i></template> {{header.name}} | {{header.column}} | {{header.position}}
                        </div>
                        <div class="col-1">
                            <i class="fa-solid fa-arrow-up" @click="moveHeaderUp(header)"></i>
                        </div>
                        <div class="col-1">
                            <i class="fa-solid fa-arrow-down" @click="moveHeaderDown(header)"></i>
                        </div>
                        <div class="col-1">
                            <i class="fa-solid fa-pencil" @click="editHeader(header)" data-bs-toggle="modal" data-bs-target="#modalHeader"></i>
                        </div>
                        <div class="col-1">
                            <i class="fa-solid fa-trash" @click="deleteHeader(header)"></i>
                        </div>
                    </div>
                        
                </li>
                <li class="list-group-item cursor-pointer" @click="setNewHeader()"
                            data-bs-toggle="modal" data-bs-target="#modalHeader">
                    <i class="fa-solid fa-plus" ></i>
                </li>
            </ul>
        </div>
        <div class="col-6">
            <EditPlantDashboardPositions v-if="this.activeHeader" v-bind="currentProperties" />
        </div>
    </div>
    <div class="modal fade" id="modalHeader" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-scrollable modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalLabel">{{ $t(this.currentComponent) }}</h5>
                    <button type="button" id="modalHeaderClose" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body modal-textarea">
                    <component v-if="this.currentComponent" :is="currentComponent" v-bind="currentPropertiesHeaders" v-on:closeModal="closeModal"></component>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t("close") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
import EditPlantDashboardPositions from '@/components/dashboard/EditPlantDashboardPositions.vue'
import AddPlantDashboardHeader from '@/components/dashboard/AddPlantDashboardHeader.vue'
import EditPlantDashboardHeader from '@/components/dashboard/EditPlantDashboardHeader.vue'
export default {
    name: 'EditDashboardForOrganisation',
    emits: [],
    components: {
        EditPlantDashboardPositions,
        AddPlantDashboardHeader,
        EditPlantDashboardHeader
        },
    props: {
        dashboard: {
            type: Object,
            required: true,
        },
        
    },
    data() {
        return {
            activeHeader: null,
            currentComponent: "",
        }
    },
    mounted(){
    },
    updated(){
        console.log('entry updated')
        
        console.log('exit updated')
    },
    methods: {
        
        closeModal(action, element){
            console.log("closeModal")
            document.getElementById('modalHeaderClose').click();
            if(action === 'add'){
                this.dashboard.plant_dashboard_headers.push(element)
            }
        },
        sortHeaderArray(){
            console.log('entry sortHeaderArray')
            this.dashboard.plant_dashboard_headers = this.dashboard.plant_dashboard_headers.sort((a,b) => {
                if(a.column < b.column){
                    return -1
                }
                if (a.column > b.column){
                    return 1
                }
                if(a.position < b.position){
                    return -1
                }
                if (a.position > b.position){
                    return 1
                }
                return 0
            })
            this.updateHeadersBackend()
            console.log('exit sortHeaderArray')
        },
        setNewHeader(){
            console.log('entry setNewHeader')
            this.currentComponent = "AddPlantDashboardHeader",
            console.log('exit setNewHeader')
        },
        editHeader(header){
            console.log('entry editHeader')
            this.currentComponent = 'EditPlantDashboardHeader'
            this.activeHeader = header
            console.log('exit editHeader')
        },
        deleteHeader(header){
            console.log('entry deleteHeader')
            axios
                .delete(`/api/v1/plantdashboards_headers/${header.id}/`)
                .then(response => {
                    createToast({text: this.$t("header deleted")
                    ,type: 'success'})
                    this.deleteHeaderFromFrontend(header)
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                    createToast({text: error.message
                    ,type: 'error'})
                })
            this.activeHeader = ''
            console.log('exit deleteHeader')
        },
        deleteHeaderFromFrontend(header){
            console.log('entry deleteHeaderFromFrontend')
            let position = 1
            let pos_delete = this.dashboard.plant_dashboard_headers.map(e => e.id).indexOf(header.id)
            console.log('pos_delete: ' + pos_delete)
            this.dashboard.plant_dashboard_headers.splice(pos_delete, 1)
            this.dashboard.plant_dashboard_headers.forEach(element => {
                element.position = position
                position++
                axios
                    .patch(`/api/v1/plantdashboards_headers/${element.id}/`, element)
                    .then(response => {
                        console.log('response')
                        console.log(response.data)
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error))
                        createToast({text: error.data
                        ,type: 'error'})
                    })
            })
            console.log('exit deleteHeaderFromFrontend')
        },
        moveHeaderUp(header){
            console.log('entry moveHeaderUp')
            if(header.position < 2){
                return
            }
            let header_to_switch = this.dashboard.plant_dashboard_headers.filter(array_header => array_header.position === header.position -1 && array_header.column === header.column)

            header_to_switch.forEach((value,index) =>{
                value.position = header.position
            })
            header.position--
            this.sortHeaderArray()
            console.log('exit moveHeaderUp')
        },
        moveHeaderDown(header){
            console.log('entry moveHeaderDown')
            if(header.position >= this.dashboard.plant_dashboard_headers.length){
                return
            }
            let header_to_switch = this.dashboard.plant_dashboard_headers.filter(array_header => array_header.position === header.position +1 && array_header.column === header.column)

            header_to_switch.forEach((value,index) =>{
                value.position = header.position
            })
            header.position++
            this.sortHeaderArray()
            console.log('exit moveHeaderDown')
        },
        setActiveHeader(header){
            console.log('entry setActiveHeader')
            this.activeHeader = header
            console.log('exit setActiveHeader')
        },
        updateHeadersBackend(){
            console.log('entry updateHeadersBackend')
            this.dashboard.plant_dashboard_headers.forEach(element => {
                axios
                    .patch(`/api/v1/plantdashboards_headers/${element.id}/`, element)
                    .then(response => {
                        console.log(response)
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error))
                        createToast({text: error.message
                        ,type: 'error'})
                    })
                })
            console.log('exit updateHeadersBackend')
        }
        
    },
    computed:{
        currentProperties(){
                return {plantDashboardHeaderId: this.activeHeader.id,
                    organisation: this.$store.state.organisation, }
            },
        currentPropertiesHeaders(){
            
            return {dashboard: this.dashboard, 
                    position: 1,
                    plantDashboardHeader: this.activeHeader
                }
        },
    }
}
</script>