<template>
    <div class="nav-plant-systems-sidebar">
        <ul class="nav nav-sidebar flex-column ms-2">
            <li class="nav-item" v-for="system in plantSystemJournals"
                v-bind:key="system.id">
                <a class="nav-link" :class="{'active': this.currentSystem === system}" href="#" @click="setSystemJournal(system)">
                    {{system.name}}
                </a>
            </li>
        </ul>
        
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'NavPlantSystemJournalsSidebar',
    emits: [
        'setSystemJournal',
    ],
    components: {
    },
    props: {
        plantSystemJournals: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            currentSystem: ""
        }
    },
    mounted(){
        
    },
    updated(){
        console.log('updated')
        
    },
    unmounted(){
        console.log('component unmounted')
        
        
    },
    methods: {
        editSystem(system){
            console.log('entry editSystem')
            this.currentSystem = system
            this.$emit('editSystem', system)
            console.log('exit editSystem')
        },
        newSystem(){
            console.log('entry newSystem')
            this.$emit('newSystem')
            console.log('exit newSystem')
        },
        setSystemJournal(system){
            console.log('entry setSystemJournal')
            this.currentSystem = system
            this.$emit('setSystemJournal', system)
            console.log('exit setSystemJournal')
        },
       
    },
    computed:{
        
    }
}
</script>