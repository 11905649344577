<template>
    <div class="page-plant mt-2">
        <div class="row">
            <div class="col-2">
                <table class="table clickable table-hover table-sm">
                    <tbody>
                        <tr
                            v-for="component in plant.plant_components"
                                v-bind:key="component.id"
                                :class="{ 'table-active': activeComponent == component }"
                                @click="setActiveComponent(component, plant)"
                        >
                            <td>{{component.name}}</td>
                        </tr>
                    </tbody>
                </table>     
            </div>
            <div class="col-10">
                <p v-if="!this.activeComponent">{{ $t("selectComponent") }}</p>
                <keep-alive>
                    <EditDescriptionComponent v-bind:component="activeComponent" v-if="this.activeComponent"></EditDescriptionComponent>
                </keep-alive>
            </div>
        </div>
        
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'

import AddPlant from '@/components/masterdata/AddPlant.vue'
import UpdatePlant from '@/components/masterdata/UpdatePlant.vue'
import UpdateComponent from '@/components/masterdata/UpdateComponent.vue'
import AddComponent from '@/components/masterdata/AddComponent.vue'
import EditDescriptionComponent from '@/components/masterdata/EditDescriptionComponent.vue'

import axios from 'axios'
export default {
    name: 'OrganisationPlants',
    emits: ['refreshOrganisation'],
    props: {
        organisation: {
            type: Object,
            required: true
        },
        plant: {
            type: Object,
            required: true
        },
    },
    components: {
        AddPlant,
        UpdatePlant,
        AddComponent,
        UpdateComponent,
        EditDescriptionComponent
    },
    data() {
        return {
            currentComponent: "AddPlant",
            plants: {},
            currentPlant: "",
            activePlant: "",
            activeComponent: ""
        }
    },
    mounted(){
        this.getPlant()
    },
    methods: {
        getPlant(){
                axios
                .get('/api/v1/components/9')
                .then(response => {
                    this.plantTypes = response.data
                    console.log('planttypes fertig geladen')
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
        },
        setActiveComponent(component, plant){
            this.activeComponent = component,
            this.activePlant = plant
        },
        setActivePlant(plant){
            this.activeComponent = "",
            this.activePlant = plant
        },
        setPlant(plant){
            this.currentPlant = plant
            this.currentComponent = "AddComponent"
        },
        setPlantForUpdate(plant){
            this.currentPlant = plant
            this.currentComponent = "UpdatePlant"
        },
        closeModal(){
            console.log("closeModal")
            document.getElementById('modalClose').click();
            console.log("reload")
            this.$emit('refreshOrganisation')
        },
        submitForm() {

            this.plant.organisation = this.organisation.id
            this.plant.created_by = this.$store.state.me.id
            this.plant.modified_by = this.$store.state.me.id
            this.plant.users = {}
            axios
                .post(`/api/v1/plants/`, this.plant)
                .then(response => {
                    
                    createToast({text: this.$t("plant added")
                   ,type: 'success'})

                   this.$emit('closeModal')
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
        }
    },
    computed:{
        currentProperties(){
            if(this.currentComponent == 'AddComponent'){
                return {plant: this.currentPlant}
            }else if(this.currentComponent == 'UpdatePlant'){
                return {plant: this.currentPlant, 
                        organisation: this.organisation}
            }else if(this.currentComponent == 'UpdateComponent'){
                return {plant: this.currentPlant, 
                        component: this.activeComponent}
            }
            return{organisation: this.organisation}
        },
        getModalName(){
            
        },
        
    }
}
</script>