<template>
    <div id="chart">
        <apexchart type="radialBar" height="390" :options="chartOptions" :series="valuesForSeries"></apexchart>
    </div>
    <div>
        <pre>{{json_formatted}}</pre>
    </div>
   
</template>

<script>
import axios from 'axios'
export default {
    name: 'SystemOverview',
    components: {
    },
    data() {
        return {
            system_overview: {},
            series: [110, 67, 61, 90],
          chartOptions: {
            chart: {
              height: 390,
              type: 'radialBar',
            },
            plotOptions: {
              radialBar: {
                dataLabels: {
                  name: {
                    fontSize: '22px',
                  },
                  value: {
                    fontSize: '16px',
                  },
                  total: {
                    show: true,
                    label: 'Systemstatus',
                    formatter: function (w) {
                      return 'Systemstatus'
                    }
                  }
                }
              }
            },
            labels: ['Speicherplatz', 'SWAP', 'CPU', 'RAM'],
            responsive: [{
              breakpoint: 480,
              options: {
                legend: {
                    show: true
                }
              }
            }]
          },
        }
    },
    mounted(){
        this.getSystemOverview()
    },
    updated(){

    },
    unmounted(){
        console.log('component unmounted')
        
    },
    methods: {
        getSystemOverview(){
            console.log('entry getSystemOverview')
            axios
                .get('/api/v1/actvity_log/get_system_info/')
                .then(response => {
                    this.system_overview = response.data 
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
            console.log('exit getSystemOverview')
        },

    },
    computed:{
        json_formatted(){
            return JSON.stringify(this.system_overview, null, 4);
        
        },
        valuesForSeries(){
            if(Object.keys(this.system_overview).length == 0)
                return []
            let value_array = []
            value_array.push(parseFloat(this.system_overview['disk_info']['partitions'][0]['percentage']))
            value_array.push(parseFloat(this.system_overview['swap_info']['percentage']))
            value_array.push(parseFloat(this.system_overview['cpu_info']['total_cpu_usage']))
            value_array.push(parseFloat(this.system_overview['memory_info']['percentage']))
            return value_array
        }
    }
}
</script>
<style scoped>
pre {
  padding: 10px;
  border-radius: 5px;
  font-family: monospace;
  white-space: pre-wrap; /* Zeilenumbruch bei langen Zeilen */
}
</style>