<template>
  <div class="chart-with-export">
    <div class="card" :class="{'invisible': chartData.datasets.length === 0}">
        <div class="card-body">
            <div class="text-end mb-2">
                <div class="dropdown">
                    <button class="btn " data-bs-toggle="dropdown" aria-expanded="true"><i class="fa-solid fa-ellipsis-vertical" ></i></button>
                    
                    <ul class="dropdown-menu">
                        <li><a class="dropdown-item" @click="generatePdf(chartId)"><i class="fa-solid fa-file-pdf"></i> {{ $t("export_pdf") }}</a></li>
                        <li><a class="dropdown-item" @click="generateExcel(chartId)"><i class="fa-solid fa-file-excel"></i> {{ $t("export_excel") }}</a></li>
                    </ul>

                </div>
            </div>
            <LineChart ref="chart" v-bind:chartId="chartId" v-bind:chartData="chartData"  v-bind:chartOptions="chartOptions"/>

        </div>
    </div>
  </div>
</template>

<script>
import { jsPDF } from "jspdf";
import LineChart from '@/components/LineChart.vue'


import axios from 'axios'
export default {
    name: 'ChartWithExport',
    emits: ['generateExcel'],
    components: {
        LineChart
    },
    props: {
        chartData: {
            type: Object,
            required: true
        },
        chartOptions: {
            type: Object,
            required: false
        },
        chartId:{
            type: String,
            required: false,
        },
    },
    methods: {
        generatePdf(htmlRef){
            console.log('entry generatePDF: ' +htmlRef)

            var doc = new jsPDF('l', 'mm', 'A4');

            var base64Image = this.$refs.chart.getBase64Image()

            doc.addImage(base64Image, 'JPEG', 15, 15, 280, 100, htmlRef, 'SLOW')
      
            doc.save('Auswertung.pdf');
        },
        generateExcel(htmlRef){
            console.log('entry generateExcel: ' +htmlRef)

            this.$emit('generateExcel')
            console.log('exit generateExcel')
        }
    }
}
</script>
