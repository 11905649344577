<template>
    <div class="row justify-content-end ">
        <div class="col-auto px-3">
            <select class="form-select form-select-sm mb-3" v-model="action_type" @change="loadActivityLog()">
                <option selected="" value="">{{ $t("select_action_log") }}</option>
                <option value="Login">Login</option>
                <option value="Create">Create</option>
                <option value="Send TB">Send TB</option>
            </select>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-striped table-hover table-sm">
                <thead>
                    <tr>
                        <th>{{ $t("action_time") }}</th>
                        <th>{{ $t("action_type") }}</th>
                        <th>{{ $t("user") }}</th>
                        <th>{{ $t("action") }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="log in logs" 
                        v-bind:key="log.id">
                        <td>{{ getTimeFormatted(log.action_time) }}</td>
                        <td>{{ log.action_type }}</td>
                        <td>{{ log.actor_bez }}</td>
                        <td>{{ log.remarks }}</td>
                    </tr>
                </tbody>
            </table>
            <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-end">
                    <li class="page-item" :class="{'disabled': !showPrevButton}" >
                        <a class="page-link"  @click="loadPrev()">&laquo;</a>
                    </li>
                    <li class="page-item" :class="{'disabled': !showNextButton}">
                        <a class="page-link" href="#" @click="loadNext()">&raquo;</a>
                    </li>
                </ul>
            </nav>
        </div>

    </div>

</template>

<script>
import axios from 'axios'

export default {
    name: 'ActivityLogs',
    components: {
    },
    data() {
        return {
            logs: [],
            action_type: "",
            currentPage: 1,
            showNextButton: false,
            showPrevButton: false
        }
    },
    mounted(){
        this.loadActivityLog()
    },
    updated(){

    },
    unmounted(){
        console.log('component unmounted')
        
    },
    methods: {
        loadNext() {
            this.currentPage += 1
            this.loadActivityLog()
        },
        loadPrev() {
            this.currentPage -= 1
            this.loadActivityLog()
        },
        loadActivityLog(){
            console.log('entry loadActivityLog')
            axios
                .get(`/api/v1/log/?page=${this.currentPage}&action_type=${this.action_type}`)
                .then(response => {
                    console.log('activity loaded')
                    console.log(response.data)
                    this.showNextButton = false
                    this.showPrevButton = false
                    console.log(response.data.next)
                                
                    if (response.data.next != null) {
                        this.showNextButton = true
                    }
                    console.log(response.data.previous)
                    if (response.data.previous) {
                        this.showPrevButton = true
                        console.log('prevbutton')
                    }

                    this.logs = response.data.results
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
            console.log('exit loadActivityLog')
        },
        getTimeFormatted(time){
            let updatedTime = new Date(time)
            return updatedTime.toLocaleString()
        },
    },
    computed:{
     
    }
}
</script>
