<template>
    <div class="container-fluid mt-2">
        <div class="row">
            <div class="col-6">
                <div class="form-floating">
                    <input id="inputName" :disabled="!this.canWriteUserGroup" type="text" name="name" class="form-control"  v-model="userGroup.grp_name" @keyup.enter="submitForm" placeholder="name@example.com">
                    <label for="inputName" class="form-label" >{{ $t("name") }}</label>
                </div>
            </div>
            <div class="col-6">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" name="send_alarmmessages" id="selectionMonth" v-model="userGroup.send_alarmmessages">
                    <label class="form-check-label" for="send_alarmmessages">
                        {{ $t("send_alarmmessages") }}
                    </label>
                </div>
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" name="send_old_data" id="selectionMonth" v-model="userGroup.send_old_data">
                    <label class="form-check-label" for="send_old_data">
                        {{ $t("send_old_data") }}
                    </label>
                </div>
            </div>
        </div>
       
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'EditUserGroup',
    emits: [],
    props: {
        userGroup: {
            type: Object,
            required: true,
        },
        canWriteUserGroup: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    data() {
        return {
            
        }
    },

    methods: {
        
    }
}
</script>