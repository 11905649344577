<template>
    <div class="container-fluid my-3 py-3">
        <div class="row my-3">
            <div class="col-6">
                <div class="form-floating">
                    <input id="inputName" ref="name" type="text" name="name" class="form-control"  v-model="organisationDailyReportSubHeader.name" placeholder="name@example.com">
                    <label for="inputName" class="form-label" >{{ $t("name") }}</label>
                </div>
            </div>   
                          
        </div>
        <button class="btn btn-success mb-0 ms-auto" @click="submitForm">{{ $t("save") }}</button>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'EditOrganisationDailyReportHeader',
    emits: ['closeModal'],
    props: {
        organisationDailyReportSubHeader: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            
        }
    },
    mounted(){
        console.log('entry mounted')
        this.focusInput()
        console.log('exit mounted')
    },
    methods: {
        focusInput() {
            console.log('entry focusInput')
            console.log(this.$refs.name)
            this.$refs.name.focus();
            console.log('exit focusInput')
        },
        submitForm() {
            
            axios
                .patch(`/api/v1/organisation_daily_report_subheaders/${this.organisationDailyReportSubHeader.id}/`, this.organisationDailyReportSubHeader)
                .then(response => {
        
                    createToast({text: this.$t("header added")
                        ,type: 'success'})

                this.$emit('closeModal')
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
        }
    },
    computed:{
    }
}
</script>