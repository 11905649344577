<template>
  <div class="master-data mt-2">
       
    <keep-alive>
      <component :is="currentTabComponent" v-bind="currentProperties" v-on:refreshOrganisation="refreshOrganisation"></component>
    </keep-alive>
    </div>
</template>
<script>    
import { createToast } from '@/assets/js/myToast.js'
import EditOrganisation from '@/components/masterdata/EditOrganisation.vue';
import axios from 'axios'
export default {
    name: 'Masterdata',
    components: {
      EditOrganisation,
  },
    data() {
        return {
            currentTabComponent: "EditOrganisation",
        }
    },
    methods: {
      refreshOrganisation(){
        console.log('entry refreshOrganisation')

        const organisationID = this.$store.state.me.organisation

        axios
          .get(`/api/v1/organisations/${organisationID}/`)
          .then(response => {
            this.$store.commit('setOrganisation', response.data)
          })
          .catch(error => {
            console.log(JSON.stringify(error))
          })
      }
    },
    computed:{
      
      canReadUser(){
            return this.$store.getters.userHasPermission('user', 'can_read')
        },
        currentProperties(){
            return {organisation: this.$store.getters.getOrganisation}
        },
        getModalName(){
            
        }
    }
}
</script>