<template>
    <div class="mt-2">
        <GMapMap
            :center="center"
            :zoom="7"
            map-type-id="hybrid"
            :options="{
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: true,
                streetViewControl: true,
                rotateControl: true,
                fullscreenControl: true,
                
                }"
        >
    
            <GMapMarker
                v-for="m in markers"
                v-bind:key="m.index"
                :position="m.position"
                @click="openInfoWindow(m); center = m.position"
                :clickable="true"
                :draggable="false"
                @mouseover="showByIndex = m.position.plant.name;"
                @mouseout="showByIndex = null"
            >
                <GMapInfoWindow v-if="showByIndex === m.position.plant.name">
                    <div>{{ m.position.plant.name }}
                    </div>
                </GMapInfoWindow>
            </GMapMarker>
    
        </GMapMap>
    </div>

    <button id="modalOpen" style="display:none;" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#modal" aria-hidden="true">hidden</button>
    
    <div class="modal fade" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-scrollable modal-xl">
        <div class="modal-content ">
            <div class="modal-header">
                <h5 class="modal-title" id="modalLabel">{{currentPlant.name}}</h5>
                
                <button type="button" id="modalClose" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="resetComponent"></button>
            </div>
            <div class="modal-body modal-textarea">
                <template v-if="currentComponent != ''">
                    <component ref="overview" :is="currentComponent" v-bind="currentProperties" v-on:closeModal="closeModal" v-on:updateTime="updateTime"></component>
                </template>
            </div>
            <div class="modal-footer justify-content-between">
                <p class="small">{{ $t("updatedAt") }} {{time}}</p>
                <div class="footer-buttons justify-content-between">
                    <button type="button" class="btn btn-outline-secondary" @click="generatePdf('test')"><i class="fa-solid fa-file-pdf"></i> {{ $t("export_pdf") }}</button>
                    <button type="button" class="btn btn-outline-secondary" @click="generateExcel()"><i class="fa-solid fa-file-excel"></i> {{ $t("export_excel") }}</button>
                </div>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="resetComponent">{{ $t("close") }}</button>
            </div>
        </div>
      </div>
    </div>
</template>

<script>

import BiogasOverview from '@/components/dashboard/BiogasOverview.vue'
export default {
    name: 'GoogleMaps',
    props: {
        plants: {
            type: Object,
            required: true,
        },
    },
    components: {
        BiogasOverview,
    },
    data() {
        return {
            center: {lat: 50.29374, lng: 8.753869},
            currentPlant: {},
            currentComponent: '',
            time: '',
            showByIndex: ''
        }
    },

    methods: {
        generateExcel(){
            console.log('entry generateExcel')
            this.$refs.overview.getDataForPlantExcel()
            console.log('exit generateExcel')
        },
        generatePdf(){
            console.log('entry generatePDF')
            this.$refs.overview.getDataForPlantPdf()
            console.log('exit genetarePdf')
        },
        resetComponent(){
            console.log('entry resetComponent')
            this.currentComponent = ''
            console.log('exit resetComponent')
        },
        openInfoWindow(marker){
            console.log('entry openInfoWindow')
            console.log('marker: ')
            console.log(marker)
            this.currentPlant = marker.position.plant
            this.currentComponent = 'BiogasOverview'
            document.getElementById('modalOpen').click();
            console.log('exit openInfoWindow')
        },
        updateTime(time){
            this.time = time
        },
        closeModal(){
            console.log("closeModal")
            document.getElementById('modalClose').click();
            this.resetComponent()
        },
    },
    computed:{
        markers(){
            if (typeof this.plants === 'undefined'){
                return
            }
            if (this.plants.length < 1){
                return
            }
            let markers=[]
            console.log('entry markers')
            console.log(this.plants)
            this.plants.forEach(element =>{
                console.log(element.longitude)
                if(element.longitude){
                    let position = {position: { 
                    }}
                    position.position.lat = parseFloat(element.latitude)
                    position.position.lng = parseFloat(element.longitude)
                    position.position.plant = element
                    markers.push(position)
                }
            })
            console.log(markers)
            console.log('exit markers')
            return markers
        },
        currentProperties(){
            
            return {plant: this.currentPlant}
        },
        getModalName(){
            
        }
    }
}
</script>