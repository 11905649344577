<template>
    <div class="container-fluid my-3 py-3">
        <div class="row my-3">
            <div class="col-12">
                <div class="form-floating">
                    <input id="inputName" ref="name" type="text" name="name" class="form-control"  v-model="plantDashboardPosition.name" placeholder="name@example.com">
                    <label for="inputName" class="form-label" >{{ $t("name") }}</label>
                </div>
            </div>                
        </div>
        
        <div class="text-danger" v-if="errors.length">
            <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
        </div>
        <button class="btn btn-success mb-0 ms-auto" @click="submitForm">{{ $t("save") }}</button>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'AddPlantDashboardPosition',
    emits: ['closeModal'],
    props: {
        organisation: {
            type:Object,
            required: true
        },
        plantDashboardHeader: {
            type: Object,
            required: true,
        },
        position: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            plantDashboardPosition: {
                name: '',
                
            },
            errors: [],
        }
    },
    mounted(){
        console.log('entry mounted')
        this.focusInput()
        console.log('exit mounted')
    },
    methods: {
        focusInput() {
            console.log('entry focusInput')
            console.log(this.$refs.name)
            this.$refs.name.focus();
            console.log('exit focusInput')
        },
        submitForm() {
            this.plantDashboardPosition.plant_dashboard_header = this.plantDashboardHeader.id
            this.plantDashboardPosition.created_by = this.$store.state.me.id
            this.plantDashboardPosition.modified_by = this.$store.state.me.id
            this.plantDashboardPosition.position = this.position
            this.plantDashboardPosition.id = 0
            
            axios
                .post(`/api/v1/plantdashboards_positions/`, this.plantDashboardPosition)
                .then(response => {
        
                    createToast({text: this.$t("position added")
                        ,type: 'success'})
                    this.plantDashboardPosition.name = ''

                this.$emit('closeModal')
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                        }

                        console.log(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.log(JSON.stringify(error.message))
                    } else {
                        console.log(JSON.stringify(error))
                    }
                })
        }
    },
    computed:{
        
    }
}
</script>