<template>
    <div class="container-fluid">
        <div class="row my-3"
            v-for="evaluation_column in this.evaluation.plant_type_evaluation_columns"
            v-bind:key="evaluation_column.id">
            
            <div class="col-2">
                    
                <label for="inputDescCol" class="form-label" >{{ $t("desc_col") }}</label>
                <input :disabled="!$store.state.me.is_superuser" id="inputDescCol" type="text" name="inputDescCol" class="form-control"  v-model="evaluation_column.name" >
            </div>
            <div class="col-2">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" :value="evaluation_column.show_units" id="show_units" v-model="evaluation_column.show_units">
                    <label class="form-check-label" for="show_units" >
                        {{$t("show_units")}}
                    </label>
                </div>
                
            </div>
            <div class="col-2">
                <label for="type_footer" class="form-label">{{ $t("type_footer") }}</label>
                <select id="type_footer" class="form-select " aria-label="Default select example" v-model="evaluation_column.type_footer">
                    <option value="EF">{{ $t("daily_report_type_footer_ef") }}</option>
                    <option value="AF">{{ $t("daily_report_type_footer_af") }}</option>
                    <option value="SF">{{ $t("daily_report_type_footer_sf") }}</option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <button :disabled="!$store.state.me.is_superuser" class="btn btn-success mb-0 ms-auto" @click="addEvaluationColumn">{{ $t("addEvaluationColumn") }}</button>
            </div>
            <div class="col-6 text-end">
                <button class="btn btn-success mb-0 ms-auto" @click="submitForm">{{ $t("save") }}</button>
            </div>
        </div>

    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios'
export default {
    name: 'EditEvaluationColumns',
    emits: [
    ],
    props: {
        evaluationId: {
            type: Number,
            required: true,
        },
        
    },
    components: {
    },
    watch: {
        evaluationId: {
            deep: true,
            handler() {
                console.log('watch fired')
                this.loadEvaluation()
            }
        }
    },
    data() {
        return {    
            evaluation: {}
        }
    },
    async mounted(){
        await this.loadEvaluation()
    },
    updated(){
    },
    methods: {
        
        loadEvaluation(){
            console.log('entry loadEvaluation')
            axios
                .get(`/api/v1/planttype_evaluations/${this.evaluationId}/`, )
                .then(response => {
                    console.log('fertig')
                    console.log(response.data)
                    this.evaluation = response.data
                    this.manipulateEvaluationColumn()
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit loadEvaluation')
        },
        manipulateEvaluationColumn(){
            console.log('entry manipulateEvaluationColumn')
            for (let i = 0; i < this.evaluation.plant_type_evaluation_columns.length; i++) {
                this.evaluation.plant_type_evaluation_columns[i].nr_pos = i
                this.evaluation.plant_type_evaluation_columns[i].uuid = uuidv4()
                
            }
            console.log('exit manipulateEvaluationColumn')
        },
        removeItem(itemToRemove){
            console.log('entry removeItem: ')
            console.log(itemToRemove)
            const index = this.evaluation.plant_type_evaluation_columns.findIndex((element, index) => {
                if(element.uuid === itemToRemove.uuid){
                    return true
                }
            })
            console.log('index: ' +index)
            this.evaluation.plant_type_evaluation_columns.splice(index, 1)
            console.log('exit removeItem')
        },  
        addEvaluationColumn(){
            console.log('entry addEvaluationColumn')
            this.evaluation.plant_type_evaluation_columns.push({
                nr_pos: this.evaluation.plant_type_evaluation_columns.length,
                name: '',
                show_units: true,
                uuid: uuidv4(),
                created_by: this.$store.state.user.id,
                modified_by: this.$store.state.user.id,
            })

            console.log('exit addEvaluationColumn')
        },
        
        submitForm() {
            axios
                .patch(`/api/v1/planttype_evaluations/${this.evaluation.id}/`, this.evaluation)
                .then(response => {
                    
                    createToast({text: this.$t("evaluation altered")
                   ,type: 'success'})
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
        }
    }
    
}
</script>