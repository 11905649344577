<template>
    <div class="container-fluid ">
        <div class="row">
            <div class="col-12">
                <div class="form-floating">
                    <input id="inputName" type="text" name="name" class="form-control"  v-model="evaluation.name" @keyup.enter="submitForm" placeholder="name@example.com">
                    <label for="inputName" class="form-label" >{{ $t("name") }}</label>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-6">
                <div class="form-floating">
                    <select id="type_evaluation" class="form-select " aria-label="Default select example" v-model="evaluation.type_evaluation">
                        <option value="DA">{{ $t("type_evaluation_da") }}</option>
                        <option value="MO">{{ $t("type_evaluation_mo") }}</option>
                    </select>
                    <label for="type_evaluation">{{ $t("type_evaluation") }}</label>
                </div>
            </div>
        </div>
        <button class="btn btn-success mt-2 mb-0 ms-auto" @click="submitForm">{{ $t("save") }}</button>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'AddEvaluation',
    emits: ['closeModal'],
    props: {
        evaluation: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
        }
    },

    methods: {
        submitForm() {

            axios
                .patch(`/api/v1/planttype_evaluations/${this.evaluation.id}/`, this.evaluation)
                .then(response => {
                    
                    createToast({text: this.$t("plantTypeEvaluation altered")
                   ,type: 'success'})

                   this.$emit('closeModal', 'edit', response.data)
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
        }
    }
}
</script>