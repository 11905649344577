<template>
    <div class="page-add-organisation">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/dashboard">Dashboard</router-link></li>
                <li class="breadcrumb-item"><router-link to="/settings/masterdata/organisations">{{ $t("organisations") }}</router-link></li>
                <li class="breadcrumb-item active"><router-link to="/settings/masterdata/organisations/add" aria-current="true">{{ $t("Neu") }}</router-link></li>
            </ol>
        </nav>
        <div class="container-fluid my-3 py-3">
            <div class="row">
                <h1 class="title">{{ $t("newOrganisation") }}</h1>
            </div>
        
            <div class="row my-3">
                <div class="col-6">
                    <div class="form-floating">
                        <input id="inputName" type="text" name="name" class="form-control"  v-model="organisation.name" @keyup.enter="submitForm" placeholder="Test">
                        <label for="inputName" class="form-label" >{{ $t("name") }}</label>
                    </div>
                </div>
            </div>
            <div class="row my-3">
                <div class="col-12">
                    <div class="form-floating">
                        <input id="inputAddress1" type="text" name="email" class="form-control"  v-model="organisation.address1" @keyup.enter="submitForm" placeholder="Test">
                        <label for="inputAddress1" class="form-label" >{{ $t("address1") }}</label>                
                    </div>
                </div>
            </div>
            <div class="row my-3">
                <div class="col-2">
                    <div class="form-floating">
                        <input id="inputZipcode" type="text" name="zipcode" class="form-control" v-model="organisation.zipcode" @keyup.enter="submitForm" placeholder="Test">
                        <label for="inputZipcode">{{ $t("zipcode") }}</label>   
                    </div>       
                </div>
                <div class="col-6">
                    <div class="form-floating">
                        <input id="inputCity" type="text" name="city" class="form-control" v-model="organisation.city" @keyup.enter="submitForm" placeholder="Test">
                        <label for="inputCity">{{ $t("city") }}</label>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-floating">
                        <input id="inputCountry" type="text" name="country" class="form-control" v-model="organisation.country" @keyup.enter="submitForm" placeholder="Test">
                        <label for="inputCountry">{{ $t("country") }}</label>
                    </div>
                </div>
            </div>
            <button class="btn btn-success mb-0 ms-auto" @click="submitForm" >{{ $t("Speichern") }}</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { useHead } from '@vueuse/head'
import { createToast } from '@/assets/js/myToast.js'
export default {
    setup () {
      useHead({
        meta: [
          {
            name: `robot`,
            content: 'noindex',
          },
        ],
      })
    },
    name: 'AddOrganisation',
    data() {
        return {
            organisation: {}
        }
    },
    methods: {
        submitForm() {
             this.organisation.created_by = this.$store.state.me.id
             this.organisation.modified_by = this.$store.state.me.id
            axios
                .post("/api/v1/allOrganisations/", this.organisation)
                .then(response => {
                    createToast({text: this.$t("organisation added")
                   ,type: 'success'})
                    this.$router.push('/settings/masterdata/organisations')
                })
                .catch(error => {
                    createToast({text: error.response.data.detail
                   ,type: 'error'})
                    console.log(JSON.stringify(error))
                })
        }
    }
}
</script>