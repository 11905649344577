<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="form-floating">
                    <input id="inputName" type="text" name="name" class="form-control"  v-model="plantSystem.name" @keyup.enter="submitForm" placeholder="name@example.com">
                    <label for="inputName" class="form-label" >{{ $t("name") }}</label>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-6">
                <label :disabled="!$store.state.me.is_superuser" for="type_import" class="form-label">{{ $t("type_import") }}</label>
                <select id="type_import" class="form-select " aria-label="Default select example" v-model="plantSystem.type_import">
                    <option value="CF">{{ $t("plant_system_type_import_cf") }}</option>
                    <option value="PF">{{ $t("plant_system_type_import_pf") }}</option>
                    <option value="SF">{{ $t("plant_system_type_import_sf") }}</option>
                </select>
            </div>
            <div class="col-3" v-if="plantSystem.type_import == 'SF'">
                <div class="form-floating">
                    <input :disabled="!$store.state.me.is_superuser" id="inputName" type="text" name="name" class="form-control"  v-model="plantSystem.folder_name_import" placeholder="name@example.com">
                    <label for="inputName" class="form-label" >{{ $t("folder_name_import") }}</label>
                </div>
            </div>
            <div class="col-3" v-if="plantSystem.type_import == 'SF'">
                <div class="form-floating">
                    <input :disabled="!$store.state.me.is_superuser" id="inputName" type="text" name="name" class="form-control"  v-model="plantSystem.archivfolder" placeholder="name@example.com">
                    <label for="inputName" class="form-label" >{{ $t("archivfolder") }}</label>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-6">
                <div class="form-floating">
                    <input id="inputPrefixAlarm" type="text" name="name" class="form-control"  v-model="plantSystem.prefix_alarmmessages" placeholder="name@example.com">
                    <label for="inputPrefixAlarm" class="form-label" >{{ $t("input_prefix_alarm") }}</label>
                </div>
            </div>
            <div class="col-6">
                <div class="form-floating">
                    <input id="inputPrefixEvu" type="text" name="name" class="form-control"  v-model="plantSystem.prefix_evu" placeholder="name@example.com">
                    <label for="inputPrefixEvu" class="form-label" >{{ $t("input_prefix_evu") }}</label>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-4">
                <div class="form-floating">
                    <input id="inputPrefixAlarm" type="text" name="name" class="form-control"  v-model="plantSystem.file_extension_import_data" placeholder="name@example.com">
                    <label for="inputPrefixAlarm" class="form-label" >{{ $t("file_extension_import_data") }}</label>
                </div>
            </div>
            <div class="col-4">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" name="import_data_compromised" id="selectionMonth" v-model="plantSystem.import_data_compromised">
                    <label class="form-check-label" for="import_data_compromised">
                        {{ $t("import_data_compromised") }}
                    </label>
                </div>
            </div>
            <div class="col-4">
                <div class="form-floating">
                    <input id="inputPrefixAlarm" type="text" name="name" class="form-control"  v-model="plantSystem.import_data_element_tag" placeholder="name@example.com">
                    <label for="inputPrefixAlarm" class="form-label" >{{ $t("import_data_element_tag") }}</label>
                </div>
            </div>
            <div class="col-4" v-if="plantSystem.import_data_compromised">
                <div class="form-floating">
                    <input id="import_data_compromised_extension" type="text" name="name" class="form-control"  v-model="plantSystem.import_data_compromised_extension" placeholder="name@example.com">
                    <label for="import_data_compromised_extension" class="form-label" >{{ $t("import_data_compromised_extension") }}</label>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-6">
                <div class="form-floating">
                    <input id="inputPrefixEvu" type="text" name="name" class="form-control"  v-model="plantSystem.file_extension_import_alarm" placeholder="name@example.com">
                    <label for="inputPrefixEvu" class="form-label" >{{ $t("file_extension_import_alarm") }}</label>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-6">
                <div class="form-floating">
                    <input id="inputPrefixAlarm" type="text" name="name" class="form-control"  v-model="plantSystem.import_separator" placeholder="name@example.com">
                    <label for="inputPrefixAlarm" class="form-label" >{{ $t("import_separator") }}</label>
                </div>
            </div>
            <div class="col-6">
                <div class="form-floating">
                    <input id="inputPrefixEvu" type="text" name="name" class="form-control"  v-model="plantSystem.import_encoding" placeholder="name@example.com">
                    <label for="inputPrefixEvu" class="form-label" >{{ $t("import_encoding") }}</label>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-6">
                <div class="form-floating">
                    <input id="inputPrefixAlarm" type="text" name="name" class="form-control"  v-model="plantSystem.import_data_header" placeholder="name@example.com">
                    <label for="inputPrefixAlarm" class="form-label" >{{ $t("import_data_header") }}</label>
                </div>
            </div>
            <div class="col-6">
                <div class="form-floating">
                    <input id="inputPrefixAlarm" type="text" name="name" class="form-control"  v-model="plantSystem.alarmmessage_folder" placeholder="name@example.com">
                    <label for="inputPrefixAlarm" class="form-label" >{{ $t("alarmmessage_folder") }}</label>
                </div>
            </div>
        </div>
        
        <div class="row mt-2">
            <div class="col-6" >
                <div class="form-floating">
                    <input :disabled="!$store.state.me.is_superuser" id="inputName" type="text" name="name" class="form-control"  v-model="plantSystem.import_prefix_sernum" placeholder="name@example.com">
                    <label for="inputName" class="form-label" >{{ $t("import_prefix_sernum") }}</label>
                </div>
            </div>
            <div class="col-6">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" name="compromise_files" id="selectionMonth" v-model="plantSystem.compromise_files">
                    <label class="form-check-label" for="compromise_files">
                        {{ $t("compromise_files") }}
                    </label>
                </div>
            </div>
        </div>
        
        <button class="btn btn-success mt-2 ms-auto" @click="submitForm">{{ $t("save") }}</button>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'EditSystem',
    emits: ['closeModal'],
    props: {
        plantSystem: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            
        }
    },

    methods: {
        submitForm() {

            axios
                .patch(`/api/v1/plantsystems/${this.plantSystem.id}/`, this.plantSystem)
                .then(response => {
                    
                    createToast({text: this.$t("plantSystem altered")
                   ,type: 'success'})

                   this.$emit('closeModal', 'edit', response.data)
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
        }
    }
}
</script>