<template>
    <div class="row">
        <div class="col-12">
            <div class="card shadow-sm">
                <div class="card-body scroll-y">
                    <table class="table table-striped table-hover table-bordered">
                        <thead>
                            <tr>
                                <th>{{ $t("plant") }}</th>
                                <th>{{ $t("price_per_month") }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="plantPrice in plantPriceData"
                                v-bind:key="plantPrice">
                                <td> {{ plantPrice.name }}</td>
                                <td> {{ plantPrice.price_per_month }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { createToast } from '@/assets/js/myToast.js'
export default {
    name: 'PlantPricesForOrganisation',
    components: {
    },
    props: {
        organisation: {
            type: Object,
            required: true
        },
        
    },
    watch: {
        organisation: {
            deep: true,
            handler() {
                console.log('watch fired')
                this.getPlantPriceData()
            }
        }
    },
    data() {
        return {
            plantPriceData: {}
        }
    },
    mounted(){
        this.getPlantPriceData()
    },
    updated(){
        console.log('entry updated')
        
        console.log('exit updated')
    },
    methods: {

        getPlantPriceData(){
            console.log('entry getPlantPriceData')
            axios
                .get(`/api/v1/organisations/${this.organisation.id}/get_plant_price_overview/`)
                .then(response => {
                    console.log('fertig')
                    console.log(response.data)
                    this.plantPriceData = response.data
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit getPlantPriceData')
        },

    },
    computed:{
                
    }
}
</script>
