<template>
    <ul class="nav nav-sidebar flex-column ms-2">
        <li class="nav-item" v-if="this.editPlantDashboard">
            <a class="nav-link" href="#" @click="newDashboard()"><i class="fa-solid fa-plus" ></i> {{ $t("new_dashboard") }}</a>
        </li>
        <li class="nav-item" v-for="dashboard in plantDashboards"
            v-bind:key="dashboard.id">
            <a class="nav-link" :class="{'active': this.currentDashboard === dashboard}" href="#" @click="setDashboard(dashboard)">
                {{dashboard.name}}
                <i class="fa-solid fa-pencil" v-if="this.editPlantDashboard" @click="editDashboard(dashboard)"></i>
            </a>
        </li>
    </ul>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'NavPlantDashboardSidebar',
    emits: [
        'setDashboard',
        'newDashboard',
        'editDashboard'
    ],
    components: {
    },
    props: {
        plantDashboards: {
            type: Object,
            required: true
        },
        editPlantDashboard:{
            type: Boolean,
            required: false,
            default: false
        },
    },
    data() {
        return {
            currentDashboard: ""
        }
    },
    mounted(){
        
    },
    updated(){
        console.log('updated')
        
    },
    unmounted(){
        console.log('component unmounted')
        
        
    },
    methods: {
        editDashboard(dashboard){
            console.log('entry editDashboard')
            this.currentDashboard = dashboard
            this.$emit('editDashboard', dashboard)
            console.log('exit editDashboard')
        },
        newDashboard(){
            console.log('entry newDashboard')
            this.$emit('newDashboard')
            console.log('exit newDashboard')
        },
        setDashboard(dashboard){
            console.log('entry setDashboard')
            this.currentDashboard = dashboard
            this.$emit('setDashboard', dashboard)
            console.log('exit setDashboard')
        },
       
    },
    computed:{
        
    }
}
</script>