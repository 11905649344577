<template>
    <ul class="nav nav-sidebar flex-column ms-2">
        <li class="nav-item" v-if="this.canCreateUserGroup">
            <a class="nav-link" href="#" @click="newUserGroup()"><i class="fa-solid fa-plus" ></i> {{ $t("new_user_group") }}</a>
        </li>
        <li class="nav-item" v-for="group in userGroups"
            v-bind:key="group.id">
            <a class="nav-link nav-toggle collapsed" :class="{'active': this.currentUserGroup === group}" href="#" 
                @click="setUserGroup(group)">
                {{ group.grp_name }} 
                <i class="fa-solid fa-trash" @click="deleteGroup(group)"></i>
            </a>
            <div class="collapse" :id="group.grp_name">
                <ul class="nav flex-column offset-1">
                    <li class="nav-item" >
                        <a class="nav-link" :class="{'active': this.currentSubList === 'user_group_user_detail' && this.currentUserGroup === group}" href="#" @click="editUserGroup(group)">
                            {{ $t("user_group_detail") }}
                        </a>
                    </li>
                    <li class="nav-item" >
                        <a class="nav-link" :class="{'active': this.currentSubList === 'user_group_user_mapping' && this.currentUserGroup === group}" href="#" @click="editUserGroupUserMapping(group)">
                            {{ $t("user_group_user_mapping") }}
                        </a>
                    </li>
                    <li class="nav-item" >
                        <a class="nav-link" :class="{'active': this.currentSubList === 'user_group_plant_mapping' && this.currentUserGroup === group}" href="#" @click="editUserGroupPlantMapping(group)">
                            {{ $t("user_group_plant_mapping") }}
                        </a>
                    </li>
                </ul>
            </div>
        </li>
    </ul>  
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'NavUserGroupsSidebar',
    emits: [
        'setUserGroup',
        'newUserGroup',
        'editUserGroup',
        'deleteUserGroup'
    ],
    components: {
    },
    props: {
        userGroups: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            currentUserGroup: "",
            currentSubList: ""
        }
    },
    mounted(){
        
    },
    updated(){
        console.log('updated')
        
    },
    unmounted(){
        console.log('component unmounted')
        
        
    },
    methods: {
        deleteGroup(userGroup){
            console.log('entry deleteGroup')
            this.currentUserGroup = userGroup
            this.$emit('deleteUserGroup', userGroup)
            
            console.log('exit deleteGroup')
        },
        editUserGroupUserMapping(userGroup){
            console.log('entry editUserGroupUserMapping')
            this.currentSubList = 'user_group_user_mapping'
            this.currentUserGroup = userGroup
            this.$emit('editUserGroupUserMapping', userGroup)
            console.log('exit editUserGroupUserMapping')
        },
        editUserGroupPlantMapping(userGroup){
            console.log('entry editUserGroupPlantMapping')
            this.currentSubList = 'user_group_plant_mapping'
            this.currentUserGroup = userGroup
            this.$emit('editUserGroupPlantMapping', userGroup)
            console.log('exit editUserGroupPlantMapping')
        },
        editUserGroup(userGroup){
            console.log('entry editUserGroup')
            this.currentUserGroup = userGroup
            this.currentSubList = 'user_group_user_detail'
            this.$emit('editUserGroup', userGroup)
            console.log('exit editUserGroup')
        },
        newUserGroup(){
            console.log('entry newUserGroup')
            this.$emit('newUserGroup')
            console.log('exit newUserGroup')
        },
        setUserGroup(userGroup){
            console.log('entry setUserGroup')
            this.currentUserGroup = userGroup
            this.$emit('setUserGroup', userGroup)
            console.log('exit setUserGroup')
        },
       
    },
    computed:{
        canCreateUserGroup(){
            return this.$store.getters.userHasPermission('user_group', 'can_write')
        }
    }
}
</script>