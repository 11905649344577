<template>
    <div class="row mt-3">
        <div class="col-3">
            <ul class="list-group ">
                <li :class="{'active': this.activeHeader === header}" class="list-group-item" v-for="header in organisationDailyReport.daily_report_headers"
                v-bind:key="header.id" >
                    <div class="row">
                        <div class="col-7 cursor-pointer" @click="setActiveHeader(header)">
                            {{header.name}} <div v-if="header.page_break_after_pdf" class="small"> --Seitenumbruch--</div>
                        </div>
                        <div class="col-1">
                            <i class="fa-solid fa-arrow-up" @click="moveHeaderUp(header)"></i>
                        </div>
                        <div class="col-1">
                            <i class="fa-solid fa-arrow-down" @click="moveHeaderDown(header)"></i>
                        </div>
                        <div class="col-1">
                            <i class="fa-solid fa-pencil" @click="editHeader(header)" data-bs-toggle="modal" data-bs-target="#modalHeader"></i>
                        </div>
                        <div class="col-1">
                            <i class="fa-solid fa-trash" @click="deleteHeader(header)"></i>
                        </div>
                    </div>
                        
                </li>
                <li class="list-group-item cursor-pointer" @click="setNewHeader()"
                            data-bs-toggle="modal" data-bs-target="#modalHeader">
                    <i class="fa-solid fa-plus" ></i>
                </li>
            </ul>
        </div>
        <div class="col-3">
            <EditOrganisationDailyReportSubHeaders v-if="this.activeHeader" v-bind="currentPropertiesSubHeader" v-on:setActiveSubHeader="setActiveSubHeader"/>
        </div>
        <div class="col-6">
            
            <EditOrganisationDailyReportPositions v-if="this.activeSubHeader" v-bind="currentPropertiesPositions" v-on:addPositionToSubHeader="addPositionToSubHeader"/>
        </div>
    </div>

    <div class="row my-3" v-if="$store.state.me.is_superuser">
        <div class="col-12 text-end">
            <button  class="btn btn-success mb-0 ms-auto" @click="getDailyReport">{{ $t("test") }}</button>
        </div>
    </div>

    <div class="modal fade" id="modalHeader" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-scrollable modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalLabel">{{ $t(this.currentComponent) }}</h5>
                    <button type="button" id="modalHeaderClose" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body modal-textarea">
                    <component v-if="this.currentComponent" :is="currentComponent" v-bind="currentProperties" v-on:closeModal="closeModal"></component>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t("close") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
const fileDownload = require('js-file-download')
import axios from 'axios'

import EditOrganisationDailyReportSubHeaders from '@/components/dailyreport/EditOrganisationDailyReportSubHeaders.vue'
import EditOrganisationDailyReportPositions from '@/components/dailyreport/EditOrganisationDailyReportPositions.vue'
import AddOrganisationDailyReportHeader from '@/components/dailyreport/AddOrganisationDailyReportHeader.vue'
import EditOrganisationDailyReportHeader from '@/components/dailyreport/EditOrganisationDailyReportHeader.vue'
export default {
    name: 'EditOrganisationDailyReport',
    emits: [],
    components: {
        EditOrganisationDailyReportHeader,
        AddOrganisationDailyReportHeader,
        EditOrganisationDailyReportSubHeaders,
        EditOrganisationDailyReportPositions
        },
    props: {
        organisationDailyReportId: {
            type: Number,
            required: true,
        },
        
    },
    data() {
        return {
            activeHeader: null,
            activeSubHeader: null,
            currentComponent: "",
            organisationDailyReport: {},
            organisationDailyReportIdOld: 0
        }
    },
    mounted(){
        this.getOrganisationDailyReportFromBackend()
    },
    updated(){
        console.log('entry updated')
        if(this.porganisationDailyReportIdOld === 0){
            this.organisationDailyReportIdOld = this.organisationDailyReportId
        }
        if(this.organisationDailyReportIdOld != this.organisationDailyReportId){
            this.getOrganisationDailyReportFromBackend()
            this.organisationDailyReportIdOld = this.organisationDailyReportId
            this.activeHeader = null
            this.activeSubHeader = null
        }
        console.log('exit updated')
    },
    methods: {
        getDailyReport(){
            console.log('entry getDailyReport')
            var formData = {}

            formData.type_data = 'pdf'
            formData.month=12
            formData.year=2022
            axios
                .post(`/api/v1/organisations/${this.organisationDailyReport.organisation}/${this.organisationDailyReport.id}/get_daily_report/`, formData, {responseType: 'blob'})
                .then(response => {
                    console.log('fertig')
                    fileDownload(response.data, `Tagesbericht.pdf`);
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
            console.log('exit getDailyReport')
        },
        addPositionToSubHeader(position){
            console.log('entry addPositionToSubHeader')
            this.activeSubHeader.daily_report_positions.push(position)
            console.log('exit addPositionToSubHeader')
        },  
        setActiveSubHeader(subHeader){
            console.log('entry setActiveSubHeader')
            this.activeSubHeader = subHeader
            console.log('exit setActiveSubHeader')
        },
        sortHeaderArray(){
            console.log('entry sortHeaderArray')
            this.organisationDailyReport.daily_report_headers = this.organisationDailyReport.daily_report_headers.sort((a,b) => {
                if(a.position < b.position){
                    return -1
                }
                if (a.position > b.position){
                    return 1
                }
                return 0
            })
            this.updateHeadersBackend()
            console.log('exit sortHeaderArray')
        },
        setNewHeader(){
            console.log('entry setNewHeader')
            this.currentComponent = "AddOrganisationDailyReportHeader",
            console.log('exit setNewHeader')
        },
        editHeader(header){
            console.log('entry editHeader')
            this.currentComponent = 'EditOrganisationDailyReportHeader'
            this.activeHeader = header
            this.activeSubHeader = ''
            console.log('exit editHeader')
        },
        deleteHeader(header){
            console.log('entry deleteHeader')
            axios
                .delete(`/api/v1/organisation_daily_report_headers/${header.id}/`)
                .then(response => {
                    createToast({text: this.$t("header deleted")
                    ,type: 'success'})
                    this.deleteHeaderFromFrontend(header)
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            this.activeHeader = ''
            console.log('exit deleteHeader')
        },
        deleteHeaderFromFrontend(header){
            console.log('entry deleteHeaderFromFrontend')
            let position = 1
            let pos_delete = this.organisationDailyReport.daily_report_headers.map(e => e.id).indexOf(header.id)
            console.log('pos_delete: ' + pos_delete)
            this.organisationDailyReport.daily_report_headers.splice(pos_delete, 1)
            this.organisationDailyReport.daily_report_headers.forEach(element => {
                element.position = position
                position++
                axios
                    .patch(`/api/v1/organisation_daily_report_headers/${element.id}/`, element)
                    .then(response => {
                        console.log('response')
                        console.log(response.data)
                    })
                    .catch(error => {
                        if (error.response) {
                            if ('detail' in error.response.data){
                                createToast({text: this.$t(error.response.data['detail'])
                                            ,type: 'error'})
                            }                        
                            console.error(JSON.stringify(error.response.data))
                        } else if (error.message) {
                            console.error(JSON.stringify(error.message))
                        } else {
                            console.error(JSON.stringify(error))
                        }
                    })
            })
            console.log('exit deleteHeaderFromFrontend')
        },
        moveHeaderUp(header){
            console.log('entry moveHeaderUp')
            if(header.position < 2){
                return
            }
            let header_to_switch = this.organisationDailyReport.daily_report_headers.filter(array_header => array_header.position === header.position -1)

            header_to_switch.forEach((value,index) =>{
                value.position = header.position
            })
            header.position--
            this.sortHeaderArray()
            console.log('exit moveHeaderUp')
        },
        moveHeaderDown(header){
            console.log('entry moveHeaderDown')
            if(header.position >= this.organisationDailyReport.daily_report_headers.length){
                return
            }
            let header_to_switch = this.organisationDailyReport.daily_report_headers.filter(array_header => array_header.position === header.position +1)

            header_to_switch.forEach((value,index) =>{
                value.position = header.position
            })
            header.position++
            this.sortHeaderArray()
            console.log('exit moveHeaderDown')
        },
        setActiveHeader(header){
            console.log('entry setActiveHeader')
            this.activeHeader = header
            this.activeSubHeader = ''
            console.log('exit setActiveHeader')
        },
        updateHeadersBackend(){
            console.log('entry updateHeadersBackend')
            this.organisationDailyReport.daily_report_headers.forEach(element => {
                axios
                    .patch(`/api/v1/organisation_daily_report_headers/${element.id}/`, element)
                    .then(response => {
                        console.log(response)
                    })
                    .catch(error => {
                        if (error.response) {
                            if ('detail' in error.response.data){
                                createToast({text: this.$t(error.response.data['detail'])
                                            ,type: 'error'})
                            }                        
                            console.error(JSON.stringify(error.response.data))
                        } else if (error.message) {
                            console.error(JSON.stringify(error.message))
                        } else {
                            console.error(JSON.stringify(error))
                        }
                    })
                })
            console.log('exit updateHeadersBackend')
        },
        getOrganisationDailyReportFromBackend(){
            console.log('entry getOrganisationDailyReportFromBackend')
            axios
                .get(`/api/v1/organisation_daily_reports/${this.organisationDailyReportId}/`)
                .then(response => {
                    console.log(response)
                    this.organisationDailyReport = response.data
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit getOrganisationDailyReportFromBackend')
        },
        closeModal(action, element){
            console.log("closeModal")
            document.getElementById('modalHeaderClose').click();
            if(action === 'add'){
                this.organisationDailyReport.daily_report_headers.push(element)
            }
        },
    },
    computed:{
        currentProperties(){
            
            return {organisationDailyReport: this.organisationDailyReport, 
                organisationDailyReportHeader: this.activeHeader,
                headerPosition: this.organisationDailyReport.daily_report_headers.length +1,
                }
        },
        currentPropertiesSubHeader(){
            
            return {organisationDailyReport: this.organisationDailyReport, 
                organisationDailyReportHeaderId: this.activeHeader.id
                }
        },
        currentPropertiesPositions(){
            
            return {organisationDailyReport: this.organisationDailyReport, 
                organisationDailyReportHeader: this.activeHeader,
                organisationDailyReportSubHeader: this.activeSubHeader
                }
        },
    }
}
</script>