<template>
    <div class="page-add-user">

        <div class="container-fluid my-3 py-3">
            <div class="row my-3">
                <div class="col-12">
                    <div class="form-floating">
                        <input id="inputName" type="text" name="name" class="form-control"  v-model="component.name" @keyup.enter="submitForm" placeholder="name@example.com">
                        <label for="inputName" class="form-label" >{{ $t("name") }}</label>
                    </div>
                </div>
                
            </div>
            <div class="row my-3">
                <div class="col-6">
                    <div class="form-floating">
                        <input id="time_diff_warn" type="text" name="name" class="form-control"  v-model="component.time_diff_warn" @keyup.enter="submitForm" placeholder="name@example.com">
                        <label for="time_diff_warn" class="form-label" >{{ $t("time_diff_warn") }}</label>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-floating">
                        <input id="time_diff_error" type="text" name="name" class="form-control"  v-model="component.time_diff_error" @keyup.enter="submitForm" placeholder="name@example.com">
                        <label for="time_diff_error" class="form-label" >{{ $t("time_diff_error") }}</label>
                    </div>
                </div>
            </div>
            <template v-if="$store.state.me.is_superuser">
            
                <div class="row my-3" >
                    <div class="col-12">
                        <label for="type_component" class="form-label">{{ $t("type_component") }}</label>
                        <select id="type_component" class="form-select " aria-label="Default select example" v-model="component.type_component">
                            <option value="DA">{{ $t("component_type_component_da") }}</option>
                            <option value="SU">{{ $t("component_type_component_su") }}</option>
                        </select>
                    </div>
                </div>  
                <div class="row my-3">
                    <div class="col-12">
                        <div class="form-floating">
                            <input id="inputName" type="text" name="name" class="form-control"  v-model="component.folder_name_import" @keyup.enter="submitForm" placeholder="name@example.com">
                            <label for="inputName" class="form-label" >{{ $t("folder_name_import") }}</label>
                        </div>
                    </div>
                
                </div>
                <div class="row my-3" >
                    <div class="col-6">
                        <div class="form-floating">
                            <input id="inputName" type="text" name="name" class="form-control"  v-model="component.dashboard_group_name" @keyup.enter="submitForm" placeholder="name@example.com">
                            <label for="inputName" class="form-label" >{{ $t("dashboard_group_name") }}</label>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-floating">
                            <input id="inputName" type="text" name="name" class="form-control"  v-model="component.daily_report_group_name" @keyup.enter="submitForm" placeholder="name@example.com">
                            <label for="inputName" class="form-label" >{{ $t("daily_report_group_name") }}</label>
                        </div>
                    </div>
                </div>
                <div class="row my-3" >
                    <div class="col-4">
                        <div class="form-floating">
                            <input id="inputName" type="text" name="name" class="form-control"  v-model="component.import_data_date_column_name" @keyup.enter="submitForm" placeholder="name@example.com">
                            <label for="inputName" class="form-label" >{{ $t("import_data_date_column_name") }}</label>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-floating">
                            <input id="inputName" type="text" name="name" class="form-control"  v-model="component.import_data_time_column_name" @keyup.enter="submitForm" placeholder="name@example.com">
                            <label for="inputName" class="form-label" >{{ $t("import_data_time_column_name") }}</label>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-floating">
                            <input id="inputName" type="text" name="name" class="form-control"  v-model="component.import_data_datetime_format" @keyup.enter="submitForm" placeholder="name@example.com">
                            <label for="inputName" class="form-label" >{{ $t("import_data_datetime_format") }}</label>
                        </div>
                    </div>
                
            </div>
            <div class="row my-3" v-if="$store.state.me.is_superuser">
                <div class="col-4">
                    <div class="form-floating">
                        <input id="inputName" type="text" name="name" class="form-control"  v-model="component.file_prefix" @keyup.enter="submitForm" placeholder="name@example.com">
                        <label for="inputName" class="form-label" >{{ $t("file_prefix") }}</label>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-floating">
                        <input id="inputName" type="text" name="name" class="form-control"  v-model="component.sernum" @keyup.enter="submitForm" placeholder="name@example.com">
                        <label for="inputName" class="form-label" >{{ $t("sernum") }}</label>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-floating">
                        <input id="inputName" type="text" name="name" class="form-control"  v-model="component.import_nth_entry" @keyup.enter="submitForm" placeholder="name@example.com">
                        <label for="inputName" class="form-label" >{{ $t("import_nth_entry") }}</label>
                    </div>
                </div>
            </div>
            </template>
            
            <button class="btn btn-success mb-0 ms-auto" @click="submitForm" >{{ $t("save") }}</button>
        </div>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'UpdateComponent',
    emits: ['closeModal'],
    props: {
        plant: {
            type: Object,
            required: true,
        },
        component: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            
        }
    },

    methods: {
        submitForm() {
            for (let i = 0; i < this.component.component_data_description.length; i++) {                
                delete this.component.component_data_description[i].component
            }
            console.log('component.folder_name_import: ' + this.component.folder_name_import)
            if(!this.component.folder_name_import){
                this.component.folder_name_import = null
            }
            axios
                .patch(`/api/v1/components/${this.component.id}/`, this.component)
                .then(response => {
                    
                    createToast({text: this.$t("component updated")
                   ,type: 'success'})

                   this.$emit('closeModal', 'update', response.data)
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
        }
    }
}
</script>