<template>
    <ul class="dropdown-menu" aria-labelledby="dropdownAdminSettings">
        <li><a class="dropdown-item dropdown-toggle" href="#" id="dropdownAdminSettingsPlants" data-bs-toggle="dropdown" aria-expanded="false">
                Anlagen
            </a>
            <ul class="submenu dropdown-menu" aria-labelledby="dropdownAdminSettingsPlants">
                <li ><router-link to="/settings/masterdata/plantsystems" class="dropdown-item">{{ $t("plantsystems") }}</router-link></li>
                <li ><router-link to="/settings/masterdata/planttypes" class="dropdown-item">{{ $t("planttypes") }}</router-link></li>
                <li ><router-link to="/settings/masterdata/planttypeevaluations" class="dropdown-item">{{ $t("planttypeevaluations") }}</router-link></li>
                <li><router-link to="/settings/alarmcodesgeneral" class="dropdown-item">{{ $t("alarmcodesgeneral") }}</router-link></li>
                <li><router-link to="/admin/create_new_plant" class="dropdown-item">{{ $t("create_new_plant") }}</router-link></li>
            </ul>
        </li>
        <li><a class="dropdown-item dropdown-toggle" href="#" id="dropdownAdminSettingslizenzen" data-bs-toggle="dropdown" aria-expanded="false">
                Lizenzen
            </a>
            <ul class="submenu dropdown-menu" aria-labelledby="dropdownAdminSettingslizenzen">
                <li><router-link to="/settings/organisation_licenses" class="dropdown-item">{{ $t("organisation_licenses") }}</router-link></li>
                <li><router-link to="/billing/plant_price_overview" class="dropdown-item">{{ $t("plant_prices") }}</router-link></li>
                
            </ul>
        </li>
        <li><a class="dropdown-item dropdown-toggle" href="#" id="dropdownAdminSettingstest" data-bs-toggle="dropdown" aria-expanded="false">
                System
            </a>
            <ul class="submenu dropdown-menu" aria-labelledby="dropdownAdminSettingstest">
                <li ><router-link to="/settings/masterdata/organisations" class="dropdown-item">{{ $t("organisations") }}</router-link></li>
                <li><router-link to="/admin/logs" class="dropdown-item">{{ $t("activity_logs") }}</router-link></li>
                <li><router-link to="/admin/trigger_backend_tasks" class="dropdown-item">{{ $t("trigger_backend_tasks") }}</router-link></li>
                <li ><router-link to="/settings/system_overview" class="dropdown-item">{{ $t("system_overview") }}</router-link></li>
                
            </ul>
        </li>
    </ul>
</template>
<script>
    
export default {
    name: 'AdminSettings',
    emits: [
            ],
    components: {
        },
    props: {
        
    },
    data() {
        return {
            
        }
    },
    mounted(){
    },
    updated(){
        
    },
    methods: {
        
    },
    computed:{
        
    }
}
</script>