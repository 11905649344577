<template>
    <div class="page-login mt-2">
        <div class="row justify-content-center">
            <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
                <div class="card shadow-sm">
                    <div class="card-header text-center pt-4">
                        <h5>{{ $t("Log in") }}</h5>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submitForm">
                            <div class="mb-3">
                                <div class="form-floating">
                                    <input id="inputUsername" ref="login" type="text" name="username" class="form-control" v-model="username" placeholder="name@example.com">
                                    <label for="inputUsername" class="form-label" >{{ $t("username") }}</label>
                                </div>
                            </div>

                            <div class="mb-3">
                                <div class="input-group">
                                    <div class="form-floating form-floating-group flex-grow-1">
                                        <input ref="password" id="inputPassword" :type="passwordFieldType" name="password" class="form-control" v-model="password" aria-describedby="hideUnhide" placeholder="test" required>
                                        <label for="inputPassword" class="form-label">{{ $t("Passwort") }}</label>
                                    </div>
                                    <span class="input-group-text" id="hideUnhide" @click="switchVisibility">
                                        <i class="fa-solid fa-eye" v-if='this.passwordFieldType === "password"'></i>
                                        <i class="fa-solid fa-eye-slash" v-if='this.passwordFieldType === "text"'></i>
                                    </span>
                                </div>
                                <router-link to="/forgot-password" class="">{{ $t("Passwort vergessen?") }}</router-link>
                            </div>

                            <div class="notification is-danger" v-if="errors.length">
                                <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                            </div>

                            <div class="text-center">
                                <button class="btn btn-success w-100" :disabled="loading">
                                    <template v-if="loading">
                                        <div class="lds-ring" ><div></div><div></div><div></div><div></div></div>
                                    </template>
                                    Login
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { useHead } from '@vueuse/head'
export default {
    name: 'LogIn',
    setup () {
      useHead({
        meta: [
          {
            name: `robot`,
            content: 'noindex',
          },
        ],
      })
    },
    data() {
        return {
            username: '',
            password: '',
            passwordFieldType: "password",
            errors: [],
            loading: false,
        }
    },
    mounted(){
        console.log('entry mounted')
        this.focusInput()
        console.log('exit mounted')
    },
    methods: {
        focusInput() {
            this.$refs.login.focus();
        },
        async submitForm(e) {
            this.loading = true
            console.log('loading auf true')
            this.errors= []
            axios.defaults.headers.common["Authorization"] = ""

            localStorage.removeItem("token")

            const formData = {
                username: this.username,
                password: this.password,
            }

            let hasError = false
            await axios
                .post("/api/v1/token/login/", formData)
                .then(response => {
                    console.log(response)
                    console.log(response.data)
                    const token = response.data.auth_token

                    this.$store.commit('setToken', token)
                    
                    axios.defaults.headers.common["Authorization"] = "Token " + token

                    localStorage.setItem("token", token)
                })
                .catch(error => {
                    console.info('im catch teil')
                    this.loading = false
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${error.response.data[property]}`)
                        }

                        console.log(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.log(JSON.stringify(error.message))
                    } else {
                        console.log(JSON.stringify(error))
                    }
                    hasError = true
                })
            if(hasError){
                return
            }
            console.log('no error continue')
            await axios
                .get("/api/v1/users/me/")
                .then(response => {
                    console.log('then teil von users/me')
                    console.log(response)
                    this.$store.commit('setUser', {'username': response.data.username, 'id': response.data.id})

                    localStorage.setItem('username', response.data.username)
                    localStorage.setItem('userid', response.data.id)
                    this.$store.commit('setMe', response.data)
                    if(response.data.user_alert_text){
                        document.getElementById('modalOpenAlert').click();
                    }
                })
                .catch(error => {
                    this.loading = false
                    console.log(JSON.stringify(error))
                })
            await axios
                .get('/api/v1/dashboard/')
                .then(response => {
                    console.log('dashboard/plants loaded')
                    console.log(response.data)
                    this.$store.commit('setPlants', response.data)
                    
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                    this.loading = false
                })
            await axios
                .get('/api/v1/authgroup/')
                .then(response => {
                    console.log('then authgroup')
                    console.log(response)
                    this.$store.commit('setPermissions', response.data[0])
                    this.$store.commit('setIsAuthenticated', true)
                    
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                    this.loading = false
                })
            
            console.log('superuser? ' + this.$store.state.me.is_superuser)
            const organisationID = this.$store.state.me.organisation
            axios
                .get(`/api/v1/organisations/${organisationID}/`)
                .then(response => {
                    console.log('then organisations')
                    console.log(response)
                    this.$store.commit('setOrganisation', response.data)
                    
                    this.$router.push('/dashboard')
                })
                .catch(error => {
                    this.loading = false
                    console.log(JSON.stringify(error))
                })
            
        },
        switchVisibility(){
            this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
            this.$refs.password.focus();
        }
    }
}
</script>