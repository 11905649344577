<template>
    <div class="container-fluid ">
        <div class="row">
            <div class="col-12">
                <div class="form-floating">
                    <input id="inputName" type="text" name="name" class="form-control"  v-model="plantSystem.name" @keyup.enter="submitForm" placeholder="name@example.com">
                    <label for="inputName" class="form-label" >{{ $t("name") }}</label>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-6">
                <div class="form-floating">
                    <input id="inputPrefixAlarm" type="text" name="name" class="form-control"  v-model="plantSystem.prefix_alarmmessages" placeholder="name@example.com">
                    <label for="inputPrefixAlarm" class="form-label" >{{ $t("input_prefix_alarm") }}</label>
                </div>
            </div>
            <div class="col-6">
                <div class="form-floating">
                    <input id="inputPrefixEvu" type="text" name="name" class="form-control"  v-model="plantSystem.prefix_evu" placeholder="name@example.com">
                    <label for="inputPrefixEvu" class="form-label" >{{ $t("input_prefix_evu") }}</label>
                </div>
            </div>
        </div>
        <button class="btn btn-success mt-2 mb-0 ms-auto" @click="submitForm">{{ $t("save") }}</button>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'AddSystem',
    emits: ['closeModal'],
    props: {
    },
    data() {
        return {
            plantSystem: {
            }
        }
    },

    methods: {
        submitForm() {

            this.plantSystem.created_by = this.$store.state.me.id
            this.plantSystem.modified_by = this.$store.state.me.id
            axios
                .post(`/api/v1/plantsystems/`, this.plantSystem)
                .then(response => {
                    
                    createToast({text: this.$t("plantSystem added")
                   ,type: 'success'})

                   this.$emit('closeModal', 'add', response.data)
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
        }
    }
}
</script>