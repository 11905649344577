<template>
   <div class="col-md-3 col-lg-2 d-md-block bg-light sidebar">
        <NavPlantTypesSidebar v-if="allPlantTypes" v-bind="currentPlantTypesProperties" 
            v-on:setType="setType" v-on:newType="newType" v-on:editType="editType"></NavPlantTypesSidebar>
    </div>
    <div class="col-md-9 ms-sm-auto col-lg-10">
       <!-- <component :is="currentTabComponent" v-if="this.currentType" v-bind="currentProperties"></component>-->
    </div>
    <button id="modalOpen" style="display:none;" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#modalPlantType" aria-hidden="true">hidden</button>
    <div class="modal fade" id="modalPlantType" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-scrollable modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalLabel">{{ $t(this.currentComponent) }}</h5>
                    <button type="button" id="modalPlantTypeClose" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body modal-textarea">
                    <component v-if="allPlantTypes" :is="currentComponent" v-bind="currentModalProperties" v-on:closeModal="closeModal"></component>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t("close") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    
import NavPlantTypesSidebar from '@/components/navs/NavPlantTypesSidebar.vue'
import AddType from '@/components/PlantTypes/AddType'
import EditType from '@/components/PlantTypes/EditType'
import axios from 'axios'
export default {
    name: 'PlantTypes',
    components: {
        NavPlantTypesSidebar,
        AddType,
        EditType
    },
    data() {
        return {
            currentType: "",
            allPlantTypes: "",
            currentTabComponent: "EditSystemOrganisationMapping",
            currentComponent: "AddType"
        }
    },
    mounted(){
        this.getPlantTypes()
    },
    updated(){
        console.log('updated')
        
    },
    unmounted(){
        console.log('component unmounted')
        
        
    },
    methods: {
        editType(type){
            console.log('entry editType')
            this.currentType = type
            this.currentComponent = "EditType"
            document.getElementById('modalOpen').click();
            console.log('exit editType')
        },
        closeModal(action, data){
            console.log('entry closeModal with action: ' + action)
            document.getElementById('modalPlantTypeClose').click();
            console.log('exit closeModal')
        },
        newType(){
            console.log('entry newType')
            this.currentComponent = "AddType"
            document.getElementById('modalOpen').click();
            console.log('exit newType')
        },
        setType(type){
            console.log('entry setType')
            this.currentType = type
            console.log('exit setType')
        },  
        getPlantTypes(){
            console.log('entry getPlantTypes')
             axios
                .get('/api/v1/planttypes/')
                .then(response => {
                    this.allPlantTypes = response.data
                    console.log('planttypes fertig geladen')
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit getPlantTypes')
        },
         
    },
    computed:{
        plantsLoaded(){
            if(this.$store.getters.getPlants){
                return true
            }
            return false
        },
        organisation(){
            return this.$store.getters.getOrganisation
        },
   
        currentPlantTypesProperties(){
                return {plantTypes: this.allPlantTypes,
                    editPlantType: true}
            },
        currentProperties(){
                return {plantType: this.currentType}
            },
        currentModalProperties(){
                return {plantType: this.currentType,}
            },
    }
}
</script>
