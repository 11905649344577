<template>
    <ul class="list-group ">
        <li :class="{'active': this.activePosition === position}" class="list-group-item" v-for="position in this.plantDashboardHeader.plant_dashboard_positions"
            v-bind:key="position.id" >
            <div class="row">
                <div class="col-8 cursor-pointer" @click="setActivePosition(position)">
                    {{position.name}} 
                </div>
                <div class="col-1">
                    <i class="fa-solid fa-arrow-up" @click="movePositionUp(position)"></i>
                </div>
                <div class="col-1">
                    <i class="fa-solid fa-arrow-down" @click="movePositionDown(position)"></i>
                </div>
                <div class="col-1">
                    <i class="fa-solid fa-pencil" @click="editPosition(position)" data-bs-toggle="modal" data-bs-target="#modal"></i>
                </div>
                <div class="col-1">
                    <i class="fa-solid fa-trash" @click="deletePosition(position)"></i>
                </div>
            </div>
                        
        </li>
        <li class="list-group-item cursor-pointer" @click="setNewPosition()"
                    data-bs-toggle="modal" data-bs-target="#modal">
            <i class="fa-solid fa-plus" ></i>
        </li>
    </ul>
    <div class="modal fade" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-scrollable modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalLabel">{{ $t(this.currentComponent) }}</h5>
                    <button type="button" id="modalClose" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closePopup()"></button>
                </div>
                <div class="modal-body modal-textarea">
                    <component v-if="this.popupOpen" :is="currentComponent" v-bind="currentProperties" v-on:closeModal="closeModal"></component>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="closePopup()">{{ $t("close") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'

import AddPlantDashboardPosition from '@/components/dashboard/AddPlantDashboardPosition.vue'
import EditPlantDashboardPosition from '@/components/dashboard/EditPlantDashboardPosition.vue'
import axios from 'axios'
export default {
   name: 'EditPlantDashboardPositions',
   emits: [],
   components: {
        AddPlantDashboardPosition,
        EditPlantDashboardPosition
       },
   props: {
       plantDashboardHeaderId: {
           type: Number,
           required: true,
       },
       organisation: {
           type: Object,
           required: true,
       },
       
   },
   data() {
       return {
            plantDashboardHeader: {},
            plantDashboardHeaderIdOld: 0,
            activePosition: null,
            popupOpen:false,
            currentComponent: "",
       }
   },
   mounted(){
       this.getPlantDashboardHeaderFromBackend()
   },
   updated(){
    console.log('entry updated')
        if(this.plantDashboardHeaderIdOld === 0){
            this.plantDashboardHeaderIdOld = this.plantDashboardHeaderId
        }
        if(this.plantDashboardHeaderIdOld != this.plantDashboardHeaderId){
            this.getPlantDashboardHeaderFromBackend()
            this.plantDashboardHeaderIdOld = this.plantDashboardHeaderId
        }
        console.log('exit updated')
   },
   methods: {
    closePopup(){
            console.log('entry closePopup')
            this.popupOpen = false
            console.log('exit closePopup')
        },
        editPosition(position){
            console.log('entry editPosition')
            this.currentComponent = 'EditPlantDashboardPosition'
            this.activePosition = position
            this.popupOpen = true
            console.log('exit editPosition')
        },
        deletePosition(position){
            console.log('entry deletePosition')
            axios
                .delete(`/api/v1/plantdashboards_positions/${position.id}/`)
                .then(response => {

                    createToast({text: this.$t("header_position deleted")
                    ,type: 'success'})
                    this.getPlantDashboardHeaderFromBackend()
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            createToast({text: `${error.response.data[property]}`
                            ,type: 'error'})
                        }
                    }
                    console.log(JSON.stringify(error))
                    
                })
            
            console.log('exit deletePosition')
        },
        closeModal(action, position){
            console.log("closeModal")
            document.getElementById('modalClose').click();
            this.getPlantDashboardHeaderFromBackend()
        },
        sortPositionArray(){
            console.log('entry sortPositionArray')
            this.plantDashboardHeader.plant_dashboard_positions = this.plantDashboardHeader.plant_dashboard_positions.sort((a,b) => {
                if(a.position < b.position){
                    return -1
                }
                if (a.position > b.position){
                    return 1
                }
                return 0
            })
            this.updatPositionsBackend()
            console.log('exit sortPositionArray')
        },
        setNewPosition(){
            console.log('entry setNewPosition')
            this.currentComponent = "AddPlantDashboardPosition"
            this.popupOpen = true
            console.log('exit setNewPosition')
        },
        movePositionUp(position){
            console.log('entry movePositionUp')
            if(position.position < 2){
                return
            }
            let position_to_switch = this.plantDashboardHeader.plant_dashboard_positions.filter(array_position => array_position.position === position.position -1)

            position_to_switch.forEach((value,index) =>{
                value.position = position.position
            })
            position.position--
            this.sortPositionArray()
            console.log('exit movePositionUp')
        },
        movePositionDown(position){
            console.log('entry movePositionDown')
            if(position.position >= this.plantDashboardHeader.plant_dashboard_positions.length){
                return
            }
            let position_to_switch = this.plantDashboardHeader.plant_dashboard_positions.filter(array_position => array_position.position === position.position +1)

            position_to_switch.forEach((value,index) =>{
                value.position = position.position
            })
            position.position++
            this.sortPositionArray()
            console.log('exit movePositionDown')
        },
        setActivePosition(position){
            console.log('entry setActivePosition')
            this.activePosition = position
            console.log('exit setActivePosition')
        },
        updatPositionsBackend(){
            console.log('entry updatPositionsBackend')
            this.plantDashboardHeader.plant_dashboard_positions.forEach(element => {
                axios
                    .patch(`/api/v1/plantdashboards_positions/${element.id}/`, element)
                    .then(response => {
                        console.log(response)
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error))
                        createToast({text: error.message
                        ,type: 'error'})
                    })
                })
            
            console.log('exit updatPositionsBackend')
        },
        getPlantDashboardHeaderFromBackend(){
            console.log('entry getPlantDashboardHeaderFromBackend')
            axios
                .get(`/api/v1/plantdashboards_headers/${this.plantDashboardHeaderId}/`)
                .then(response => {
                    console.log(response)
                    this.plantDashboardHeader = response.data
                })
                .catch(error => {
                    console.log(error)
                })
            console.log('exit getPlantDashboardHeaderFromBackend')
        }
       
   },
   computed:{
        currentProperties(){
            return {plantDashboardHeader: this.plantDashboardHeader,
                    organisation: this.$store.state.organisation,
                    position: this.plantDashboardHeader.plant_dashboard_positions.length +1,
                    plantDashboardPosition: this.activePosition }
        },
   }
}
</script>