<template>
    <div class="nav-plant-systems-sidebar">
        <ul class="nav nav-sidebar flex-column ms-2">
            <li class="nav-item" v-if="$store.state.me.is_superuser && this.editPlantSystem">
                <a class="nav-link" href="#" @click="newSystem()"><i class="fa-solid fa-plus" ></i> {{ $t("new_system") }}</a>
            </li>
            <li class="nav-item" v-for="system in plantSystems"
                v-bind:key="system.id">
                <a class="nav-link" :class="{'active': this.currentSystem === system}" href="#" @click="setSystem(system)">
                    {{system.name}}
                    <i class="fa-solid fa-pencil" v-if="$store.state.me.is_superuser && this.editPlantSystem" @click="editSystem(system)"></i>
                </a>
            </li>
        </ul>
        
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'NavPlantSystemsSidebar',
    emits: [
        'setSystem',
        'newSystem',
        'editSystem'
    ],
    components: {
    },
    props: {
        plantSystems: {
            type: Object,
            required: true
        },
        editPlantSystem:{
            type: Boolean,
            required: false,
            default: false
        },
    },
    data() {
        return {
            currentSystem: ""
        }
    },
    mounted(){
        
    },
    updated(){
        console.log('updated')
        
    },
    unmounted(){
        console.log('component unmounted')
        
        
    },
    methods: {
        editSystem(system){
            console.log('entry editSystem')
            this.currentSystem = system
            this.$emit('editSystem', system)
            console.log('exit editSystem')
        },
        newSystem(){
            console.log('entry newSystem')
            this.$emit('newSystem')
            console.log('exit newSystem')
        },
        setSystem(system){
            console.log('entry setSystem')
            this.currentSystem = system
            this.$emit('setSystem', system)
            console.log('exit setSystem')
        },
       
    },
    computed:{
        
    }
}
</script>