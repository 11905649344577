<template>
    <div class="container-fluid">
        <table class="table table-striped table-hover table-bordered mt-3" >
            <thead>
                <tr>
                    <th class="text-center th-sticky-top">{{$t("alarm_code")}}</th>
                    <th class="text-center th-sticky-top">{{$t("alarm_txt")}}</th>
                    <th class="text-center th-sticky-top">{{$t("type_control_center")}}
                        <template v-if="$store.state.me.is_superuser">
                            <br>
                            <button type="button" @click="setAllControlCenter('2')" class="btn " :class="{'btn-danger': all_type_control_center === '2', 'btn-outline-danger': all_type_control_center !== '2'}" />
                            <button type="button" @click="setAllControlCenter('1')" class="btn " :class="{'btn-warning': all_type_control_center === '1', 'btn-outline-warning': all_type_control_center !== '1'}" />
                            <button type="button" @click="setAllControlCenter('0')" class="btn " :class="{'btn-success': all_type_control_center === '0', 'btn-outline-success': all_type_control_center !== '0'}" />
                        </template>
                    </th>
                    <th class="text-center th-sticky-top">{{$t("is_error_message")}}<template v-if="$store.state.me.is_superuser"><br><input class="form-check-input" type="checkbox" @click="invertCheckedAllErrorMessage()"  v-model="setAllErrorMessage"></template></th>
                    <th class="text-center th-sticky-top">{{$t("send_mail")}}<template v-if="$store.state.me.is_superuser"><br><input class="form-check-input" type="checkbox" @click="invertCheckedAllMail()"  v-model="setAllMails"></template></th>
                    <th class="text-center th-sticky-top">{{$t("send_sms")}}<template v-if="$store.state.me.is_superuser"><br><input class="form-check-input" type="checkbox" @click="invertCheckedAllSMS()"  v-model="setAllSMS"></template></th>
                    
                </tr>
            </thead> 
            <tbody>
                <tr v-for="alarmcode in this.allAlarmcodesForSystem"
                    v-bind:key="alarmcode.id">
                    <td>{{alarmcode.alarm_code}}</td>
                    <td>{{alarmcode.alarm_txt}}</td>
                    <template v-for="code_organisation in this.getCodeForOrganisation(alarmcode)"
                        v-bind:key="code_organisation.alarmcode">
                        <td class="text-center" @click="setCodeChanged(code_organisation)">
                            <button :disabled="!this.canWriteAlarmcodes" v-tooltip title="auf Störung setzen" type="button" @click="code_organisation.type_control_center = '2'" class="btn " :class="{'btn-danger': code_organisation.type_control_center === '2', 'btn-outline-danger': code_organisation.type_control_center !== '2'}" />
                            <button :disabled="!this.canWriteAlarmcodes" v-tooltip title="auf Warnung setzen" type="button" @click="code_organisation.type_control_center = '1'" class="btn " :class="{'btn-warning': code_organisation.type_control_center === '1', 'btn-outline-warning': code_organisation.type_control_center !== '1'}" />
                            <button :disabled="!this.canWriteAlarmcodes" v-tooltip title="auf OK setzen" type="button" @click="code_organisation.type_control_center = '0'" class="btn " :class="{'btn-success': code_organisation.type_control_center === '0', 'btn-outline-success': code_organisation.type_control_center !== '0'}" />
                        </td>
                        <td class="text-center" @click="setCodeChanged(code_organisation)"><input :disabled="!this.canWriteAlarmcodes" class="form-check-input" type="checkbox" :value="code_organisation.is_error_message" :id="'is_error_message__'+code_organisation.alarmcode" v-model="code_organisation.is_error_message"></td>
                        <td class="text-center" @click="setCodeChanged(code_organisation)"><input :disabled="!this.canWriteAlarmcodes" class="form-check-input" type="checkbox" :value="code_organisation.send_mail" :id="'send_mail__'+code_organisation.alarmcode" v-model="code_organisation.send_mail"></td>
                        <td class="text-center" @click="setCodeChanged(code_organisation)"><input :disabled="!this.canWriteAlarmcodes" class="form-check-input" type="checkbox" :value="code_organisation.send_sms" :id="'send_sms__'+code_organisation.alarmcode" v-model="code_organisation.send_sms"></td>
                    </template>
                </tr>
            </tbody>
        </table>
        <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-end">
                <li class="page-item" :class="{'disabled': !showPrevButton}" >
                    <a class="page-link"  @click="loadPrev()">&laquo;</a>
                </li>
                <li class="page-item" :class="{'disabled': !showNextButton}">
                    <a class="page-link" href="#" @click="loadNext()">&raquo;</a>
                </li>
            </ul>
        </nav>
        <button class="btn btn-success mt-2 mb-0 ms-auto" :disabled="!this.canWriteAlarmcodes" @click="submitForm">{{ $t("save") }}</button>

    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'

import { v4 as uuidv4 } from 'uuid';
import axios from 'axios'
export default {
    name: 'EditAlarmcodesForOrganisation',
    components: {
        
    },
    props: {
        plantSystem: {
            type: Object,
            required: true
        },
        canWriteAlarmcodes: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    watch: {
        plantSystem: {
            deep: true,
            handler() {
                console.log('watch fired')
                this.loadAlarmcodesForSystem()
            }
        }
    },
    data() {
        return {
            currentPage: 1,
            allAlarmcodesForOrganisation: [],
            allAlarmcodesForSystem: [],
            setAllMails: false,
            setAllSMS: false,
            setAllErrorMessage: false,
            all_type_control_center: ''
        }
    },
    async mounted(){
        await this.loadAlarmcodesForSystem()
        this.getAlarmcodesOrganisation()
    },
    updated(){
        console.log('entry updated')
        
       
        console.log('exit updated')
        
    },
    unmounted(){
        console.log('component unmounted')        
    },
    methods: {
        setAllControlCenter(code){
            console.log('entry setAllControlCenter')
            console.log(code)
            this.allAlarmcodesForSystem.forEach(alarmcode_system =>{
                console.log('alarmcode_system')
                console.log(alarmcode_system)
                var alarmcode = this.allAlarmcodesForOrganisation.filter(code => code.alarmcode === alarmcode_system.id)[0]
                console.log('alarmcode')
                console.log(alarmcode)
                alarmcode.type_control_center = code

                alarmcode.has_changed = true
            })
            console.log('exit setAllControlCenter')
        },
        invertCheckedAllMail(){
            console.log('entry invertCheckedAllMail')

            this.allAlarmcodesForSystem.forEach(alarmcode_system =>{
                console.log('alarmcode_system')
                console.log(alarmcode_system)
                var alarmcode = this.allAlarmcodesForOrganisation.filter(code => code.alarmcode === alarmcode_system.id)[0]
                if(!alarmcode.send_mail){
                    alarmcode.send_mail = true
                    
                }else{
                    alarmcode.send_mail = false
                }

                alarmcode.has_changed = true
            })
            console.log('exit invertCheckedAllMail')
        },
        invertCheckedAllSMS(){
            console.log('entry invertCheckedAllSMS')

            this.allAlarmcodesForSystem.forEach(alarmcode_system =>{
                console.log('alarmcode_system')
                console.log(alarmcode_system)
                var alarmcode = this.allAlarmcodesForOrganisation.filter(code => code.alarmcode === alarmcode_system.id)[0]
                if(!alarmcode.send_sms){
                    alarmcode.send_sms = true
                    
                }else{
                    alarmcode.send_sms = false
                }

                alarmcode.has_changed = true
            })
            console.log('exit invertCheckedAllSMS')
        },
        invertCheckedAllErrorMessage(){
            console.log('entry invertCheckedAllErrorMessage')

            this.allAlarmcodesForSystem.forEach(alarmcode_system =>{
                console.log('alarmcode_system')
                console.log(alarmcode_system)
                var alarmcode = this.allAlarmcodesForOrganisation.filter(code => code.alarmcode === alarmcode_system.id)[0]
                if(!alarmcode.is_error_message){
                    alarmcode.is_error_message = true
                    
                }else{
                    alarmcode.is_error_message = false
                }

                alarmcode.has_changed = true
            })
            
            console.log('exit invertCheckedAllErrorMessage')
        },
        setCodeChanged(code_organisation){
            console.log('entry setCodeChanged')

            code_organisation.has_changed = true
            console.log('exit setCodeChanged')
        },
        loadAlarmcodesForSystem(){
            console.log('entry loadAlarmcodesForSystem')
            axios
                .get(`/api/v1/alarmcodes_list/?page=${this.currentPage}&system_id=${this.plantSystem.id}`)
                .then(response => {
                    console.log('activity loaded')
                    console.log(response.data)
                    this.showNextButton = false
                    this.showPrevButton = false
                    console.log(response.data.next)
                                
                    if (response.data.next != null) {
                        this.showNextButton = true
                    }
                    console.log(response.data.previous)
                    if (response.data.previous) {
                        this.showPrevButton = true
                        console.log('prevbutton')
                    }

                    this.allAlarmcodesForSystem = response.data.results
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        loadNext() {
            this.currentPage += 1
            this.loadAlarmcodesForSystem()
            this.submitForm()
        },
        loadPrev() {
            this.currentPage -= 1
            this.loadAlarmcodesForSystem()
            this.submitForm()
        },
        getCodeForOrganisation(alarm_code){
            console.log('entry getCodeForOrganisation')
            console.log(alarm_code)
            let code_organisation = this.allAlarmcodesForOrganisation.filter(code => code.alarmcode === alarm_code.id)[0]

            if(!code_organisation){
                console.log('new code organisation')
                code_organisation = {alarmcode: alarm_code.id
                                    ,organisation: this.$store.getters.getOrganisation.id
                                    ,created_by: this.$store.state.user.id
                                    ,modified_by: this.$store.state.user.id
                                    ,is_error_message: false
                                    ,send_mail: false
                                    ,send_sms: false
                                    ,is_new: true
                                    ,has_changed: true
                                    ,type_control_center: 0
                                    }
                
                this.allAlarmcodesForOrganisation.push(code_organisation)
                
            }
            console.log('exit getCodeForOrganisation')
            return [code_organisation]
        },
        getAlarmcodesOrganisation(){
            console.log('entry getAlarmcodesOrganisation')
            axios
                .get('/api/v1/alarmcodesorganisations/')
                .then(response => {
                    console.log('alarmcodesOrganisation loaded')
                    console.log(response.data)
                    this.allAlarmcodesForOrganisation = response.data
                    
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
            console.log('exit getAlarmcodesOrganisation')
        },
        submitForm() {
            this.allAlarmcodesForOrganisation.filter(code => code.has_changed).forEach(element => {
                console.log('working on element:')
                console.log(element)
                if(element.is_new){
                    console.log('is new to backend')
                    axios
                        .post(`/api/v1/alarmcodesorganisations/`, element)
                        .then(response => {
                        })
                        .catch(error => {
                            console.log(JSON.stringify(error))
                        })
                }else{
                    console.log('known element')
                    axios
                        .patch(`/api/v1/alarmcodesorganisations/${element.id}/`, element)
                        .then(response => {
                            createToast({text: this.$t("alarmcodesOrganisation altered")
                                ,type: 'success'})
                        })
                        .catch(error => {
                            console.log(JSON.stringify(error))
                        })
                }
                element.has_changed = false
            })
        }
    },
    computed:{
        
        allAlarmcodesCombinedWithOrganisation(){
            console.log('entry allAlarmcodesCombinedWithOrganisation')
            let final_array = []
            this.plantSystem.plantsystems_alarmcodes.forEach((value,index) =>{
                console.log('value.id')
                console.log(value.id)
                let code_organisation = this.allAlarmcodesForOrganisation.filter(code => code.alarmcode === value.id)[0]
                console.log('code_organisation')
                console.log(code_organisation)
                let array_pos = {...value, ...code_organisation}
                console.log('array_pos')
                console.log(array_pos)
                final_array.push(array_pos)
            })
            console.log('exit allAlarmcodesCombinedWithOrganisation')
            console.log(final_array)
            return final_array
        }
    }
}
</script>