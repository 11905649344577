<template>
    <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
      <div class="position-sticky pt-3">
        <ul class="nav nav-sidebar flex-column">
          <li class="nav-item">
            <router-link to="/dashboard" class="nav-link">
              <i class="fa-solid fa-house"></i>
              Dashboard
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/live-data" class="nav-link">
              <i class="fa-solid fa-database"></i>
              Aktuelle Daten
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/analyse" class="nav-link">
              <i class="fa-solid fa-chart-area"></i>
              Auswertung
            </router-link>
          </li>
          
          <li class="nav-item">
            <a class="nav-link nav-toggle collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#settings-collapse" aria-expanded="true">
              <i class="fa-solid fa-gears"></i>
              {{ $t("settings") }}
            </a>
            <div class="collapse show" id="settings-collapse">
              <ul class="nav flex-column">
                <li class="nav-item"><router-link to="/settings/masterdata" class="nav-link">{{ $t("master_data") }}</router-link></li>
                <li class="nav-item"><router-link to="/settings/live_datas" class="nav-link">{{ $t("config_live_data") }}</router-link></li>
                <li class="nav-item"><router-link to="/settings/plants" class="nav-link"><i class="fa-solid fa-industry"></i>{{ $t("plants") }}</router-link></li>
                <li class="nav-item"><router-link to="/settings/usergroups" class="nav-link"><i class="fa-solid fa-industry"></i>{{ $t("user_groups") }}</router-link></li>
                <li class="nav-item" v-if="$store.state.me.is_superuser"><router-link to="/settings/masterdata/organisations" class="nav-link">{{ $t("organisations") }}</router-link></li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </nav>

</template>
<script>
export default {
  name: 'Sidebar',
  
}
</script>
