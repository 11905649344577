<template>
    <div class="page-organisations">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/dashboard">Dashboard</router-link></li>
                <li class="breadcrumb-item active"><router-link to="/settings/masterdata/organisations" aria-current="true">{{ $t("organisations") }}</router-link></li>
            </ol>
        </nav>

        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">{{ $t("organisations") }}</h1>

                <router-link :to="{ name: 'AddOrganisation' }" class="btn btn-primary t mt-4">{{ $t("addOrganisation") }}</router-link>
                
            </div>
        </div>
        <div class="column is-12 mt-3">
            <table class="table table-striped table-hover clickable table-sm">
                <thead>
                    <tr>
                        <th>{{ $t("organisation") }}</th>
                        <th>{{ $t("status_license") }}</th>
                        <th>{{ $t("license_valid_until") }}</th>
                        <th>{{ $t("numUser") }}</th>
                        <th>{{ $t("numPlants") }}</th>
                        <th></th>
                       <!-- <th> <button type="button" @click="downloadClients()" class="btn btn-sm btn-outline-secondary"><i class="bi bi-file-earmark-excel"></i> Export</button></th>-->
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="organisation in organisations"
                        v-bind:key="organisation.id"
                        @click="navigateOrganisation(organisation.id)"
                    >
                        <td>{{ organisation.name }}</td>
                        <td>{{ organisation.status_license }}</td>
                        <td>{{ organisation.license_valid_until }}</td>
                        <td>{{ this.getNumUser(organisation) }}</td>
                        <td>{{ this.getNumPlants(organisation) }}</td>
                        <td>
                           <router-link :to="{ name: 'Organisation', params: { id: organisation.id }}">{{ $t("details") }}</router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>    
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'Organisations',
    data() {
        return {
            organisations: []
        }
    },
    mounted(){
        this.getOrganisations()
    },
    methods: {
      navigateOrganisation(organisationID) {

       this.$router.push({name:'Organisation', params: {id: organisationID}});
      },
      getOrganisations() {
            axios
                .get('/api/v1/allOrganisations/')
                .then(response => {
                    for (let i = 0; i < response.data.length; i++) {
                        this.organisations.push(response.data[i])
                    }
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
      },
         getNumUser(organisation){

            return organisation.users.length
        },
        getNumPlants(organisation){
            return organisation.plants.length
        }
    },
    computed:{
       
    }
}
</script>