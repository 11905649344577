<template>
    <div class="row">

        <table class="table table-striped table-hover table-bordered">
             <thead>
                 <tr>
                     <th class="w-50">{{ $t("organisation") }}</th>
                     <th class="w-25">{{ $t("Status") }}</th>
                     <th class="w-25">{{ $t("until") }}</th>
                 </tr>
             </thead>
             <tbody>
                 <tr v-for="orga in allOrganisations"
                     v-bind:key="orga.name">
                     <td> {{ orga.name }}</td>
                     <td></td>
                     <td></td>
                 </tr>
             </tbody>
         </table>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'OrganisationLicenses',
    components: {
    },
    data() {
        return {
            allOrganisations: [],
        }
    },
    mounted(){
        this.getAllOrganisations()
    },
    updated(){
        console.log('updated')
        
    },
    unmounted(){
        console.log('component unmounted')
        
    },
    methods: {
        getAllOrganisations(){
            console.log('entry getAllOrganisations')
            axios
                .get('/api/v1/allOrganisations/')
                .then(response => {
                    for (let i = 0; i < response.data.length; i++) {
                        this.allOrganisations.push(response.data[i])
                    }
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
            console.log('exit getAllOrganisations')
        }
    },
    computed:{
        
        
    }
}
</script>