<template>
    <ul class="nav nav-sidebar flex-column ms-2">
        <li class="nav-item" v-for="authgroup in authGroups"
            v-bind:key="authgroup.id">
            <a class="nav-link" :class="{'active': this.currentAuthGroup === authgroup}" href="#" @click="setAuthGroup(authgroup)">
                {{authgroup.grp_name}}
            </a>
        </li>
    </ul>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'NavAuthGroupSidebar',
    emits: [
        'setAuthGroup',
    ],
    components: {
    },
    props: {
        authGroups: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            currentAuthGroup: ""
        }
    },
    mounted(){
        
    },
    updated(){
        console.log('updated')
        
    },
    unmounted(){
        console.log('component unmounted')
        
        
    },
    methods: {
        setAuthGroup(authgroup){
            console.log('entry setAuthGroup')
            this.currentAuthGroup = authgroup
            this.$emit('setAuthGroup', authgroup)
            console.log('exit setAuthGroup')
        },
       
    },
    computed:{
        
    }
}
</script>