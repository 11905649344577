<template>
    <div class="card mt-4">
        <div class="card-header">
            <h5>{{ $t("Passwortänderung") }}</h5>
        </div>
        <div class="card-body pt-0">
            <div class="mb-3 mt-3">
                <div class="form-floating">
                    <input id="inputCurrentPassword" type="password" name="currentPassword" class="form-control" v-model="currentPassword" placeholder="Zufall" required>
                    <label for="inputCurrentPassword" class="form-label" >{{ $t("aktuelles Passwort") }}</label>                
                </div>
            </div>
                            
            <div class="input-group">
                <div class="form-floating form-floating-group flex-grow-1">
                    <input ref="password" id="inputNewPassword" :type="passwordFieldType" name="newPassword" class="form-control" v-model="newPassword" aria-describedby="hideUnhide" placeholder="test">
                    <label for="inputNewPassword" class="form-label">{{ $t("neues Passwort") }}</label>
                </div>
                <span class="input-group-text" id="hideUnhide" @click="switchVisibility">
                    <i class="fa-solid fa-eye" v-if='this.passwordFieldType === "password"'></i>
                    <i class="fa-solid fa-eye-slash" v-if='this.passwordFieldType === "text"'></i>
                </span>
            </div>
            <div class="text-danger" v-if="errors.length">
                <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
            </div>
            <div class="row">
                <div class="col-9"></div>
                <div class="col-3">
                    <button class="btn btn-danger float-end mt-3" @click="changePassword">{{ $t("Ändere Passwort") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'ChangePassword',
    data(){
        return {
            currentPassword: '',
            newPassword: '',
            passwordFieldType: "password",
            errors: [],
        }
    },
    methods:{
        changePassword(){
            const formData = {
                 current_password: this.currentPassword,
                 new_password: this.newPassword
            }
            axios
                .post(`/api/v1/users/set_password/`, formData)
                .then(response => {
                   createToast({text: this.$t("Passwort wurde geändert")
                              ,type: 'success'})
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${error.response.data[property]}`)
                        }

                        console.log(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.log(JSON.stringify(error.message))
                    } else {
                        console.log(JSON.stringify(error))
                    }
                })
        },
        switchVisibility(){
            this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
        },
    }
}
</script>
