import { createStore } from 'vuex'

export default createStore({
  state: {
    user: {
      id: '',
      username: '',
      permissions: [],
    },
    userPermissions: {},
    isAuthenticated: false,
    token: '',
    registrationGroup: {},
    me:{},
    organisation: {},
    componentUnits: {},
    plants: [],
    plantSystems: [],
    plantSystemJournals: [],
    userGroups: []
  },
  getters: {
    getPlantSystems(state){
      return state.plantSystems
    },
    getPlantSystemJournals(state){
      return state.plantSystemJournals
    },
    getUserGroups(state){
      return state.userGroups
    },
    getPlants(state){
      return state.plants
    },
    getOrganisation(state){
      return state.organisation
    },
    userHasPermission:(state) => (dialog, permission) =>{
      console.log('entry userHasPermission')
      console.log(state.user.permissions)
      console.log('dialog: ' + dialog)
      console.log('permission: ' + permission)
      console.log(typeof(state.user.permissions))
      if(state.me.is_superuser){
        console.log('user is superuser')
        return true
      }
      if(!state.user.permissions || state.user.permissions.length < 1){
        console.log('no user found')
        return false
      }
      
      if(state.user.permissions.permissions.length < 1){
        return false
      }
      try {
        var dialog_permission = state.user.permissions.permissions.filter(object_permission => object_permission.dialog === dialog)
        console.log(dialog_permission[0])
        console.log(dialog_permission[0][permission])
        console.log('exit userHasPermission')
        return dialog_permission[0][permission]
      } catch (error) {
        return false
      }
      
    }
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem('token')) {
        state.token = localStorage.getItem('token')
        state.isAuthenticated = true
        state.user.username = localStorage.getItem('username')
        state.user.id = localStorage.getItem('userid')
        state.user.permissions = []
      } else {
        state.user.id = ''
        state.user.username = ''
        state.user.permissions = []
        state.token = ''
        state.isAuthenticated = false
        state.registrationGroup = {}
        state.plants = []
        state.plantSystems = []
        state.userGroups = []
      }
    },
    setToken(state, token) {
      state.token = token
    },
    setIsAuthenticated(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated
    },
    removeToken(state) {
      state.user.id = ''
      state.user.username = ''
      state.user.permissions = []
      state.token = ''
      state.isAuthenticated = false
      state.me = {}
      state.permissions = {}
      state.organisation = {}
      state.plants = []
      state.plantSystems = []
      state.userGroups = []
    },
    setUser(state, user) {
      state.user = user
    },
    setPermissions(state, permissions){
      state.user.permissions = permissions
    },
    setRegistrationGroup(state, registrationGroup){
      state.registrationGroup = registrationGroup
    },
    setMe(state, me){
      state.me = me
    },
    setOrganisation(state, organisation){
      state.organisation = organisation
    },
    setComponentUnits(state, componentUnits){
      state.componentUnits = componentUnits
    },
    setPlants(state, plants){
      state.plants = plants
    },
    updatePlant(state, plant){
      console.log('entry updatePlant')
      console.log(plant)
      let pos_plant = state.plants.map(e => e.id).indexOf(plant.id)
      console.log(pos_plant)
      state.plants[pos_plant] = plant
      console.log('exit updatePlant')
    },
    setPlantSystems(state, plantSystems){
      state.plantSystems = plantSystems
    },
    setPlantSystemJournals(state, plantSystemJournals){
      state.plantSystemJournals = plantSystemJournals
    },
    setUserGroups(state, userGroups){
      state.userGroups = userGroups
    },
    addPlantSystem(state, system){
      state.plantSystems.push(system)
    },
    addUserGroup(state, userGroup){
      state.userGroups.push(userGroup)
    },
    deleteUserGroup(state, userGroup){
      let pos_delete = state.userGroups.map(e => e.id).indexOf(userGroup.id)
      state.userGroups.splice(pos_delete, 1)
    },
    addPlant(state, plant){
      state.plants.push(plant)
    },
  },
  actions: {
  },
  modules: {
  }
})
