<template>
    <div class="page-add-user">

        <div class="container-fluid my-3 py-3">
            <div class="row my-3">
                <div class="col-12">
                    <select class="form-select " aria-label="Default select example" v-model="copyComponent">
                        <option  v-for="component in this.plant.plant_components" 
                        v-bind:key="component.name" v-bind:value="component.id">{{component.name}}</option>
                    </select>
                </div>
                
            </div>

            <button class="btn btn-success mb-0 ms-auto" @click="submitForm">{{ $t("save") }}</button>
        </div>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'CopyDescription',
    emits: ['closeModal'],
    props: {
        plant: {
            type: Object,
            required: true,
        },
        component: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            copyComponent: {}
        }
    },

    methods: {
        submitForm() {
            let formData= {'copy_component': this.copyComponent,
                          'target_component': this.component.id}

            axios
                .post(`/api/v1/plants/${this.plant.id}/copy_component_description/`, formData)
                .then(response => {
                    
                    createToast({text: this.$t("component copied")
                   ,type: 'success'})

                   this.$emit('closeModal', 'copy')
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
        }
    }
}
</script>