<template>
    <div class="loading-block" v-if="!this.dataLoaded"></div>
    <div class="page-config-live-data">
        <div class="col-md-3 col-lg-2 d-md-block bg-light sidebar">
            <NavPlantsSidebarMultiselect v-if="plantsLoaded" v-bind="currentPlantsProperties" 
                v-on:setPlant="setEvaluatePlant" v-on:updateChoosenData="updateChoosenData"></NavPlantsSidebarMultiselect>
        </div>
        <div class="col-md-9 ms-sm-auto col-lg-10 my-2">
            <div class="row">
                <div class="col-4">
                    <ChooseEvaluation v-if="this.allCheckedPlants.length > 0" v-bind="currentProperties"
                    v-on:updateChoosenEvaluation="updateEvaluation"></ChooseEvaluation>
                </div>
                <div class="col-4">
                    <ChooseDateRangeEvaluation v-if="this.currentEvaluation" v-bind="chooseDateProperties"
                        v-on:confirmDate="confirmDate"></ChooseDateRangeEvaluation>
                </div>
            </div>
            <div class="row my-3">
                <div class="row my-3">
                    <VisualizeEvaluationData  v-if="this.dataForVisualize"
                        v-bind="visualizeProperties" ></VisualizeEvaluationData>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VisualizeEvaluationData from '@/components/evaluation/VisualizeEvaluationData.vue'
import ChooseDateRangeEvaluation from '@/components/evaluation/ChooseDateRangeEvaluation.vue'
import ChooseEvaluation from '@/components/evaluation/ChooseEvaluation.vue'
import NavPlantsSidebarMultiselect from '@/components/navs/NavPlantsSidebarMultiselect.vue'
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'

const fileDownload = require('js-file-download')
export default {
    name: 'Evaluation',
    components: {
        ChooseDateRangeEvaluation,
        NavPlantsSidebarMultiselect,
        ChooseEvaluation,
        VisualizeEvaluationData
    },
    watch: {
        '$route.params.type': function(newType, oldType) {
      
        this.reset();
        }
  },
    data() {
        return {
            dataLoaded: true,
            currentPlant: '',
            currentEvaluation: '',
            dataForVisualize: '',
            allCheckedPlants: [],
        }
    },
    methods: {
        reset(){
            this.currentPlant = ''
            this.currentEvaluation = ''
            this.dataForVisualize = ''
            this.allCheckedPlants = []
        },

        updateChoosenData(plant){
            console.log('entry updateChoosenData')
            let pos_description = this.allCheckedPlants.map(e => e.id).indexOf(plant.id)
            if (pos_description == -1){
                this.allCheckedPlants.push(plant)
            }else{
                this.removeItemCheckedArray(pos_description)
            }
            this.dataForVisualize = ''
            console.log('exit updateChoosenData')
        },
        removeItemCheckedArray(pos_description){
            console.log('entry removeItemCheckedArray')
            console.log(pos_description)
            this.allCheckedPlants.splice(pos_description, 1)
            console.log('remove removeItemCheckedArray')
        },
        updateEvaluation(evaluation){
            console.log('entry updateEvaluation')
            this.currentEvaluation = evaluation
            this.dataForVisualize = ''
            console.log('exit updateEvaluation')
        },
        setEvaluatePlant(plant){
            console.log('entry setEvaluatePlant')
            this.currentPlant = plant
            console.log('exit setEvaluatePlant')
        },   
        generateExcel(formData){
          console.log('entry generateExcel')
          
          formData.type_data = 'xlsx'
          this.dataLoaded = false
          axios
                .post(`/api/v1/plant_type_evaluations/${this.currentEvaluation.id}/get_data_for_plant_type_evaluation/`, formData, {responseType: 'blob'})
                .then(response => {
                    console.log('fertig')
                    console.log(response.data)
                    this.dataLoaded= true
                    fileDownload(response.data, `_auswertung.xlsx`);
                })
                .catch(error => {
                    this.dataLoaded = true
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
          console.log('exit generateExcel')
        },
        confirmDate(dateObject, type){
            console.log('entry confirmDate')
            console.log(dateObject)
            var formData = dateObject
            formData.type_data = type
            let selectionData = []
            this.allCheckedPlants.forEach((plant,index) =>{
                selectionData.push(plant.id)
            })
            formData.selectionData = selectionData
            this.safedFormData = formData
            if (type === 'xlsx'){
                this.generateExcel(formData)
                return
            }
            this.dataLoaded = false
            axios
                .post(`/api/v1/plant_type_evaluations/${this.currentEvaluation.id}/get_data_for_plant_type_evaluation/`, formData)
                .then(response => {
                    console.log('fertig')
                    console.log(response.data)
                    this.dataLoaded = true
                    this.dataForVisualize = response.data
                    
                })
                .catch(error => {
                    this.dataLoaded = true
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
           
        },
    },
    computed:{
        visualizeProperties(){
            return {dataForVisualize: this.dataForVisualize,
                    evaluation: this.currentEvaluation
                }
        },
        plantsLoaded(){
            if(this.$store.getters.getPlants){
                return true
            }
            return false
        },
        organisation(){
            return this.$store.getters.getOrganisation
        },
        
        currentProperties(){
            return {type: this.$route.params.type}
        },
        chooseDateProperties(){
            return {evaluation: this.currentEvaluation}
        },
        currentPlantsProperties(){
                return {plants: this.$store.getters.getPlants.filter(plant => {
                    if (plant.type){
                        return plant.type.toLowerCase() === this.$route.params.type.toLowerCase()
                    }else{
                        return false
                    }}),
                    checkedPlants: this.allCheckedPlants,}
            },
    }
}
</script>
