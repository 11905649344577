<template>
    <div class="row">
        <div class="col-6 col-xl-4 my-2" v-if="getAllPlantsForSystemFormatted.length > 0">
            <div class="card shadow-sm">
                <div class="card-header d-flex align-items-center">
                    <p class="text-center h5">{{ $t("selectionData") }}</p>
                    
                </div>
                <div class="card-body">
                    <div class="row">
                        <template
                            v-for="plantsForSystem in getAllPlantsForSystemFormatted"
                            v-bind:key="plantsForSystem.id"
                        >
                            <div :class="{'col-6': getAllPlantsForSystemFormatted.length > 1, 'col': getAllPlantsForSystemFormatted.length === 1}">
                                <template
                                v-for="plant in plantsForSystem"
                                v-bind:key="plant.id"
                                >
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" :value="plant.id" :id="plant.id" v-model="checkedPlants">
                                        <label class="form-check-label" :for="plant.id" >
                                            {{plant.name}}
                                        </label>
                                    </div>

                                </template>
                            </div>
                        
                        </template>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="col-lg-4 my-2">
            <div class="card shadow-sm">
                <div class="card-header text-center">
                    <p class="h5">{{ $t("selectionIntervall") }}</p>
                    
                </div>
                <div class="card-body ">
                    <div class="mt-2 text-center">
                        <div class="form-check form-check-inline" v-if="plantTypeEvaluation.type_evaluation === 'DA'">
                            <input class="form-check-input" @click="switchIntervall" type="radio" name="flexRadioDefault" id="selectionDay" v-model="selectionIntervall" v-bind:value="'day'" checked >
                            <label class="form-check-label" for="selectionDay">
                                {{ $t("day") }}
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" @click="switchIntervall" type="radio" name="flexRadioDefault" id="selectionMonth" v-model="selectionIntervall" v-bind:value="'month'" >
                            <label class="form-check-label" for="selectionMonth">
                                {{ $t("month") }}
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" @click="switchIntervall" type="radio" name="flexRadioDefault" id="selectionYear" v-model="selectionIntervall" v-bind:value="'year'">
                            <label class="form-check-label" for="selectionYear">
                                {{ $t("year") }}
                            </label>
                        </div>
                    </div>
                    <hr>
                    <div class="mt-2 text-center">
                        <Datepicker v-model="date" range closeOnScroll autoApply 
                                                   :format="getDatepickerFormat" locale="de"
                                                   cancelText="abbrechen" selectText="auswählen" 
                                                   :enableTimePicker="false"
                                                   v-if="selectionIntervall === 'day' && plantTypeEvaluation.type_evaluation === 'DA'"/>
                        <Datepicker v-model="date" monthPicker
                                                   range closeOnScroll autoApply 
                                                   :format="getDatepickerFormat" locale="de"
                                                   cancelText="abbrechen" selectText="auswählen" 
                                                   :enableTimePicker="false" 
                                                   v-if="selectionIntervall === 'month'"/>
                        <Datepicker v-model="date" yearPicker
                                                   closeOnScroll autoApply 
                                                   :format="getDatepickerFormat" locale="de"
                                                   cancelText="abbrechen" selectText="auswählen" 
                                                   :enableTimePicker="false" 
                                                   v-if="selectionIntervall === 'year'"/>
                    </div>
                    <div class="text-end mt-3">
                        <button class="btn btn-success mx-1 ms-auto" @click="getEvaluationForPlantsPdf" ><i class="fa-solid fa-file-pdf"></i> {{ $t("pdf") }}</button>
                        <button class="btn btn-success mb-0 ms-auto" @click="getEvaluationForPlants" >{{ $t("confirm") }}</button>
                    </div>
                </div>
            </div>
          
        </div>        
    </div>
    <div class="row justify-content-evenly mt-3" v-if="this.evaluationLoaded">
        <div class="row my-3" v-for="(page, index) in Array(this.evaluationData.num_pages)"
            v-bind:key="index">
            <div class="col-12">
                <div class="card shadow-sm">
                    <div class="card-body scroll-y">
                    
                        <table id="test" ref="testHtml" class="table table-striped table-hover table-bordered table-fixed-first-column">
                            <thead>
                                <tr>
                                    <template v-for="header in this.evaluationData.page_headers[index].headers"
                                        v-bind:key="header">
                                        <template v-if="header.name != 'spacing'">
                                            <th :rowspan="header.rowspan" :colspan="header.colspan">{{header.name}}</th>
                                        </template>
                                    </template>
                            
                                </tr>
                                <tr>
                                    <template v-for="subheader in this.evaluationData.page_headers[index].subheaders"
                                        v-bind:key="subheader">
                                        <template v-if="subheader.name != 'spacing' && subheader.name != 'hide'">
                                            <th :rowspan="subheader.rowspan" :colspan="subheader.colspan">{{subheader.name}}</th>
                                        </template>
                                    </template>
                                </tr>
                                <tr>

                                    <template v-for="header_pos in this.evaluationData.page_headers[index].positions"
                                    v-bind:key="header_pos">
                                        <template v-if="header_pos.name != 'spacing'">
                                            <th>{{header_pos.name}}</th>
                                        </template>
                                    </template>
                                </tr>
                            </thead>
                            
                            <tbody>
                                <template
                                    v-for="position in this.evaluationData.page_positions[index]"
                                    v-bind:key="position.id"
                                >
                                    <tr>
                                        <td>
                                            {{getDateFormatted(position.created_at_date)}}
                                        </td>
                                        <template
                                            v-for="value in position.data"
                                            v-bind:key="value.id"
                                        >   
                                            <template v-if="value.value != 'spacing'">

                                                <td class="text-end">
                                                    {{value.value}} <template v-if="value.unit"> <span class="table-unit">{{ value.unit }}</span></template>
                                                </td>
                                            </template>
                                        </template>
                                    </tr>
                                </template>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>{{this.evaluationData.page_footer[index].name}}</th>
                                    <template
                                        v-for="value in this.evaluationData.page_footer[index].data"
                                        v-bind:key="value.id"
                                    >
                                        <template v-if="value.value != 'spacing'">
                                            <th class="text-end">
                                                {{value.value}} <template v-if="value.unit"><span class="table-unit"> {{ value.unit }}</span></template>
                                            </th>
                                        </template>
                                    </template>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
const fileDownload = require('js-file-download')
export default {
    name: 'PlantEvaluations',
    props: {
        plantTypeEvaluation: {
            type: Object,
            required: true
        },
        plantType: {
            type: Object,
            required: true
        },
    },
    watch: {
        plantTypeEvaluation: {
            deep: true,
            handler() {
                console.log('watch fired')
                this.reset()
            }
        }
    },
    data() {
        return {
            
            checkedPlants: [],
            date: null,
            evaluationData: {},
            selectionIntervall: "day",
        }
    },
    async mounted(){
        this.initSelectionIntervall()
    },
    updated(){
        console.log('entry updated')

        console.log('exit updated')
    },
    methods: {
        initSelectionIntervall(){
            if(this.plantTypeEvaluation.type_evaluation === 'MO'){
                this.selectionIntervall = "month"
            }else{
                this.selectionIntervall = "day"
            }
        },
        getDateFormatted(date_string){
            if(this.plantTypeEvaluation.type_evaluation === 'DA'){
                let date = new Date(date_string)
                return date.toLocaleDateString()
            }else{
                let date = new Date(date_string)
                return date.toLocaleDateString('de-DE', {month: '2-digit', year: 'numeric'})
            }
            
        },
        reset(){
            console.log('entry reset')
            this.initSelectionIntervall()
            this.date = null
            this.checkedPlants = []
            console.log('exit reset')
        },
        switchIntervall(){
            console.log('entry switchIntervall')
            this.date = null
            console.log('exit switchIntervall')
        },
        getTimeFormatted(time){
            let updatedTime = new Date(time)
            console.log('nach updatedTime')
            console.log(updatedTime.toLocaleString())
            return updatedTime.toLocaleString()
        },
        getIntervall(){
            console.log('entry getIntervall')
            let startIntervall = {}
            let endIntervall = {}

            if(this.selectionIntervall === 'day') {
                console.log("day") 
                startIntervall['day'] = this.date[0].getDate()
                startIntervall['month'] = this.date[0].getMonth()
                startIntervall['year'] = this.date[0].getFullYear()
                endIntervall['day'] = this.date[1].getDate()+1//suche erfolgt immer exklusive des letzten Tags
                endIntervall['month'] = this.date[1].getMonth()
                endIntervall['year'] = this.date[1].getFullYear()
            }
            if(this.selectionIntervall === 'month') {
                console.log("month") 
                startIntervall['day'] = '1'
                startIntervall['month'] = this.date[0]['month']
                startIntervall['year'] = this.date[0]['year']
                if(this.date[1] != null){
                    
                    var lastDayOfMonth = new Date(this.date[1]['year'], this.date[1]['month'] +1, 0)
                    endIntervall['day'] = lastDayOfMonth.getDate()
                    endIntervall['month'] = this.date[1]['month']
                    endIntervall['year'] = this.date[1]['year']
                }else{
                    var lastDayOfMonth = new Date(this.date[0]['year'], this.date[0]['month'] +1, 0)
                    endIntervall['day'] = lastDayOfMonth.getDate()
                    endIntervall['month'] = this.date[0]['month']
                    endIntervall['year'] = this.date[0]['year']
                }
                
            }
            if(this.selectionIntervall === 'year') {
                console.log("year") 
                startIntervall['day'] = '1'
                startIntervall['month'] = '0'
                startIntervall['year'] = this.date
                endIntervall['day'] = '31'
                endIntervall['month'] = '11'
                endIntervall['year'] = this.date
            }
            const formData = {
                selectionData: this.checkedPlants,
                startIntervall: startIntervall,
                endIntervall: endIntervall,
                type_intervall: this.selectionIntervall,
            }
            console.log('exit getIntervall')
            return formData
        },
        getEvaluationForPlants(){
            console.log('entry getEvaluationForPlants')
            var formData = this.getIntervall()

            formData.plants = this.checkedPlants
            formData.type_data = 'plain'
            axios
                .post(`/api/v1/planttype_evaluations/${this.plantTypeEvaluation.id}/get_evaluation_for_plants/`, formData)
                .then(response => {
                    console.log('fertig')
                    console.log(response.data)
                    this.evaluationData = response.data
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
            console.log('exit getEvaluationForPlants')
        },
        getEvaluationForPlantsPdf(){
            console.log('entry getEvaluationForPlants')
            var formData = this.getIntervall()

            formData.plants = this.checkedPlants
            formData.type_data = 'pdf'
            axios
                .post(`/api/v1/planttype_evaluations/${this.plantTypeEvaluation.id}/get_evaluation_for_plants/`, formData, {responseType: 'blob'})
                .then(response => {
                    console.log('fertig')
                    fileDownload(response.data, this.plantTypeEvaluation.name + `-Auswertung.pdf`)
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
            console.log('exit getEvaluationForPlants')
        }
    },
    computed:{
        evaluationLoaded(){
            if(this.evaluationData.hasOwnProperty('page_headers')){
                return true
            }
            return false
        },
        getAllPlantsForSystemFormatted(){
            console.log('entry getAllPlantsForSystemFormatted')
            console.log('plantType:')
            console.log(this.plantType)
            if(this.plantType === ""){
                return [];
            }
            console.log(this.$store.getters.getPlants)
            let filtered_plants = this.$store.getters.getPlants.filter(plant => this.plantType.name === plant.type)
            console.log('filtered_plants:')
            console.log(filtered_plants)
            let array_length = filtered_plants.length
            let formatted_array = []

            if(array_length < 2){
                formatted_array[0] = filtered_plants
            }else{
                let plants_array = filtered_plants.slice(0, Math.round(array_length/2))
                let plants_array2 = filtered_plants.slice(Math.round(array_length/2), array_length)
                formatted_array[0] = plants_array
                formatted_array[1] = plants_array2
                console.log('plants_array')
                console.log(plants_array)
                console.log('plants_array2')
                console.log(plants_array2)
            }
            console.log('formatted_array')
            console.log(formatted_array)
            console.log('exit getAllPlantsForSystemFormatted')
            return formatted_array
        },
        getDatepickerFormat(){
            console.log("getDatepickerFormat entry")
            console.log('date:')
            console.log(this.date)
            if(this.selectionIntervall === 'day') {
                console.log("day") 
                return "dd/MM/yyyy"
            }
            if(this.selectionIntervall === 'month') {
                console.log("month") 
                return "MM/yyyy"
            }
            if(this.selectionIntervall === 'year') {
                console.log("year") 
                return "yyyy"
            }
        },
    }
}
</script>