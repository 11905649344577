<template>
    <div class="row">
        <div class="col-lg-4 my-2">
            <div class="card shadow-sm">
                <div class="card-header text-center">
                    <p class="h5">{{ $t("selectionIntervall") }}</p>
                    
                </div>
                <div class="card-body ">
                    <div class="mt-2 text-center">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" @click="switchIntervall" type="radio" name="flexRadioDefault" id="selectionDay" v-model="selectionIntervall" v-bind:value="'day'" checked >
                            <label class="form-check-label" for="selectionDay">
                                {{ $t("day") }}
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" @click="switchIntervall" type="radio" name="flexRadioDefault" id="selectionMonth" v-model="selectionIntervall" v-bind:value="'month'" >
                            <label class="form-check-label" for="selectionMonth">
                                {{ $t("month") }}
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" @click="switchIntervall" type="radio" name="flexRadioDefault" id="selectionYear" v-model="selectionIntervall" v-bind:value="'year'" >
                            <label class="form-check-label" for="selectionYear">
                                {{ $t("year") }}
                            </label>
                        </div>
                    </div>
                    <hr>
                    <div class="mt-2 text-center">
                        <Datepicker v-model="date" range closeOnScroll autoApply 
                                                   :format="getDatepickerFormat" locale="de"
                                                   cancelText="abbrechen" selectText="auswählen" 
                                                   :enableTimePicker="false"
                                                   v-if="selectionIntervall === 'day'"/>
                        <Datepicker v-model="date" monthPicker
                                                   range closeOnScroll autoApply 
                                                   :format="getDatepickerFormat" locale="de"
                                                   cancelText="abbrechen" selectText="auswählen" 
                                                   :enableTimePicker="false" 
                                                   v-if="selectionIntervall === 'month'"/>
                        <Datepicker v-model="date" yearPicker
                                                   closeOnScroll autoApply 
                                                   :format="getDatepickerFormat" locale="de"
                                                   cancelText="abbrechen" selectText="auswählen" 
                                                   :enableTimePicker="false" 
                                                   v-if="selectionIntervall === 'year'"/>
                    </div>
                    <div class="text-end mt-3">
                        <button class="btn btn-success mb-0 ms-auto" @click="getPlantJournalData" :disabled="isButtonConfirmDisabled">{{ $t("confirm") }}</button>
                    </div>
                </div>
            </div>
          
        </div>        
    </div>
    <div class="row" v-for="plant_journals in this.plantJournalData.data"
        v-bind:key="plant_journals.id">
        <div class="col-12">
            <div class="card shadow-sm">
                <div class="card-header card-header-space-between">
                    <p class="h5">{{ $t("plant_journal") }} - {{this.plant.name}} </p>
                    <div class="text-end mb-2">
                        <div class="dropdown">
                            <button class="btn " data-bs-toggle="dropdown" aria-expanded="true"><i class="fa-solid fa-ellipsis-vertical" ></i></button>
                    
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" @click="generatePdf('test')"><i class="fa-solid fa-file-pdf"></i> {{ $t("export_pdf") }}</a></li>
                                <li><a class="dropdown-item" @click="generateExcel('test')"><i class="fa-solid fa-file-excel"></i> {{ $t("export_excel") }}</a></li>
                            </ul>

                        </div>
                    </div>
                </div>
                <div class="card-body scroll-y">
                    
                    <table id="test" ref="testHtml" class="table table-striped table-hover table-bordered table-fixed-first-column">
                        <thead>
                            <tr>
                                <template
                                    v-for="head in plant_journals.header.headers"
                                    v-bind:key="head.name"
                                >
                                    <th :colspan="head.colspan" :rowspan="head.rowspan" class="text-center" >
                                        {{head.name}}
                                    </th>
                      
                                </template>
                            </tr>
                            <tr>
                                <template
                                    v-for="position in plant_journals.header.positions"
                                    v-bind:key="position.name"
                                >
                                    <th class="text-break dummy" style="min-width: 5rem;">
                                        {{position.name}}
                                    </th>
                      
                                </template>
                            </tr>
                        </thead>
                        <tbody>
                            <template
                                v-for="position in plant_journals.positions"
                                v-bind:key="position.id"
                            >
                                <tr>
                                    <td>
                                        {{position.created_at_date}}
                                    </td>
                                    <template
                                        v-for="value in position.data"
                                        v-bind:key="value.id"
                                    >
                                        <td >
                                            {{value.value}}
                                        </td>
                                    </template>
                                </tr>
                            </template>
                        </tbody>
  
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import axios from 'axios'
import { createToast } from '@/assets/js/myToast.js'
const fileDownload = require('js-file-download')
export default {
    name: 'AnalyseForPlant',
    components: {
    },
    props: {
        plant: {
            type: Object,
            required: true
        },
        
    },
    data() {
        return {
            plantIdOld: 0,
            date: null,
            selectionIntervall: "day",
            plantJournalData: {}
        }
    },
    updated(){
        console.log('entry updated')
        if(this.plantIdOld === 0){
            this.plantIdOld = this.plant.id
        }
        if(this.plantIdOld != this.plant.id){
            this.reset()
            this.plantIdOld = this.plant.id
        }
        console.log('exit updated')
    },
    methods: {
        generatePdf(htmlRef){
            console.log('entry generatePDF: ' +htmlRef)

            var doc = new jsPDF('l', 'pt', 'A4');
            
            doc.autoTable({ html: '#test', 
                            rowPageBreak: 'auto',
                            horizontalPageBreak: 'true',
                            horizontalPageBreakRepeat: 0,
                            //pageBreak: 'always',
                            // Default for all columns
                            styles: { overflow: 'linebreak'},
                            //bodyStyles: { valign: 'top' },
                            
                        })
            doc.save('table.pdf')
        },
        generateExcel(htmlRef){
            console.log('entry generateExcel: ' +htmlRef)

            var formData = this.getIntervall()
            
            formData.type_data = 'xlsx'
            axios
                .post(`/api/v1/plants/${this.plant.id}/get_plant_journal/`, formData, {responseType: 'blob'})
                .then(response => {
                    console.log('fertig')
                    fileDownload(response.data, `_auswertung.xlsx`);
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
            console.log('exit generateExcel')
        },
        getIntervall(){
            console.log('entry getIntervall')
            let startIntervall = {}
            let endIntervall = {}

            if(this.selectionIntervall === 'day') {
                console.log("day") 
                startIntervall['day'] = this.date[0].getDate()
                startIntervall['month'] = this.date[0].getMonth()
                startIntervall['year'] = this.date[0].getFullYear()
                endIntervall['day'] = this.date[1].getDate()
                endIntervall['month'] = this.date[1].getMonth()
                endIntervall['year'] = this.date[1].getFullYear()
            }
            if(this.selectionIntervall === 'month') {
                console.log("month") 
                startIntervall['day'] = '1'
                startIntervall['month'] = this.date[0]['month']
                startIntervall['year'] = this.date[0]['year']
                if(this.date[1] != null){
                    
                    var lastDayOfMonth = new Date(this.date[1]['year'], this.date[1]['month'] +1, 0)
                    endIntervall['day'] = lastDayOfMonth.getDate()
                    endIntervall['month'] = this.date[1]['month']
                    endIntervall['year'] = this.date[1]['year']
                }else{
                    var lastDayOfMonth = new Date(this.date[0]['year'], this.date[0]['month'] +1, 0)
                    endIntervall['day'] = lastDayOfMonth.getDate()
                    endIntervall['month'] = this.date[0]['month']
                    endIntervall['year'] = this.date[0]['year']
                }
                
            }
            if(this.selectionIntervall === 'year') {
                console.log("year") 
                startIntervall['day'] = '1'
                startIntervall['month'] = '0'
                startIntervall['year'] = this.date
                endIntervall['day'] = '31'
                endIntervall['month'] = '11'
                endIntervall['year'] = this.date
            }
            const formData = {
                startIntervall: startIntervall,
                endIntervall: endIntervall,
                type_intervall: this.selectionIntervall,
            }
            console.log('exit getIntervall')
            return formData
        },
        getPlantJournalData(){
            console.log('entry getPlantJournalData')
            console.log(this.date)

            var formData = this.getIntervall()
            
            formData.type_data = 'plain'
            axios
                .post(`/api/v1/plants/${this.plant.id}/get_plant_journal/`, formData)
                .then(response => {
                    console.log('fertig')
                    console.log(response.data)
                    this.plantJournalData = response.data
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit getPlantJournalData')
        },
        reset(){
            console.log('entry reset')
            this.selectionIntervall = "day",
            this.date = null
            this.plantJournalData = {}
            console.log('exit reset')
        },
        switchIntervall(){
            console.log('entry switchIntervall')
            this.date = null
            console.log('exit switchIntervall')
        }
    },
    computed:{
        isButtonConfirmDisabled(){
            if(this.selectionIntervall === 'live'){
                return false
            }

            if(this.date === null){
                return true
            }
            return false
        },
        getDatepickerFormat(){
            console.log("getDatepickerFormat entry")
            console.log('date:')
            console.log(this.date)
            if(this.selectionIntervall === 'day') {
                console.log("day") 
                return "dd/MM/yyyy"
            }
            if(this.selectionIntervall === 'month') {
                console.log("month") 
                return "MM/yyyy"
            }
            if(this.selectionIntervall === 'year') {
                console.log("year") 
                return "yyyy"
            }
        },
        
    }
}
</script>
