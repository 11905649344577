<template>
    <div class="live-data">
        <template v-if="this.anzLiveData > 1">
            <ul class="nav nav-tabs">
                <li v-for=" live_data in this.liveDatas"
                    v-bind:key="live_data.id">
                    <a class="nav-link" :class="{'active': this.liveDataId === live_data.id}" href="#" @click="setLiveDataId(live_data.id)">{{ live_data.name }}</a>
                </li>
            </ul>
        </template>
        <template v-else-if="this.anzLiveData === 0">
            {{ $t("no_live_data") }}
        </template>

        <template v-if="this.liveDataId">
            <component :is="currentTabComponent" v-bind="currentProperties"></component>
        </template>
    </div>

    
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import LiveDataForId from '@/components/livedata/LiveDataForId.vue'
import axios from 'axios'
export default {
    name: 'LiveData',
    components: {
      LiveDataForId
    },
    data() {
        return {
            liveDataId: '',
            anzLiveDataOld: 0,
            liveDatas: [],
            currentTabComponent: 'LiveDataForId',
            
        }
    },
    mounted(){
       this.loadLiveDatas()
    },
    updated(){
        console.log('updated')
        if(this.anzLiveDataOld === 0){
            this.setUpLiveData()
        }
    },
    unmounted(){
        console.log('component unmounted')
        
        
    },
    methods: {
        loadLiveDatas(){
            console.log('entry loadLiveDatas')
            axios
                .get(`/api/v1/live_datas/`)
                .then(response => {
                    console.log('fertig')
                    console.log(response.data)
                    this.liveDatas = response.data
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit loadLiveDatas')
        },
        setUpLiveData(){
            console.log('entry setUpLiveData')
            this.anzLiveDataOld = this.anzLiveData
            if(this.anzLiveData > 0){
                this.liveDataId = this.firstLiveData.id
            }
            console.log(this.liveDataId)
            console.log('exit setUpLiveData')
        },
        setLiveDataId(live_data_id){
            console.log('entry setLiveDataId: ' + live_data_id)
            this.liveDataId = live_data_id 
            console.log('exit setLiveDataId')
        }
    },
    computed:{
        anzLiveData(){

            return this.liveDatas.length
        },
        firstLiveData(){
            if(this.liveDatas === undefined){
                return null
            }
            
            if (this.liveDatas.length < 1){
                return null
            }
            return this.liveDatas[0]
        },
        currentProperties(){
            return {liveDataId: this.liveDataId}
        },
    }
}
</script>
