import Chart from 'chart.js/auto'
import { defineComponent, h, reactive } from 'vue'
import 'chartjs-adapter-date-fns';
import zoomPlugin from 'chartjs-plugin-zoom';

const notifyAfterRender = {
  id: 'notifyAfterRender',
  afterRender(chart, args, options) {
    console.log('notify afterRender')
    console.log(options.doAfterRender)
    options.doAfterRender()
    console.log('notify afterRender done')
  }
};

Chart.register(notifyAfterRender)
Chart.register(zoomPlugin);
/**
 *
 * @param chartsId string
 * @param chartsType string
 */
function generateChart(chartsId, chartsType) {

  return defineComponent({
    name: 'BaseChart',
    props: {
      chartId: {
        type: String,
        required: false
      },
      chartType: {
        type: String,
        required: false
      },
      width: {
        type: Number,
        required: false,
        default: 400
      },
      height: {
        type: Number,
        required: false,
        default: 400
      },
      cssClasses: {
        type: String,
        required: false,
        default: ''
      },
      styles: {
        type: Object,
        required: false
      }
    },
    data() {
      return {
          chartObj: null,
          rendered: false
      }
    },

    beforeUnmount() {
      console.log('entry beforeUnmount in BaseCharts.js')
      if (this.chartObj) {
        console.log('wir destroyen')
        //this.chartObj.destroy()
      }
      console.log('exit beforeUnmount')
    },
    methods: {
      getBase64Image(){
        if(this.chartObj){
          var base64 = this.chartObj.toBase64Image('image/png', 1)
          
          console.log(base64)
          return base64        }
      },
      renderChart(userData, userOptions) {
        console.log('renderChart')
        console.log('chartId: ' + this.chartId)
        if (this.chartObj) {
          console.log('wir sollten destroyen')
          this.chartObj.destroy()
        }
        if(!userData.datasets){
          console.log('keine labels vorhanden also exit')
          return
        }

        let ctx = this.$refs.canvas.getContext('2d')
        
        this.chartObj = new Chart(ctx, {
            type: chartsType,
            data: userData,
            options: userOptions
         })
         console.log('wir haben gezeichnet')
         console.log('chartId: ' + this.chartId)
      },
    
      removeDataFromChart(){
        this.chartObj.labels.pop()
        this.chartObj.data.datasets.forEach((dataset) => {
          dataset.data.pop();
        });
      },
      addDatatoChart(data){
        console.log('addDatatoChart')
        this.chartObj.data.datasets.forEach((dataset) => {
          dataset.data.push(data);
        });
      },
    },
    render() {
      console.log('render')
        return h('div', { style: this.styles, class: this.cssClasses }, [
          h('canvas', {
            ref: 'canvas',
            id: this.chartId,
            width: this.width,
            height: this.height
          })
        ])
      
    }
  })
}


const Bar = generateChart('bar-chart', 'bar')
const Bubble = generateChart('bubble-chart', 'bubble')
const Doughnut = generateChart('doughnut-chart', 'doughnut')
const HorizontalBar = generateChart('horizontalbar-chart', 'horizontalBar')
const Line = generateChart('line-chart', 'line')
const Pie = generateChart('pie-chart', 'pie')
const PolarArea = generateChart('polar-chart', 'polarArea')
const Radar = generateChart('radar-chart', 'radar')
const Scatter = generateChart('scatter-chart', 'scatter')

export {
  Bar,
  Bubble,
  Doughnut,
  HorizontalBar,
  Line,
  Pie,
  PolarArea,
  Radar,
  Scatter,
  generateChart
}
