<template>
    <div class="page-add-user">

        <div class="container-fluid my-3 py-3">
            <div class="row my-3">
                <div class="col-12">
                    <div class="form-floating">
                        <input id="inputName" type="text" name="name" class="form-control"  v-model="component.name" @keyup.enter="submitForm" placeholder="name@example.com">
                        <label for="inputName" class="form-label" >{{ $t("name") }}</label>
                    </div>
                </div>
                
            </div>
            <div class="row my-3">
                <div class="col-12">
                    <label for="type_component" class="form-label">{{ $t("type_component") }}</label>
                    <select id="type_component" class="form-select " aria-label="Default select example" v-model="component.type_component">
                        <option value="DA">{{ $t("component_type_component_da") }}</option>
                        <option value="SU">{{ $t("component_type_component_su") }}</option>
                    </select>
                </div>
            </div>
            <div class="row my-3" v-if="component.type_component == 'DA'">
                <div class="col-12">
                    <div class="form-floating">
                        <input id="inputName" type="text" name="name" class="form-control"  v-model="component.folder_name_import" @keyup.enter="submitForm" placeholder="name@example.com">
                        <label for="inputName" class="form-label" >{{ $t("folder_name_import") }}</label>
                    </div>
                </div>
                
            </div>
            <div class="row my-3">
                <div class="col-6">
                    <div class="form-floating">
                        <input :disabled="!$store.state.me.is_superuser" id="inputName" type="text" name="name" class="form-control"  v-model="component.dashboard_group_name" @keyup.enter="submitForm" placeholder="name@example.com">
                        <label for="inputName" class="form-label" >{{ $t("dashboard_group_name") }}</label>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-floating">
                        <input :disabled="!$store.state.me.is_superuser" id="inputName" type="text" name="name" class="form-control"  v-model="component.daily_report_group_name" @keyup.enter="submitForm" placeholder="name@example.com">
                        <label for="inputName" class="form-label" >{{ $t("daily_report_group_name") }}</label>
                    </div>
                </div>
            </div>
            <div class="row my-3" v-if="component.type_component == 'DA'">
                <div class="col-4">
                    <div class="form-floating">
                        <input :disabled="!$store.state.me.is_superuser" id="inputName" type="text" name="name" class="form-control"  v-model="component.import_data_date_column_name" @keyup.enter="submitForm" placeholder="name@example.com">
                        <label for="inputName" class="form-label" >{{ $t("import_data_date_column_name") }}</label>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-floating">
                        <input :disabled="!$store.state.me.is_superuser" id="inputName" type="text" name="name" class="form-control"  v-model="component.import_data_time_column_name" @keyup.enter="submitForm" placeholder="name@example.com">
                        <label for="inputName" class="form-label" >{{ $t("import_data_time_column_name") }}</label>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-floating">
                        <input :disabled="!$store.state.me.is_superuser" id="inputName" type="text" name="name" class="form-control"  v-model="component.import_data_datetime_format" @keyup.enter="submitForm" placeholder="name@example.com">
                        <label for="inputName" class="form-label" >{{ $t("import_data_datetime_format") }}</label>
                    </div>
                </div>
                
            </div>
            <div class="row my-3" v-if="component.type_component == 'DA'">
                <div class="col-4">
                    <div class="form-floating">
                        <input :disabled="!$store.state.me.is_superuser" id="inputName" type="text" name="name" class="form-control"  v-model="component.file_prefix" @keyup.enter="submitForm" placeholder="name@example.com">
                        <label for="inputName" class="form-label" >{{ $t("file_prefix") }}</label>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-floating">
                        <input :disabled="!$store.state.me.is_superuser" id="inputName" type="text" name="name" class="form-control"  v-model="component.sernum" @keyup.enter="submitForm" placeholder="name@example.com">
                        <label for="inputName" class="form-label" >{{ $t("sernum") }}</label>
                    </div>
                </div>
            </div>
            <div class="row my-3" v-if="component.type_component == 'DA'">
                <div class="col-12">
                    <select class="form-select " aria-label="Default select example" v-model="typeInit">
                        <option value="dummy">{{$t("dummy_type_init")}}</option>
                        <option value="InitInverter">{{$t("type_init_inverter")}}</option>
                    </select>
                </div>
            </div>
            <template v-if="this.typeInit!='dummy'">
                <component ref="init" :is="typeInit" v-bind="currentProperties" v-on:closeModal="closeModal"></component>
            </template>
            <button class="btn btn-success mb-0 ms-auto" @click="submitForm">{{ $t("save") }}</button>
        </div>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
import InitInverter from '@/components/masterdata/InitInverter.vue'
export default {
    name: 'AddComponent',
    emits: ['closeModal'],
    props: {
        plant: {
            type: Object,
            required: true,
        },
    },
    components: {
        InitInverter
    },
    data() {
        return {
            component: {
                import_data_datetime_format: '%d.%m.%Y %H:%M:%S',
                import_data_time_column_name: 'Uhrzeit',
                import_data_date_column_name: 'Datum',
                type_component: 'DA'
            },
            addedComponent: {},
            typeInit: 'dummy'
        }
    },

    methods: {
        closeModal(){
            console.log('entry closeModal')
            this.$emit('closeModal', 'add', this.addedComponent)

            console.log('exit closeModal')
        },
        submitForm() {

            this.component.plant = this.plant.id
            this.component.created_by = this.$store.state.me.id
            this.component.modified_by = this.$store.state.me.id
            this.component.users = {}
            axios
                .post(`/api/v1/components/`, this.component)
                .then(response => {
                    
                    createToast({text: this.$t("component added")
                   ,type: 'success'})

                   if(this.typeInit!= 'dummy'){
                        this.addedComponent = response.data
                        this.$refs.init.initComponent(response.data)
                   }else{
                        this.$emit('closeModal', 'add', response.data)
                   }
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
        }
    },
    computed: {
        currentProperties(){
                return {}
        },
  }
}
</script>