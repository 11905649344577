<template>
    <div class="col-12">
        <div class="card shadow-sm">

            <div class="card-body scroll-y">
                <h5 class="card-title">{{ evaluation.name }}</h5>
                <table id="test" ref="testHtml" class="table table-striped table-hover table-bordered">
                    <thead>
                        <tr>
                            <th rowspan="2">Datum</th>
                            <template v-for="header in dataForVisualize.headers" v-bind:key="header">
                                <th :colspan="header.colspan">{{ header.name }}</th>
                            </template>
                        </tr>
                        <tr>
                            <template v-for="header_position in dataForVisualize.header_positions" v-bind:key="header_position">
                                <th :colspan="header_position.colspan">{{ header_position.name }}</th>
                            </template>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="position in dataForVisualize.positions">
                            <td>{{getDateFormatted(position.name)}}</td>
                            <template v-for="value in position.values">
                                <td>{{ value.value }} <template v-if="value.unit"><span class="table-unit"> {{ value.unit }}</span></template></td>
                            </template>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th>Summe</th>
                            <template v-for="value in dataForVisualize.footer">
                                <th>{{ value.value }} <template v-if="value.unit"><span class="table-unit"> {{ value.unit }}</span></template></th>
                            </template>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</template>

<script>

import { de } from 'date-fns/locale';
export default {
    name: 'VisualizeEvaluationData',
    
    props: {
        dataForVisualize: {
            type: Object,
            required: true
        },
        evaluation: {
            type: Object,
            required: true
        },
    },

    data() {
        return {
            chartDone: true,
            
            
        }
    },
    mounted(){
    },
    updated(){
        console.log('entry updated')

        console.log('exit updated')
    },
    methods: {
        getDateFormatted(date_string){
            let date = new Date(date_string)
            if (this.evaluation.type_evaluation == 'MO'){
                const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Monat im Format MM
                const year = date.getFullYear(); // Jahr im Format YYYY
                return `${month}.${year}`;
            }
            return date.toLocaleDateString()
        },
       
        
    },
    computed:{
        

        
    }
}
</script>
