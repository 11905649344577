<template>
    <div class="dashboard-row-view">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-3"
                v-for="column in dataOverview"
                v-bind:key="column.index"
            >
                <table class="table table-sm table-striped mb-3 text-center"
                    v-for="table in column"
                    v-bind:key="table.index"
                >
                    <thead>
                        <tr>
                            <th colspan="3">
                               <template v-if="table.status_data || table.status_data == '0'"> <img :src="require('@/assets/icons/status_'+ table.status_data + '.gif')"/> &nbsp;</template> 
                               {{ $t(table.name) }} 
                               <template v-if="table.status_data || table.status_data == '0'">&nbsp; <img :src="require('@/assets/icons/status_'+ table.status_data + '.gif')"/></template> 
                               
                               <template v-if="table.time_update"><br><span class="small">{{ $t("last_value") }} {{ getTimeFormatted(table.time_update) }}</span></template>
                            </th>
                        </tr>
                    </thead>
                    <tr
                        v-for="table_row in table.data"
                        v-bind:key="table_row.index"
                        :class="{ 'tr-limit-reached': isLimitReached(table_row)}"
                    >
                        <td class="text-start">
                            {{table_row.name}}
                        </td>
                        <td class="text-end">
                            <template v-if="table_row.data_type =='datetime'">{{getDateTimeFormatted(table_row.data)}}</template>
                            <template v-else>{{table_row.data}}</template> 
                            <template v-if="table_row.unit">[{{table_row.unit}}]</template>
                        </td>
                        <i class="fa-solid fa-down-long" v-if="isLimitLowReached(table_row)"></i>
                        <i class="fa-solid fa-up-long" v-if="isLimitUpperReached(table_row)"></i>
                    </tr>
                </table>
            </div>
        </div>
        
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'

const fileDownload = require('js-file-download')
export default {
    name: 'DashboardRowView',
    emits: ['closeModal',
            'updateTime'],
    props: {
        plant: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            dataOverview : '',
            
            polling: null,
            isStartup: true,
            loading: false,
        }
    },
    mounted(){
        this.pollData()
    },
    updated(){
        
    },
    unmounted(){
        console.log('component unmounted')
        
        clearInterval(this.polling)
    },
    methods: {
        pollData () {
            
		    this.polling = setInterval(() => {
                console.log('timer hat gefeuert. erstmal schlafen')
                setTimeout(() => {
                    this.getDataForPlant()
                    
                }, 2000)
		    }, process.env.VUE_APP_REFRESH_INTERVALL_OVERVIEW)

        if(this.isStartup){
          this.getDataForPlant()
          this.isStartup = false
        }
	    },
        getDataForPlant(){
            console.log('entry getDataForPlant: ' + this.plant.name)

            var formData = {}

            formData.type_data = 'plain'
            axios
                .post(`/api/v1/plants/${this.plant.id}/get_data_for_overview/`, formData)
                .then(response => {
                    console.log('fertig')
                    console.log(response.data)
                    this.processData(response.data)
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
            console.log('exit getDataForPlant')
        },
        getDataForPlantExcel(){
            console.log('entry getDataForPlant: ' + this.plant.name)

            var formData = {}

            formData.type_data = 'xlsx'
            axios
                .post(`/api/v1/plants/${this.plant.id}/get_data_for_overview/`, formData, {responseType: 'blob'})
                .then(response => {
                    console.log('fertig')
                    fileDownload(response.data, `${this.plant.name}_Auswertung.xlsx`);
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
            console.log('exit getDataForPlant')
        },
        getDataForPlantPdf(){
            console.log('entry getDataForPlant: ' + this.plant.name)

            var formData = {}

            formData.type_data = 'pdf'
            axios
                .post(`/api/v1/plants/${this.plant.id}/get_data_for_overview/`, formData, {responseType: 'blob'})
                .then(response => {
                    console.log('fertig')
                    fileDownload(response.data, `${this.plant.name}_Auswertung.pdf`);
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
            console.log('exit getDataForPlant')
        },
        processData(data){
            console.log('entry processData')
            console.log(data)

            let updatedTime = new Date(data.time_update)
            console.log('nach updatedTime')
            this.$emit('updateTime', (updatedTime.getHours()<10?'0':'') + updatedTime.getHours() + ':' + (updatedTime.getMinutes()<10?'0':'') + updatedTime.getMinutes())
            console.log(data.data)
            this.dataOverview = data.data
            console.log('exit processData')
        },
        submitForm() {

            this.plant.organisation = this.organisation.id
            this.plant.created_by = this.$store.state.me.id
            this.plant.modified_by = this.$store.state.me.id
            this.plant.users = {}
            axios
                .post(`/api/v1/plants/`, this.plant)
                .then(response => {
                    
                    createToast({text: this.$t("plant added")
                   ,type: 'success'})

                   this.$emit('closeModal')
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        isLimitLowReached(table_row){
            if(!table_row.limit_low)
                return false
            
            return Number(table_row.data) <= Number(table_row.limit_low)
        },  
        isLimitUpperReached(table_row){
            if(!table_row.limit_upper)
                return false
            return Number(table_row.data) >= Number(table_row.limit_upper)
        }, 
        isLimitReached(table_row){
            if(!table_row.limit_low || !table_row.limit_upper){
                return false
            }
            return Number(table_row.data) <= Number(table_row.limit_low) || Number(table_row.data) >= Number(table_row.limit_upper)
        },
        getDateTimeFormatted(time){
            if(!time){
                return ''
            }

            let updatedTime = new Date(time)
            return updatedTime.getDate() + '.' + (updatedTime.getMonth()+1) + ' ' + (updatedTime.getHours()<10?'0':'') + updatedTime.getHours() + ':' + (updatedTime.getMinutes()<10?'0':'') + updatedTime.getMinutes()
        },
        getTimeFormatted(time){
            if(!time){
                return ''
            }

            let updatedTime = new Date(time)
            const options = {
                day: "2-digit",
                month: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
            };
            return updatedTime.toLocaleDateString("de-DE", options).replace(",", "");
        }
    },
    computed: {
        dataOverviewComputed(){
            return this.dataOverview
        },
        
    }
}
</script>