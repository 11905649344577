<template>
    
    <nav class="navbar navbar-expand-md fixed-top navbar-dark bg-dark" aria-label="Main navigation">
      <div class="container-fluid">
        <router-link to="/dashboard" :class="{'navbar-brand': !$store.state.organisation.logo}" href="#"><template v-if="$store.state.organisation.logo"><img :src="get_logo" style="max-height:48px;" class="img-fluid" ></template><template v-else>Re-Ti-Pla</template></router-link>
        <button class="navbar-toggler p-0 border-0" type="button" id="navbarSideCollapse" aria-label="Toggle navigation" @click="toggleTopNavbar()">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="navbar-collapse offcanvas-collapse" :class="{ 'open': topbarToggled }" id="topNavbar">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <router-link to="/dashboard" class="nav-link">
                <i class="fa-solid fa-house"></i>
                Dashboard
              </router-link>
            </li>
            <li class="nav-item" v-if="this.canReadOrganisationDailyReport && this.orga_has_daily_report">
              <router-link to="/organisation_daily_report" class="nav-link">
                <i class="fa-solid fa-calendar-pen"></i>
                {{$t("organisation_daily_report")}}
              </router-link>
            </li>
            <li class="nav-item" v-if="this.canReadLiveData">
              <router-link to="/live-data" class="nav-link">
                <i class="fa-solid fa-database"></i>
                {{$t("live_data_topbar")}}
              </router-link>
            </li>
            <li class="nav-item dropdown" v-if="this.canReadAnalyse">
              <a class="nav-link dropdown-toggle" href="#" id="dropdownAnalyse" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="fa-light fa-file-spreadsheet"></i>
                {{ $t("reports") }}
              </a>
              <ul class="dropdown-menu" aria-labelledby="dropdownAnalyse">
                <li v-if="this.canReadAnalysePlant"><router-link to="/plant_journals" class="dropdown-item">{{ $t("plant_journals") }}</router-link></li>
                <li v-if="this.canReadAlarmmessagesPlant"><router-link to="/alarmmessages_for_plants" class="dropdown-item">{{ $t("alarmmessages_for_plants") }}</router-link></li>
                <li v-if="this.$store.state.me.is_superuser"><router-link to="/plant_evaluations_for_plants" class="dropdown-item">{{ $t("plant_evaluations_for_plants") }}</router-link></li>
                <li><hr class="dropdown-divider"></li>
                <li v-if="this.orgaHasSolarPlants"><router-link :to="{ name: 'Evaluation', params: { type: 'solar' } }" class="dropdown-item">{{ $t("evaluation_solar") }}</router-link></li>
                <li v-if="this.orgaHasWindPlants"><router-link :to="{ name: 'Evaluation', params: { type: 'windkraft' } }" class="dropdown-item">{{ $t("evaluation_wind") }}</router-link></li>
                <li v-if="this.orgaHasBiogasPlants"><router-link :to="{ name: 'Evaluation', params: { type: 'biogas' } }" class="dropdown-item">{{ $t("evaluation_biogas") }}</router-link></li>
                
              </ul>
            </li>
            
            <li class="nav-item" v-if="this.canReadResearch">
              <router-link to="/analyse" class="nav-link">
                <i class="fa-solid fa-magnifying-glass"></i>
                {{$t("research")}}
              </router-link>
            </li>
          
            <li class="nav-item dropdown" v-if="this.canReadSettings">
              <a class="nav-link dropdown-toggle" href="#" id="dropdownSettings" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="fa-solid fa-gears"></i>
                {{ $t("settings") }}
              </a>
              <ul class="dropdown-menu" aria-labelledby="dropdownSettings">
                <li v-if="this.canReadOrganisation"><router-link to="/settings/masterdata" class="dropdown-item">{{ $t("master_data") }}</router-link></li>
                <li v-if="this.canReadOrganisation"><router-link to="/settings/users" class="dropdown-item">{{ $t("users") }}</router-link></li>
                <li v-if="$store.state.me.is_superuser"><router-link to="/settings/permissions" class="dropdown-item">{{ $t("permissions") }}</router-link></li>                
                <li v-if="this.canReadPlants"><hr class="dropdown-divider"></li>
                <li v-if="this.canReadUserGroups"><router-link to="/settings/report_users" class="dropdown-item">{{ $t("report_users") }}</router-link></li>
                <li v-if="this.canReadUserGroups"><router-link to="/settings/usergroups" class="dropdown-item">{{ $t("user_groups") }}</router-link></li>
                <li v-if="this.canReadPlants"><hr class="dropdown-divider"></li>
                <li v-if="$store.state.me.is_superuser"><router-link to="/settings/daily_report" class="dropdown-item">{{ $t("config_organisation_daily_report") }}</router-link></li>
                <li v-if="$store.state.me.is_superuser"><router-link to="/settings/daily_report_mail" class="dropdown-item">{{ $t("config_organisation_daily_report_mail") }}</router-link></li>
                <li v-if="$store.state.me.is_superuser"><router-link to="/settings/live_datas" class="dropdown-item">{{ $t("live_data_config") }}</router-link></li>
                <li v-if="this.canReadPlants"><router-link to="/settings/plants" class="dropdown-item">{{ $t("plants") }}</router-link></li>
                <li v-if="$store.state.me.is_superuser"><router-link to="/settings/config_plant_dashboards" class="dropdown-item">{{ $t("plant_dashboards_config") }}</router-link></li>
                <li v-if="this.canReadLimits"><router-link to="/settings/edit_limits" class="dropdown-item">{{ $t("edit_limits") }}</router-link></li>
                <li v-if="this.canReadAlarmcodes"><router-link to="/settings/alarmcodesorganisation" class="dropdown-item">{{ $t("alarmcodesorganisation") }}</router-link></li>
                <li v-if="$store.state.me.is_superuser"><router-link to="/settings/config_plant_journals" class="dropdown-item">{{ $t("config_plant_journals") }}</router-link></li>
              </ul>
            </li>

            <li class="nav-item dropdown" v-if="$store.state.me.is_superuser">
              <a class="nav-link dropdown-toggle" href="#" id="dropdownAdminSettings" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="fa-solid fa-hammer"></i>
                {{ $t("admin_settings") }}
              </a>
              <AdminSettings></AdminSettings>
            </li>

            <li class="nav-item dropdown" v-if="$store.state.me.is_superuser">
              <a class="nav-link dropdown-toggle" href="#" id="dropdownAdminBilling" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="fa-solid fa-money-bill-wave"></i>
                {{ $t("admin_billing") }}
              </a>
              <ul class="dropdown-menu" aria-labelledby="dropdownAdminBilling">
                <li><router-link to="/billing/plant_notifications" class="dropdown-item">{{ $t("plant_notfications") }}</router-link></li>
                <li><router-link to="/billing/plant_notification_parks" class="dropdown-item">{{ $t("plant_notification_parks") }}</router-link></li>
                <li><router-link to="/billing/organisation_price_overview" class="dropdown-item">{{ $t("organisation_price_overview") }}</router-link></li>
              </ul>
            </li>

            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="dropdownUserSettings" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="fa-solid fa-user"></i>
                {{$store.state.me.username}}
              </a>
              <ul class="dropdown-menu" aria-labelledby="dropdownUserSettings">
                <li v-if="$store.state.me.is_superuser"><hr class="dropdown-divider"></li>
                <li v-if="$store.state.me.is_superuser"> <ChooseOrganisation></ChooseOrganisation></li>
                <li v-if="$store.state.me.is_superuser"><hr class="dropdown-divider"></li>
                <li><router-link to="/account/change-password" class="dropdown-item"><i class="fa-solid fa-unlock"></i> {{ $t("change_password") }}</router-link></li>
                <li><a class="dropdown-item clickable" @click="logout()" > <i class="fa-solid fa-right-from-bracket"></i> {{ $t("sign_out") }}</a></li>
              </ul>
            </li>
            
          </ul>
          <a @click="getHowToFromBackend()" class="btn btn-outline-secondary btn-sm" href="#" v-if="['root', 'Orga-Manager'].includes($store.state.me.grp_name_bez)">
            <i class="fa-solid fa-book"></i> {{$t("how_to")}}
          </a>
          
        </div>
      </div>
    </nav>
</template>


<script>
import axios from 'axios'
import ChooseOrganisation from '@/components/ChooseOrganisation.vue'
import AdminSettings from '@/components/navs/AdminSettings.vue'

const fileDownload = require('js-file-download')
export default {
    name: 'Topbar',
    components: {
        ChooseOrganisation,
        AdminSettings
    },
    data() {
        return {
          topbarToggled: false
        }
    },
    methods: {
      getHowToFromBackend(){
        console.log('entry getHowToFromBackend')
        axios
          .get(`/api/v1/get_how_to/${this.$store.state.me.grp_name_bez}/`, {responseType: 'blob'})
          .then(response => {
              console.log('fertig')
              fileDownload(response.data, `How_To.pdf`);
          })
          .catch(error => {
            console.log('error')
            console.log(error)
          })
        console.log('exit getHowToFromBackend')
      },
      toggleTopNavbar(){
        console.log('entry toggleTopNavbar')
        this.topbarToggled = !this.topbarToggled
        console.log('exit toggleTopNavbar')
      },
      logout() {
        axios
          .post("/api/v1/token/logout/")
          .then(response => {
            axios.defaults.headers.common["Authorization"] = ""

            localStorage.removeItem("username")
            localStorage.removeItem("userid")
            localStorage.removeItem("token")
            localStorage.removeItem("mustSetupCompany")
            this.$store.commit('removeToken')
            localStorage.removeItem("permissions")
            this.$router.push('/')
          })
          .catch(error => {
            if (error.response) {
              console.log(JSON.stringify(error.response.data))
            } else if (error.message) {
              console.log(JSON.stringify(error.message))
            } else {
              console.log(JSON.stringify(error))
            }
            axios.defaults.headers.common["Authorization"] = ""

            localStorage.removeItem("username")
            localStorage.removeItem("userid")
            localStorage.removeItem("token")
            localStorage.removeItem("permissions")

            this.$store.commit('removeToken')

            this.$router.push('/')
          })
        }
    },
    computed:{
      orga_has_daily_report(){
        return this.$store.state.organisation.has_daily_report
      },
      get_logo() {
            if(this.$store.state.organisation.get_logo_base64 == null){
                return 
            }
            if(this.$store.state.organisation.get_logo_base64.startsWith('data:image')){
                return this.$store.state.organisation.get_logo_base64
            }
            return 'data:image/png;base64,' + this.$store.state.organisation.get_logo_base64
        },
      orgaHasWindPlants(){
        return this.$store.getters.getPlants.filter(plant => {
                    if (plant.type){
                        return plant.type.toLowerCase() === 'windkraft'
                    }else{
                        return false
                    }}).length > 0
      },
      orgaHasSolarPlants(){
        return this.$store.getters.getPlants.filter(plant => {
                    if (plant.type){
                        return plant.type.toLowerCase() === 'solar'
                    }else{
                        return false
                    }}).length > 0
      },
      orgaHasBiogasPlants(){
       return this.$store.getters.getPlants.filter(plant => {
                    if (plant.type){
                        return plant.type.toLowerCase() === 'biogas'
                    }else{
                        return false
                    }}).length > 0
      },
      canReadOrganisationDailyReport(){
        return this.$store.getters.userHasPermission('organisation_daily_report','can_read')
      },
      canReadConfigPlantJournals(){
        return this.$store.getters.userHasPermission('plant_journal_config','can_read')
      },
      canReadAnalyse(){
        return this.canReadAnalysePlant || this.canReadAlarmmessagesPlant
      },
      canReadResearch(){
        return this.$store.getters.userHasPermission('analyse_plant','can_read')
      },
      canReadSettings(){
        return this.canReadOrganisation || this.canReadPermissions || this.canReadPlants || this.canReadUserGroups || this.canReadLimits || this.canReadAlarmcodes
      },
      canReadLiveData(){
            return this.$store.getters.userHasPermission('live_data','can_read')
        },
      canReadAnalysePlant(){
            return this.$store.getters.userHasPermission('analyse_plant','can_read')
        },
      canReadAlarmmessagesPlant(){
            return this.$store.getters.userHasPermission('alarmmessages_plant','can_read')
        },
      canReadUserGroups(){
            return this.$store.getters.userHasPermission('user_group','can_read')
        },
      canReadLimits(){
            return this.$store.getters.userHasPermission('limits','can_read')
        },
      canReadPlants(){
            return this.$store.getters.userHasPermission('plant','can_read')
        },
      canReadLiveDataConfig(){
            return this.$store.getters.userHasPermission('live_data_config','can_read')
        },
      canReadPermissions(){
            return this.$store.getters.userHasPermission('permission','can_read')
        },
      canReadAlarmcodes(){
            return this.$store.getters.userHasPermission('alarmcodes', 'can_read')
        },
        canReadOrganisation(){
            return this.$store.getters.userHasPermission('organisation', 'can_read')
        },
    }
}
</script>