<template>
    <div class="page-add-live-data-header">

        <div class="container-fluid my-3 py-3">
            <div class="row my-3">
                <div class="col-8">
                    <div class="form-floating">
                        <input id="inputName" ref="name" type="text" name="name" class="form-control"  v-model="liveData.name" placeholder="name@example.com">
                        <label for="inputName" class="form-label" >{{ $t("name") }}</label>
                    </div>
                </div>                
            </div>
            <button class="btn btn-success mb-0 ms-auto" @click="submitForm">{{ $t("save") }}</button>
        </div>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'AddLiveData',
    emits: ['closeModal'],
    props: {
        organisation: {
            type: Object,
            required: true,
        },
        
    },
    data() {
        return {
            liveData: {
                name: ''
            },
        }
    },
    mounted(){
        console.log('entry mounted')
        this.focusInput()
        console.log('exit mounted')
    },
    methods: {
        focusInput() {
            console.log('entry focusInput')
            console.log(this.$refs.name)
            this.$refs.name.focus();
            console.log('exit focusInput')
        },
        submitForm() {
            this.liveData.organisation = this.organisation.id
            this.liveData.created_by = this.$store.state.me.id
            this.liveData.modified_by = this.$store.state.me.id
            this.liveData.plants = []
            
            axios
                .post(`/api/v1/organisation_live_datas/`, this.liveData)
                .then(response => {
        
                    createToast({text: this.$t("live_data added")
                        ,type: 'success'})
                    this.liveData.name = ''

                this.$emit('closeModal', 'add', response.data)
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
        }
    },
    computed:{
    }
}
</script>