<template>
    <div class="row">
        <div class="col-md-3 col-lg-2 d-md-block bg-light sidebar">
            <NavOrganisationsSidebar v-bind="currentProperties" 
                    v-on:setOrganisation="setOrganisation"></NavOrganisationsSidebar>
        </div>
        <div class="col-md-9 ms-sm-auto col-lg-10" v-if="this.currentOrganisation">
            <PlantNotificationsForOrganisation v-bind="currentNotificationProperties" 
                ></PlantNotificationsForOrganisation>
        </div>
    </div>
   
</template>

<script>
import axios from 'axios'

import NavOrganisationsSidebar from '@/components/navs/NavOrganisationsSidebar.vue'
import PlantNotificationsForOrganisation from '@/components/Alarmmessages/PlantNotificationsForOrganisation.vue'
export default {
    name: 'PlantNotifications',
    components: {
        NavOrganisationsSidebar,
        PlantNotificationsForOrganisation
    },
    data() {
        return {
            notifications: [],
            currentPage: 1,
            showNextButton: false,
            showPrevButton: false,
            allOrganisations: [],
            currentOrganisation: ""
        }
    },
    mounted(){
        this.getAllOrganisations()
    },
    updated(){

    },
    unmounted(){
        console.log('component unmounted')
        
    },
    methods: {
        setOrganisation(organisation){
            console.log('entry setOrganisation')
            this.currentOrganisation = organisation
            console.log('exit setOrganisation')
        },
        getAllOrganisations(){
            console.log('entry getAllOrganisations')
            axios
                .get('/api/v1/allOrganisations/')
                .then(response => {
                    for (let i = 0; i < response.data.length; i++) {
                        this.allOrganisations.push(response.data[i])
                    }
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
            console.log('exit getAllOrganisations')
        },
        loadNext() {
            this.currentPage += 1
            this.loadPlantNotifications()
        },
        loadPrev() {
            this.currentPage -= 1
            this.loadPlantNotifications()
        },
        getTimeFormatted(time){
            let updatedTime = new Date(time)
            return updatedTime.toLocaleDateString()
        },
    },
    computed:{
        currentProperties(){
                return {allOrganisations: this.allOrganisations}
            },
        currentNotificationProperties(){
                return {organisation: this.currentOrganisation}
            },
    }
}
</script>
