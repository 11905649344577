<template>

<component v-if="dailyReportsLoaded" :is="currentTabComponent" v-bind="currentProperties"></component>
</template>

<script>

import EditOrganisationDailyReport from '@/components/dailyreport/EditOrganisationDailyReport'
import axios from 'axios'
export default {
    name: 'ConfigOrganisationDailyReport',
    components: {
        EditOrganisationDailyReport
    },
    data() {
        return {
            allOrganisationDailyReports: [],
            currentTabComponent: "EditOrganisationDailyReport",
            dailyReportsLoaded: false
        }
    },
    mounted(){
        this.loadOrganisationDailyReport()
    },
    updated(){
        console.log('updated')
        
    },
    unmounted(){
        console.log('component unmounted')
        
    },
    methods: {
        loadOrganisationDailyReport(){
            console.log('entry loadOrganisationDailyReport')
            axios
                .get('/api/v1/organisation_daily_reports/')
                .then(response => {
                    console.log('daily reports loaded')
                    console.log(response.data)
                    this.allOrganisationDailyReports = response.data
                    this.dailyReportsLoaded = true
                    
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
            console.log('exit loadOrganisationDailyReport')
        }
    },
    computed:{
        
        currentProperties(){
                return {organisationDailyReportId: this.allOrganisationDailyReports[0].id}
            },
    }
}
</script>