<template>
    <div class="loading-block" v-if="!this.chartDone"></div>
    <div class="row">
        <div class="col-6 col-xl-4 my-2" v-if="getAllColDescriptionsFormatted.length > 0">
            <div class="card shadow-sm">
                <div class="card-header d-flex align-items-center">
                    <p class="text-center h5">{{ $t("selectionData") }}</p>
                    
                </div>
                <div class="card-body">
                    <div class="row">
                        <template
                            v-for="descriptions in getAllColDescriptionsFormatted"
                            v-bind:key="descriptions.id"
                        >
                            <div :class="{'col-6': getAllColDescriptionsFormatted.length > 1, 'col': getAllColDescriptionsFormatted.length === 1}">
                                <template
                                v-for="description in descriptions"
                                v-bind:key="description.id"
                                >
                                    <div class="form-check" v-tooltip :title="getTooltip(description)">
                                        <input :disabled="!description.is_suitable_for_analyse" class="form-check-input" type="checkbox" :value="description.col_component_data" :id="description.col_component_data" v-model="checkedDescriptions">
                                        <label class="form-check-label" :for="description.col_component_data" >
                                            {{description.col_description}}
                                        </label>
                                    </div>

                                </template>
                            </div>
                        
                        </template>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="col-6 col-xl-4 my-2" v-if="checkedDescriptions.length != 0">
            <div class="card shadow-sm">
                <div class="card-header text-center">
                    <p class="h5">{{ $t("selectionIntervall") }}</p>
                    
                </div>
                <div class="card-body ">
                    <div class="mt-2 text-center">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" @click="switchIntervall" type="radio" name="flexRadioDefault" id="selectionDay" v-model="selectionIntervall" v-bind:value="'day'" :disabled="checkedDescriptions.length === 0">
                            <label class="form-check-label" for="selectionDay">
                                {{ $t("day") }}
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" @click="switchIntervall" type="radio" name="flexRadioDefault" id="selectionMonth" v-model="selectionIntervall" v-bind:value="'month'" :disabled="checkedDescriptions.length === 0">
                            <label class="form-check-label" for="selectionMonth">
                                {{ $t("month") }}
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" @click="switchIntervall" type="radio" name="flexRadioDefault" id="selectionYear" v-model="selectionIntervall" v-bind:value="'year'" :disabled="checkedDescriptions.length === 0">
                            <label class="form-check-label" for="selectionYear">
                                {{ $t("year") }}
                            </label>
                        </div>
                    </div>
                    <hr>
                    <div class="mt-2 text-center">
                        <Datepicker v-model="date" range closeOnScroll autoApply 
                                                   :format="getDatepickerFormat" locale="de"
                                                   cancelText="abbrechen" selectText="auswählen" 
                                                   :enableTimePicker="true"
                                                   v-if="selectionIntervall === 'day'"/>
                        <Datepicker v-model="date" monthPicker
                                                   range closeOnScroll autoApply 
                                                   :format="getDatepickerFormat" locale="de"
                                                   cancelText="abbrechen" selectText="auswählen" 
                                                   :enableTimePicker="false" 
                                                   v-if="selectionIntervall === 'month'"/>
                        <Datepicker v-model="date" yearPicker
                                                   closeOnScroll autoApply 
                                                   :format="getDatepickerFormat" locale="de"
                                                   cancelText="abbrechen" selectText="auswählen" 
                                                   :enableTimePicker="false" 
                                                   v-if="selectionIntervall === 'year'"/>
                    </div>
                    <div class="text-end mt-3">
                        <button class="btn btn-success mb-0 ms-auto" @click="generateChart" :disabled="isButtonConfirmDisabled">{{ $t("confirm") }}</button>
                    </div>
                </div>
            </div>
          
        </div>

        <ChartWithExport v-bind:chartId="chartDataId" v-bind:chartData="chartData"  v-bind:chartOptions="chartOptions" v-on:generateExcel="generateExcel"/>
        
    </div>
    
</template>

<script>
import axios from 'axios'
import ChartWithExport from '@/components/ChartWithExport.vue'
const fileDownload = require('js-file-download')

import { createToast } from '@/assets/js/myToast.js'
export default {
    name: 'ResearchForPlant',
    components: {
        ChartWithExport
    },
    props: {
        plant: {
            type: Object,
            required: true
        },
        component: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            componentIdOld: 0,
            date: null,
            chartDone: true,
            selectionIntervall: "day",
            checkedDescriptions: [],
            chartDataId: 'test',
            chartDataset: [],
            chartLabelArray: [],
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    notifyAfterRender: {
                        doAfterRender: setDone => {
                            this.chartDone = true
                        }
                    }
                }
            },
        }
    },
    mounted(){

    },
    updated(){
        console.log('entry updated')
        if(this.componentIdOld === 0){
            this.componentIdOld = this.component.id
        }
        if(this.componentIdOld != this.component.id){
            this.reset()
            this.componentIdOld = this.component.id
        }
        console.log('exit updated')
    },
    methods: {
        
        getTooltip(description){
            if(!description.is_suitable_for_analyse){
                if(description.col_data_delimiter){
                    return "Kombinierte Felder können nicht ausgewertet werden! (" + description.col_unit +")"
                }
            }
            return ""
        },
        reset(){
            console.log('entry reset')
            this.checkedDescriptions = []
            this.selectionIntervall = "day",
            this.date = null
            this.chartDataset = []
            this.chartLabelArray = []
            console.log('exit reset')
        },

        switchIntervall(){
            console.log('entry switchIntervall')
            this.date = null
            console.log('exit switchIntervall')
        },
        getIntervall(){
            console.log('entry getIntervall')
            let startIntervall = {}
            let endIntervall = {}

            if(this.selectionIntervall === 'day') {
                console.log("day") 
                startIntervall['day'] = this.date[0].getDate()
                startIntervall['month'] = this.date[0].getMonth()
                startIntervall['year'] = this.date[0].getFullYear()
                endIntervall['day'] = this.date[1].getDate()+1//suche erfolgt immer exklusive des letzten Tags
                endIntervall['month'] = this.date[1].getMonth()
                endIntervall['year'] = this.date[1].getFullYear()
            }
            if(this.selectionIntervall === 'month') {
                console.log("month") 
                startIntervall['day'] = '1'
                startIntervall['month'] = this.date[0]['month']
                startIntervall['year'] = this.date[0]['year']
                if(this.date[1] != null){
                    
                    var lastDayOfMonth = new Date(this.date[1]['year'], this.date[1]['month'] +1, 0)
                    endIntervall['day'] = lastDayOfMonth.getDate()
                    endIntervall['month'] = this.date[1]['month']
                    endIntervall['year'] = this.date[1]['year']
                }else{
                    var lastDayOfMonth = new Date(this.date[0]['year'], this.date[0]['month'] +1, 0)
                    endIntervall['day'] = lastDayOfMonth.getDate()
                    endIntervall['month'] = this.date[0]['month']
                    endIntervall['year'] = this.date[0]['year']
                }
                
            }
            if(this.selectionIntervall === 'year') {
                console.log("year") 
                startIntervall['day'] = '1'
                startIntervall['month'] = '0'
                startIntervall['year'] = this.date
                endIntervall['day'] = '31'
                endIntervall['month'] = '11'
                endIntervall['year'] = this.date
            }
            const formData = {
                selectionData: this.checkedDescriptions,
                startIntervall: startIntervall,
                endIntervall: endIntervall,
                type_intervall: this.selectionIntervall,
            }
            console.log('exit getIntervall')
            return formData
        },
        generateChart(){
            console.log('entry generateChart')
            console.log(this.date)
            this.chartDone = false
            console.log(this.chartDone)
            var formData = this.getIntervall()
            formData.type_data = 'plain'
            axios
                .post(`/api/v1/components/${this.component.id}/get_research_data_for_component/`, formData)
                .then(response => {
                    console.log('fertig')
                    this.chartDone = false
                    console.log(response.data)
                    this.visualiseData(response.data)
                })
                .catch(error => {
                    this.chartDone = true
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })

            console.log('exit generateChart')
        },
        generateExcel(){
            console.log('entry generateExcel')
            console.log(this.date)

            var formData = this.getIntervall()
            formData.type_data = 'xlsx'
            axios
                .post(`/api/v1/components/${this.component.id}/get_research_data_for_component/`, formData)
                .then(response => {
                    console.log('fertig')
                    console.log(response.data)
                    fileDownload(response.data, this.plant.name +'_' + this.component.name +`_auswertung.csv`);
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })

            console.log('exit generateExcel')
        },
        visualiseData(componentData){
            console.log('entry visualiseData')
            this.chartLabelArray = []
            this.chartDataset = []
            console.log(this.component)
            this.chartDataId = this.component.name
            componentData.chart_time_array.forEach((item, index) =>{
                let date = new Date(item)

                this.chartLabelArray.push(date.getDate() + '.' + (date.getMonth()+1) + ' ' + (date.getHours()<10?'0':'') + date.getHours() + ':' + (date.getMinutes()<10?'0':'') + date.getMinutes())

            })
            console.log('chartLabelArray: ' + this.chartLabelArray)
            this.chartDataset = componentData.datasets
            console.log('exit visualiseData')
        }
    },
    computed:{
        isButtonConfirmDisabled(){
            if(this.selectionIntervall === 'live'){
                return false
            }

            if(this.date === null){
                return true
            }
            return false
        },
        getDatepickerFormat(){
            console.log("getDatepickerFormat entry")
            console.log('date:')
            console.log(this.date)
            if(this.selectionIntervall === 'day') {
                console.log("day") 
                return "dd/MM/yyyy"
            }
            if(this.selectionIntervall === 'month') {
                console.log("month") 
                return "MM/yyyy"
            }
            if(this.selectionIntervall === 'year') {
                console.log("year") 
                return "yyyy"
            }
        },
        getAllColDescriptionsFormatted(){
            console.log('entry getAllColDescriptionsFormatted')
            console.log('activeComponent:')
            console.log(this.component)
            if(this.component === ""){
                return [];
            }
            let array_length = this.component.component_data_description.length
            let formatted_array = []

            if(array_length < 2){
                formatted_array[0] = this.component.component_data_description
            }else{
                let descriptions = this.component.component_data_description.slice(0, Math.round(array_length/2))
                let descriptions2 = this.component.component_data_description.slice(Math.round(array_length/2), array_length)
                formatted_array[0] = descriptions
                formatted_array[1] = descriptions2
                console.log('descriptions')
                console.log(descriptions)
                console.log('descriptions2')
                console.log(descriptions2)
            }
            console.log('formatted_array')
            console.log(formatted_array)
            console.log('exit getAllColDescriptionsFormatted')
            return formatted_array
        },
        chartData(){
            if(this.chartLabelArray === []){
                return {};
            }

            console.log('this.chartLabelArray')
            console.log(this.chartLabelArray)
            console.log('this.chartDataset')
            console.log(this.chartDataset)
            return {
                labels: this.chartLabelArray,

                datasets: this.chartDataset
            }
        },
    }
}
</script>
