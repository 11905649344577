<template>
    <div class="container-fluid my-3 py-3">
        <div class="row my-3">
            <h6>{{$t("last_code")}} {{ this.lastAlarmcode.alarm_code }}/{{this.lastAlarmcode.alarm_txt}}</h6>
        </div>
        <div class="row my-3">
            <div class="col-2">
                <div class="form-floating">
                    <input id="inputName" ref="name" type="text" name="name" class="form-control"  v-model="alarmcode.alarm_code" placeholder="name@example.com">
                    <label for="inputName" class="form-label" >{{ $t("alarm_code") }}</label>
                </div>
            </div> 
             <div class="col-6">
                <div class="form-floating">
                    <input id="inputName" type="text" name="name" class="form-control"  v-model="alarmcode.alarm_txt" placeholder="name@example.com">
                    <label for="inputName" class="form-label" >{{ $t("alarm_txt") }}</label>
                </div>
            </div>                
        </div>
        <button class="btn btn-success mb-0 ms-auto" @click="submitForm">{{ $t("save") }}</button>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'AddAlarmcodeForSystem',
    emits: ['closeModal'],
    props: {
        plantSystem: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            alarmcode: {},
            lastAlarmcode: {}
        }
    },
    mounted(){
        console.log('entry mounted')
        this.focusInput()
        this.getLastAlarmcodeForSystem()
        console.log('exit mounted')
    },
    methods: {
        getLastAlarmcodeForSystem(){
            console.log('entry getLastAlarmcodeForSystem')
            axios
                .get(`/api/v1/plantsystems/${this.plantSystem.id}/get_last_alarmcode_for_system/`)
                .then(response => {
                    
                    this.lastAlarmcode = response.data
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit getLastAlarmcodeForSystem')
        },
        focusInput() {
            console.log('entry focusInput')
            console.log(this.$refs.name)
            this.$refs.name.focus();
            console.log('exit focusInput')
        },
        submitForm() {
            this.alarmcode.plant_system = this.plantSystem.id
            this.alarmcode.created_by = this.$store.state.me.id
            this.alarmcode.modified_by = this.$store.state.me.id
            axios
                .post(`/api/v1/alarmcodes/`, this.alarmcode)
                .then(response => {
                    
                    createToast({text: this.$t("alarmcode created")
                   ,type: 'success'})

                   this.$emit('closeModal', 'create', response.data)
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
        }
    },
    computed:{
    }
}
</script>