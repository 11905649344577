<template>
    <div class="row mt-3">
        <div class="col-12">
            <table class="table table-striped table-hover clickable table-sm">
                <thead>
                    <tr>
                        <th>{{ $t("user") }}</th>
                        <th>{{ $t("userRole") }}</th>
                        <th>{{ $t("email")}}</th>
                        <th>{{ $t("phone")}}</th>
                        <th>{{ $t("send_daily_report")}}</th>
                        <th v-if="this.canWriteUser"> <button  type="button" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#modal" @click="addUser()">{{ $t("newUser") }}</button></th>
                    </tr>
                </thead>
                <tbody>

                    <tr
                        v-for="user in allOrganisationUsers"
                        v-bind:key="user.id"
                    >
                        <td @click="editUser(user)" data-bs-toggle="modal" data-bs-target="#modal">{{ user.username }}</td>
                        <td @click="editUser(user)" data-bs-toggle="modal" data-bs-target="#modal">{{ user.grp_name_bez }}</td>
                        <td @click="editUser(user)" data-bs-toggle="modal" data-bs-target="#modal">{{ user.email}}</td>
                        <td @click="editUser(user)" data-bs-toggle="modal" data-bs-target="#modal">{{ user.phone_number}}</td>
                        <td v-on:change="updateUser(user)">
                            <div class="form-check form-switch">
                                <input :disabled="user.email == ''" class="form-check-input" type="checkbox" role="switch" v-model="user.send_daily_report">
                            </div>
                        </td>
                        <td v-if="this.canWriteUser"> <button  type="button" class="btn btn-sm btn-outline-info" data-bs-toggle="modal" data-bs-target="#modal" @click="resetPassword(user)">{{ $t("resetPasswordUser") }}</button></td>
                        <td v-if="this.canWriteUser"> <button  type="button" class="btn btn-sm btn-outline-danger" data-bs-toggle="modal" data-bs-target="#modal" @click="deleteUser(user)">{{ $t("deleteUser") }}</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="modal fade" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalLabel">{{ $t(this.currentComponent) }}</h5>
                <button type="button" id="modalClose" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body modal-textarea">
                <component v-if="currentComponent" :is="currentComponent" v-bind="currentProperties" v-on:closeModal="closeModal"></component>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t("close") }}</button>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'

import AddUser from '@/components/masterdata/AddUser.vue'
import DeleteUser from '@/components/masterdata/DeleteUser.vue'
import EditUser from '@/components/masterdata/EditUser.vue'
import ResetPasswordForUser from '@/components/masterdata/ResetPasswordForUser.vue'
import axios from 'axios'
export default {
    name: 'OrganisationUsers',
    components: {
        AddUser,
        EditUser,
        DeleteUser,
        ResetPasswordForUser
    },
    data() {
        return {
            currentComponent: "",
            allOrganisationUsers: [],
            currentUser: ''
        }
    },
    async mounted(){
       await this.getAllUsersForCompanie()
    },
    methods: {
        updateUser(user){
            console.log('entry updateUser')
            axios
                .patch(`/api/v1/users/${user.id}/`, user)
                .then(response => {
                    
                    createToast({text: this.$t("user_altered")
                   ,type: 'success'})
                   this.$emit('closeModal')
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })          
            console.log(('exit updateUser'))
        },  
        resetPassword(user){
            console.log('entry resetPasswort')
            if(this.canWriteUser){
                this.currentComponent = "ResetPasswordForUser"
                this.currentUser = user
            }
            console.log('exit resetPassword')
        },
        deleteUser(user){
            console.log('entry deleteUser')
            if(this.canWriteUser){
                this.currentComponent = "DeleteUser"
                this.currentUser = user
            }
            console.log('exit deleteUser')
        },
        editUser(user){
            console.log('entry editUser')
            if(this.canWriteUser){
                this.currentComponent = "EditUser"
                this.currentUser = user
            }
            console.log('exit editUser')
        },
        addUser(){
            console.log('entry addUser')
            if(this.canWriteUser){
                this.currentComponent = "AddUser"
            }
            console.log('exit addUser')
        },
        getAllUsersForCompanie(){
            console.log('entry getAllUsersForCompanie')
            axios
                .get('/api/v1/allOrganisationUsers/')
                .then(response => {
                    this.allOrganisationUsers = response.data
                    console.log('users fertig geladen')
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit getAllUsersForCompanie')
        },
        closeModal(){
            console.log("closeModal")
            document.getElementById('modalClose').click();
            console.log("reload")
            this.currentComponent = ''
            this.getAllUsersForCompanie()
        },
    },
    computed:{
        canWriteUser(){
            return this.$store.getters.userHasPermission('user', 'can_write')
        },
        currentProperties(){
            return {organisation: this.organisation
                ,user: this.currentUser}
        },
        getModalName(){
            
        },
        organisation(){
            return this.$store.getters.getOrganisation
        }
    }
}
</script>