<template>
    <div class="page-edit-live-data-position">
        <div class="container-fluid my-3 py-3">
            <div class="row my-3">
                <div class="col-12">
                    <div class="form-floating">
                        <input id="inputName" type="text" name="name" class="form-control"  v-model="activePosition.name" placeholder="name@example.com">
                        <label for="inputName" class="form-label" >{{ $t("name") }}</label>
                    </div>
                </div>                
            </div>
            <div class="row my-3">
                <div class="col-8">
                    <div class="form-check form-switch">
                        <input class="form-check-input" id="show_units" type="checkbox" v-model="activePosition.position_different_times">
                        <label class="form-check-label" for="show_units" >
                            {{ $t("positions_different_times_live_data_position")}}
                        </label>
                    </div>
                </div>
            </div>
            <div class="row mt-2" v-if="this.plantDescriptions.length === 0">
                {{ $t("loading plants") }}
            </div>
            <div class="row mt-2" v-for="plant in this.plantDescriptions.plants"
            v-bind:key="plant.id">
                <div class="col-6">
                    <div class="form-floating">
                        <input id="inputPlant" type="text" name="name" class="form-control"  v-model="plant.name" placeholder="name@example.com" disabled>
                        <label for="inputPlant" class="form-label" >{{ $t("plant") }}</label>
                    </div>
                    
                </div>
                <div class="col-6">
                    <select class="form-select " aria-label="Default select example" v-model="plant.act_component_data_description">
                        <option  v-for="description in plant.data_description" 
                        v-bind:key="description.id" v-bind:value="description.id">{{description.col_description}}</option>
                    </select>
                </div>
            </div>
            <button class="btn btn-success mb-0 ms-auto mt-2" @click="submitForm">{{ $t("save") }}</button>
        </div>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'EditLiveDataPosition',
    emits: ['closeModal'],
    props: {
        organisation: {
            type: Object,
            required: true,
        },
        liveDataHeader: {
            type: Object,
            required: true,
        },
        activePosition: {
            type: Object,
            required: true,
        },
        position: {
            type: Number,
            required: true,
        },
        liveDataId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            plantDescriptions: []
        }
    },
    mounted(){
        this.getPlantDescriptions()
    },
    methods: {
        getPlantDescriptions(){
            console.log('entry getPlantDescriptions')
            axios
                .get(`/api/v1/organisations/${this.organisation.id}/${this.liveDataId}/${this.activePosition.id}/get_plant_descriptions/`)
                .then(response => {
                    this.plantDescriptions = response.data
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit getPlantDescriptions')
        },
        submitForm() {
            console.log('entry submitForm')
            this.activePosition.component_data_description.length = 0
            this.plantDescriptions.plants.forEach(element => {
                console.log('element:')
                console.log(element)
                this.activePosition.component_data_description.push(element.act_component_data_description)
            })
            axios
                .patch(`/api/v1/organisation_live_data_positions/${this.activePosition.id}/`, this.activePosition)
                .then(response => {
        
                    createToast({text: this.$t("header_position altered")
                        ,type: 'success'})

                this.$emit('closeModal', 'update', response.data)
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            
            console.log('exit submitForm')
        }
    },
    computed:{
    }
}
</script>