<template>
    <div class="page-config-live-data">
        
        <div class="col-md-3 col-lg-2 d-md-block bg-light sidebar">
            <NavPlantsSidebar v-bind="currentPlantsProperties" 
                v-on:setPlant="setPlant" v-on:editPlant="editPlant" v-on:newPlant="newPlant"
                v-on:setComponent="setPlantComponent" v-on:editComponent="editPlantComponent"
                v-on:newComponent="newPlantComponent" v-on:editPlantUserMapping="editPlantUserMapping"
                v-on:updatePlant="updatePlant" v-on:setEvaluation="setEvaluation"></NavPlantsSidebar>
        </div>
        <div class="col-md-9 ms-sm-auto col-lg-10">
            <component :is="currentTabComponent" v-if="this.userSelectedSomething" v-bind="currentProperties"
                v-on:copyDescription="copyDescription"></component>

        </div>
    </div>

    <button id="modalOpen" style="display:none;" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#modalPlant" aria-hidden="true">hidden</button>
    <div class="modal fade" id="modalPlant" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-scrollable modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalLabel">{{ $t(this.currentComponent) }}</h5>
                    <button type="button" id="modalPlantClose" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body modal-textarea">
                    <component v-if="plantsLoaded && currentComponent" :is="currentComponent" v-bind="currentModalProperties" v-on:closeModal="closeModal"></component>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t("close") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    
import EditDescriptionComponent from '@/components/masterdata/EditDescriptionComponent.vue'
import EditDescriptionComponentXML from '@/components/masterdata/EditDescriptionComponentXML.vue'
import EditDescriptionComponentSummary from '@/components/masterdata/EditDescriptionComponentSummary.vue'
import EditPlantEvaluationColumns from '@/components/masterdata/EditPlantEvaluationColumns.vue'
import EditPlantUserMapping from '@/components/masterdata/EditPlantUserMapping.vue'
import AddPlant from '@/components/masterdata/AddPlant.vue'
import CopyDescription from '@/components/masterdata/CopyDescription.vue'
import UpdatePlant from '@/components/masterdata/UpdatePlant.vue'
import AddComponent from '@/components/masterdata/AddComponent.vue'
import UpdateComponent from '@/components/masterdata/UpdateComponent.vue'
import { createToast } from '@/assets/js/myToast.js'
import NavPlantsSidebar from '@/components/navs/NavPlantsSidebar.vue'
import Plant from '@/components/masterdata/Plant.vue'
import axios from 'axios'
export default {
    name: 'Plants',
    emits: [
            ],
    components: {
        Plant,
        NavPlantsSidebar, 
        AddPlant,
        CopyDescription,
        UpdatePlant,
        AddComponent,
        UpdateComponent,
        EditDescriptionComponent,
        EditDescriptionComponentSummary,
        EditPlantUserMapping,
        EditDescriptionComponentXML,
        EditPlantEvaluationColumns
        },
    props: {
    },
    data() {
        return {
            currentPlant: "",
            currentTabComponent: "EditDescriptionComponent",
            currentComponent: "",
            currentPlantComponent: "",
            currentPlantEvaluation: "",
            userSelectedSomething: false,
        }
    },
    mounted(){
        this.checkAndLoadPlantSystems()
    },
    updated(){
        
    },
    methods: {
        checkAndLoadPlantSystems(){
            console.log('entry checkAndLoadPlantSystems')
            axios
                .get('/api/v1/plantsystems/')
                .then(response => {
                    console.log('plantsystems loaded')
                    console.log(response.data)
                    this.$store.commit('setPlantSystems', response.data)
                    
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
            
            console.log('exit checkAndLoadPlantSystems')
        }, 
        copyDescription(){
            console.log('entry copyDescription')
            this.currentComponent = "CopyDescription"
            document.getElementById('modalOpen').click();
            console.log('exit copyDescription')
        },
        updatePlant(plant){
            console.log('entry updatePlant')
            console.log(plant)
            this.currentPlant = plant
            console.log('exit updatePlant')
        },
        closeModal(action, data){
            console.log('entry closeModal with action: ' + action)
            document.getElementById('modalPlantClose').click();

            if(action === 'add'){
                if(this.currentComponent === 'AddPlant'){
                    this.$store.commit('addPlant', data)
                }else if(this.currentComponent === 'AddComponent'){
                    console.log('AddComponent')
                    console.log(this.currentPlant)
                    this.currentPlant.plant_components.push(data)
                }
            }
            console.log('exit closeModal')
        },
        editPlantUserMapping(plant){
            console.log('entry editPlantUserMapping')
            this.currentPlant = plant
            this.currentTabComponent = "EditPlantUserMapping"
            this.userSelectedSomething = true
            console.log('exit editPlantUserMapping')
        },  
        editPlant(plant){
            console.log('entry editPlant')
            this.currentPlant = plant
            this.currentComponent = "UpdatePlant"
            document.getElementById('modalOpen').click();
            console.log('exit editPlant')
        },
        newPlant(){
            console.log('entry newPlant')
            this.currentComponent = "AddPlant"
            document.getElementById('modalOpen').click();
            console.log('exit newPlant')
        },
        newPlantComponent(){
            console.log('entry newPlantComponent')
            this.currentComponent = "AddComponent"
            document.getElementById('modalOpen').click();
            console.log('exit newPlantComponent')
        },
        setPlant(plant){
            console.log('entry setPlant')
            if(this.currentPlant != plant){
                this.currentPlant = plant
                this.currentPlantComponent = ""
                this.currentTabComponent = ""
                this.userSelectedSomething = false
            }
            console.log('exit setPlant')
        },
        getSystemForPlant(plant){
           return this.$store.getters.getPlantSystems.filter(system => system.id === plant.system)[0]
        },
        setPlantComponent(plant, component){
            console.log('entry setPlantComponent')
            console.log(plant)
            this.currentPlantComponent = component
            if(this.currentPlant.name != plant.name){
                this.currentPlant = plant
            }
            let system = this.getSystemForPlant(plant)
            console.log(system)
            if(component.type_component == 'SU'){
                this.currentTabComponent = 'EditDescriptionComponentSummary'
            }else if(system.file_extension_import_data == 'xml'){
                this.currentTabComponent = "EditDescriptionComponentXML"
            }
            else{
                this.currentTabComponent = "EditDescriptionComponent"
            }
            this.userSelectedSomething = true
            console.log('exit setPlantComponent')
        },
        setEvaluation(plant, evaluation){
            console.log('entry setEvaluation')
            console.log(plant)
            this.currentPlantComponent = ''
            this.currentPlantEvaluation = evaluation
            if(this.currentPlant.name != plant.name){
                this.currentPlant = plant
            }
            
            this.currentTabComponent = "EditPlantEvaluationColumns"
            
            this.userSelectedSomething = true
            console.log('exit setEvaluation')
        },
        editPlantComponent(component){
            console.log('entry editPlantComponent')
            this.currentPlantComponent = component
            this.currentComponent = "UpdateComponent"
            document.getElementById('modalOpen').click();
            this.userSelectedSomething = true
            console.log('exit editPlantComponent')
        },
    },
    computed:{
        canWritePlant(){
            return this.$store.getters.userHasPermission('plant', 'can_write')
        },
        plantsLoaded(){
            if(this.$store.getters.getPlants){
                return true
            }
            return false
        },
        currentProperties(){
                return {componentId: this.currentPlantComponent.id
                    ,evaluationId: this.currentPlantEvaluation.id
                    ,plant: this.currentPlant
                    ,canWritePlant: this.canWritePlant}
            },
        currentPlantsProperties(){
                return {plants: this.$store.getters.getPlants
                    ,showPlantComponents: true
                    ,showPlantEvaluations: true
                    ,editPlantComponents: true
                    ,editPlants: true}
            },
        currentModalProperties(){
                return {organisation:this.$store.getters.getOrganisation,  plant: this.currentPlant, component: this.currentPlantComponent, plants: this.$store.getters.getPlants}
            },
    }
}
</script>