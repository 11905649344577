<template>
    <div class="row mx-2 my-2">
        <div class="card shadow-sm">
            <div class="card-header d-flex align-items-center">
                <p class="text-center h5">{{ $t("user_group_plant_mapping") }}</p>           
            </div>
            <div class="card-body">
                <div class="row">
                    <template
                        v-for="plants in getAllPlantsFormatted"
                        v-bind:key="plants.id"
                    >
                        <div :class="{'col-6': getAllPlantsFormatted.length > 1, 'col': getAllPlantsFormatted.length === 1}">
                            <template
                                v-for="plant in plants"
                                v-bind:key="plant.id"
                            >
                                <div class="form-check form-switch">
                                    <input class="form-check-input" :disabled="!this.canWriteUserGroup" type="checkbox" :value="plant.id" :id="'plant'+plant.id" v-model="taggedPlants">
                                    <label class="form-check-label" :for="'plant'+plant.id" >
                                        {{plant.name}}
                                    </label>
                                </div>
                            </template>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'EditUserGroupPlantMapping',
    props: {
        userGroup: {
            type: Object,
            required: true,
        },
        canWriteUserGroup: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    data() {
        return {
            allOrganisationUsers: [],
            taggedPlants: [],
            userGroupOldId: 0,
        }
    },
    async mounted(){
        await this.manipulateTaggedPlants()
    },
    updated(){
        console.log('entry updated')
        if(this.userGroupOldId === 0){
            this.userGroupOldId = this.userGroup.id
        }
        if(this.userGroupOldId != this.userGroup.id){
            this.manipulateTaggedPlants()
            this.userGroupOldId = this.userGroup.id
        }
        console.log('exit updated')
    },
    methods: {
        manipulateTaggedPlants(){
            console.log('entry manipulateTaggedPlants')
            this.taggedPlants = []
            this.userGroup.group_plants.forEach(plant=>{
                this.taggedPlants.push(plant.id)
            })
            console.log('exit manipulateTaggedPlants')
        },
        submitForm() {
            this.userGroup.group_plants = []
            this.taggedPlants.forEach(plantId=>{
                console.log(plantId)
                var plant = this.allPlants.filter(orga_plant=> orga_plant.id === plantId)[0]
                if(!plant){
                    return
                }
                var plantArray = this.userGroup.group_plants.filter(group_plant=> group_plant.id === plantId)[0]
                if(!plantArray){
                    console.log('new Plant for Array')
                    this.userGroup.group_plants.push(plant)
                    console.log('push done')
                }
            })

            delete this.userGroup.group_users
            console.log('entry submitForm')
            axios
                .patch(`/api/v1/usergroup/${this.userGroup.id}/`, this.userGroup)
                .then(response => {
                    createToast({text: this.$t("plant_mapping altered")
                    ,type: 'success'})
                })
                .catch(error => {
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit submitForm')
        }
    },
    computed:{
        allPlants(){
            return this.$store.getters.getPlants
        },
        getAllPlantsFormatted(){
            console.log('entry getAllPlantsFormatted')
            console.log('activeComponent:')
            console.log(this.allPlants)
            let array_length = this.allPlants.length
            let formatted_array = []

            if(array_length < 2){
                formatted_array[0] = this.allPlants
            }else{
                let plants = this.allPlants.slice(0, Math.round(array_length/2))
                let plants2 = this.allPlants.slice(Math.round(array_length/2), array_length)
                formatted_array[0] = plants
                formatted_array[1] = plants2
                console.log('plants')
                console.log(plants)
                console.log('plants2')
                console.log(plants2)
            }
            console.log('formatted_array')
            console.log(formatted_array)
            console.log('exit getAllPlantsFormatted')
            return formatted_array
        },
    }
}
</script>