<template>
    <div class="container-fluid my-3 py-3">
        <div class="row mb-3">
            <div class="col-6">
                <div class="form-floating">
                    <input id="description" type="email" name="username" class="form-control" @keyup.enter="submitForm" v-model="description" placeholder="name@examp" required>
                    <label for="description" class="form-label" >{{ $t("description") }}</label>                
                </div>
            </div>
            
        </div>
        <div class="row mb-3">
            <div class="col-6">
                <div class="form-floating">
                    <input id="inputFirstname" type="text" name="first_name" class="form-control" @keyup.enter="submitForm" v-model="first_name" placeholder="name@examp" required>
                    <label for="inputFirstname" class="form-label" >{{ $t("first_name") }}</label>                
                </div>
            </div>
            <div class="col-6">
                <div class="form-floating">
                    <input id="inputLastname" type="text" name="last_name" class="form-control" @keyup.enter="submitForm" v-model="last_name" placeholder="name@examp" required>
                    <label for="inputLastname" class="form-label" >{{ $t("last_name") }}</label>                
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-6">
                <div class="form-floating">
                    <input id="inpuEmail" type="email" name="username" class="form-control" @keyup.enter="submitForm" v-model="email" placeholder="name@examp" required>
                    <label for="inputEmail" class="form-label" >{{ $t("email") }}</label>                
                </div>
            </div>
            <div class="col-6">
                <div class="form-floating">
                    <input id="inputPhone" type="text" name="username" class="form-control" @keyup.enter="submitForm" v-model="phone_number" placeholder="name@examp" required>
                    <label for="inputPhone" class="form-label" >{{ $t("phone") }}</label>
                </div>
            </div>
        </div>
        <div class="text-danger" v-if="errors.length">
                <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
            </div>
        <button class="btn btn-success mb-0 ms-auto" @click="submitForm">{{ $t("save") }}</button>
    </div>
</template>

<script>
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'
export default {
    name: 'AddReportUser',
    props: {
        organisation: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            description: '',
            errors: [],
        }
    },
    mounted(){
    },
    methods: {
        
        submitForm() {
            this.errors = []
            const formData = {
                email: this.email,
                organisation: this.organisation.id,
                phone_number: this.phone_number,
                last_name: this.last_name,
                first_name: this.first_name,
                description: this.description
            }
            axios
                .post("/api/v1/report_users/", formData)
                .then(response => {
                    
                    createToast({text: this.$t("successNewReportUser")
                   ,type: 'success'})
                   this.$emit('closeModal')
                })
                .catch(error => {
                    this.loading = false
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${error.response.data[property]}`)
                        }                       
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            
        },
        
    }
}
</script>