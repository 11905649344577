<template>
    <div class="page-config-live-data">
        <div class="col-md-2 col-lg-1 d-md-block bg-light sidebar">
            <NavPlantsSidebar v-if="plantsLoaded" v-bind="currentPlantsProperties" 
                v-on:setPlant="setAnalysePlant"></NavPlantsSidebar>
        </div>
        <div class="col-md-9 ms-sm-auto col-lg-11">
            <template v-if="this.currentPlant">
                <component :is="currentTabComponent" v-bind="currentProperties"></component>
            </template>
        </div>
    </div>
</template>

<script>
    
import PlantJournalForPlant from '@/components/PlantJournals/PlantJournalForPlant.vue'
import NavPlantsSidebar from '@/components/navs/NavPlantsSidebar.vue'
export default {
    name: 'PlantJournals',
    components: {
        PlantJournalForPlant,
        NavPlantsSidebar
    },
    data() {
        return {
            currentPlant: '',
            currentTabComponent: ''
        }
    },
    methods: {
        setAnalysePlant(plant){
            console.log('entry setAnalysePlant')
            this.currentPlant = plant
            this.currentTabComponent = 'PlantJournalForPlant'
            console.log('exit setAnalysePlant')
        },   
         
    },
    computed:{
        plantsLoaded(){
            if(this.$store.getters.getPlants){
                return true
            }
            return false
        },
        organisation(){
            return this.$store.getters.getOrganisation
        },
        
        currentProperties(){
            return {plant: this.currentPlant}
        },
        currentPlantsProperties(){
                return {plants: this.$store.getters.getPlants
                    ,showPlantComponents: false
                    ,editPlantComponents: false
                    ,editPlants: false}
            },
    }
}
</script>
