<template>
    <div class="page-config-live-data">
        <div class="col-md-3 col-lg-2 d-md-block bg-light sidebar">
            <NavPlantsSidebar v-if="plantsLoaded" v-bind="currentPlantsProperties" 
                v-on:setPlant="setResearchPlant" v-on:setComponent="setResearchPlantComponent"></NavPlantsSidebar>
        </div>
        <div class="col-md-9 ms-sm-auto col-lg-10 my-2">
            <div class="row">
                <div class="col-4">
                    <ChooseDataResearch v-if="this.currentPlantComponent" v-bind="currentProperties"
                    v-on:updateChoosenData="updateChoosenData"></ChooseDataResearch>
                </div>
                <div class="col-4">
                    <template v-if="this.allCheckedPlantComponentDescriptions.length > 0">
                        <ListAllChoosenData  v-bind="propertiesListChoosenData"
                            v-on:removeDescription="removeDescription"></ListAllChoosenData>

                    </template>
                    <template v-else>
                        {{ $t("research_how_to") }}
                    </template>
                </div>
                <div class="col-4">
                    <ChooseDateRange v-if="this.allCheckedPlantComponentDescriptions.length > 0" 
                        v-on:confirmDate="confirmDate"></ChooseDateRange>
                </div>
            </div> 
            <div class="row my-3">
                <VisualizeResearchData v-if="this.allCheckedPlantComponentDescriptions.length > 0" 
                    v-bind="visualizeProperties" v-on:generateExcel="generateExcel"></VisualizeResearchData>
            </div>
        </div>
    </div>
</template>

<script>
    
import ResearchForPlant from '@/components/research/ResearchForPlant.vue'
import VisualizeResearchData from '@/components/research/VisualizeResearchData.vue'
import ChooseDateRange from '@/components/research/ChooseDateRange.vue'
import ListAllChoosenData from '@/components/research/ListAllChoosenData.vue'
import ChooseDataResearch from '@/components/research/ChooseDataResearch.vue'
import NavPlantsSidebar from '@/components/navs/NavPlantsSidebar.vue'
import { createToast } from '@/assets/js/myToast.js'
import axios from 'axios'

const fileDownload = require('js-file-download')
export default {
    name: 'Research',
    components: {
        ResearchForPlant,
        VisualizeResearchData,
        ChooseDateRange,
        ListAllChoosenData,
        ChooseDataResearch,
        NavPlantsSidebar
    },
    data() {
        return {
            currentPlant: '',
            currentPlantComponent: '',
            currentTabComponent: '',
            allCheckedPlantComponentDescriptions: [],
            dataForVisualize: [],
            safedFormData: {},
            maxDescriptions: 10
        }
    },
    methods: {
        prepareDataForVisualize(data){
            console.log('entry prepareDataForVisualize')
            this.dataForVisualize = data
            
        },
        generateExcel(){
          console.log('entry generateExcel')
          var formData = this.safedFormData
          formData.type_data = 'xlsx'
          axios
                .post(`/api/v1/organisations/${this.$store.getters.getOrganisation.id}/get_research_data/`, formData, {responseType: 'blob'})
                .then(response => {
                    console.log('fertig')
                    console.log(response.data)
                    fileDownload(response.data, `_auswertung.xlsx`);
                })
                .catch(error => {
                    this.chartDone = true
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
          console.log('exit generateExcel')
        },
        confirmDate(dateObject, type){
            console.log('entry confirmDate')
            console.log(dateObject)
            var formData = dateObject
            formData.type_data = type
            formData.selectionData = this.allCheckedPlantComponentDescriptions
            this.safedFormData = formData
            if (type === 'xlsx'){
                this.generateExcel()
                return
            }
            axios
                .post(`/api/v1/organisations/${this.$store.getters.getOrganisation.id}/get_research_data/`, formData)
                .then(response => {
                    console.log('fertig')
                    console.log(response.data)
                    this.prepareDataForVisualize(response.data)
                    
                })
                .catch(error => {
                    this.chartDone = true
                    if (error.response) {
                        if ('detail' in error.response.data){
                            createToast({text: this.$t(error.response.data['detail'])
                                        ,type: 'error'})
                        }                        
                        console.error(JSON.stringify(error.response.data))
                    } else if (error.message) {
                        console.error(JSON.stringify(error.message))
                    } else {
                        console.error(JSON.stringify(error))
                    }
                })
            console.log('exit confirmDate')
        },
        removeDescription(description){
            console.log('entry removeDescription')
            console.log(description)
            let pos_description = this.allCheckedPlantComponentDescriptions.map(e => e.description_id).indexOf(description.description_id)
            console.log(pos_description)
            if (pos_description != -1){
                this.removeItemCheckedArray(pos_description)
            }
            console.log('exit removeDescription')
        },
        removeItemCheckedArray(pos_description){
            console.log('entry removeItemCheckedArray')
            console.log(pos_description)
            this.allCheckedPlantComponentDescriptions.splice(pos_description, 1)
            console.log('remove removeItemCheckedArray')
        },
        addItemCheckedArray(description){
            console.log('entry addItemCheckedArray')
            var plant_component_description = {}
            plant_component_description.plant_name = this.currentPlant.name
            plant_component_description.plant_id = this.currentPlant.id
            plant_component_description.component_name = this.currentPlantComponent.name
            plant_component_description.component_id = this.currentPlantComponent.id
            plant_component_description.description_name = description.col_description
            plant_component_description.description_id = description.id
            plant_component_description.col_component_data = description.col_component_data

            this.allCheckedPlantComponentDescriptions.push(plant_component_description)
            console.log('exit addItemCheckedArray')
        },
        updateChoosenData(description){
            console.log('entry updateChoosenData')
            let pos_description = this.allCheckedPlantComponentDescriptions.map(e => e.description_id).indexOf(description.id)
            
            if (pos_description == -1){
                this.addItemCheckedArray(description)
            }else{
                this.removeItemCheckedArray(pos_description)
            }
            console.log('exit updateChoosenData')
        },
        setResearchPlant(plant){
            console.log('entry setResearchPlant')
            this.currentPlant = plant
            this.currentPlantComponent = ""
            this.currentTabComponent = 'ResearchForPlant'
            console.log('exit setResearchPlant')
        },   
        setResearchPlantComponent(plant, component){
            console.log('entry ssetResearchPlantComponent')
            this.currentPlant = plant
            this.currentPlantComponent = component
            this.currentTabComponent = 'ResearchForPlant'
            console.log('exit setResearchPlantComponent')
        },       
    },
    computed:{
        plantsLoaded(){
            if(this.$store.getters.getPlants){
                return true
            }
            return false
        },
        organisation(){
            return this.$store.getters.getOrganisation
        },
        
        currentProperties(){
            return {plant: this.currentPlant, 
                    component: this.currentPlantComponent,
                    checkedDescriptions: this.allCheckedPlantComponentDescriptions,
                    maxDescriptions: this.maxDescriptions
                }
        },
        visualizeProperties(){
            return {dataForVisualize: this.dataForVisualize
                }
        },
        propertiesListChoosenData(){
            return {allCheckedPlantComponentDescriptions: this.allCheckedPlantComponentDescriptions,
                maxDescriptions: this.maxDescriptions
                }
        },
        currentPlantsProperties(){
                return {plants: this.$store.getters.getPlants
                    ,showPlantComponents: true
                    ,editPlantComponents: false
                    ,editPlants: false}
            },
    }
}
</script>
